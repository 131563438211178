import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import InnerBanner from "../../components/InnerBanner";
import ProductDetails from "../../components/products/ProductDetails";
import {fetchProductDetail} from "../../api/redux/product";
import {ApiParam} from "../../api/network/apiParams";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {

    const dispatch = useDispatch()
    const getData = useSelector(store => store.productDetails)
    let {slug} = useParams();
    const [relatedCat, setRelatedCat] = useState();

    // api call
    useEffect(() => {
        let api_url = apiEndPoints.PRODUCT_DATA
        let param = {
            [ApiParam.type]: 'slug',
            [ApiParam.value]: `${slug}`,
            [ApiParam.get_section]: 'yes',
            [ApiParam.image]: 'yes',
            [ApiParam.post]: 'yes',
            [ApiParam.file]: 'yes',
            [ApiParam.gallery]: 'yes',
            [ApiParam.category]: relatedCat,
        }
        dispatch(fetchProductDetail([api_url , param]))
    }, [dispatch])

    const productDetails = getData?.detail?.data;
    console.log(productDetails)
    const productList = getData?.posts?.product_list;
    useEffect(() => {
            setRelatedCat(productDetails?.product_data?.category_id);
    }, [productDetails]);

    const relatedProduct = productList?.filter((item) => item?.product_data?.category_id === relatedCat);
    console.log(relatedProduct)
    return (

        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${getData?.detail?.data?.product_data?.title ? getData?.detail?.data?.product_data?.title : ''}`}</title>
                {
                    getData?.detail?.data?.product_data?.meta_title &&
                    <meta name="title" content={getData?.detail?.data?.product_data?.meta_title}/>

                }
                {
                    getData?.detail?.data?.product_data?.meta_description &&
                    <meta name="description" content={getData?.detail?.data?.product_data?.meta_description}/>

                }
                {
                    getData?.detail?.data?.product_data?.og_title &&
                    <meta property="og:title" content={getData?.detail?.data?.product_data?.og_title}/>

                }
                {
                    getData?.detail?.data?.product_data?.og_description &&
                    <meta property="og:description" content={getData?.detail?.data?.product_data?.og_description}/>

                }
                {
                    getData?.detail?.data?.product_data?.og_type &&
                    <meta property="og:type" content={getData?.detail?.data?.product_data?.og_type}/>

                }
                {
                    getData?.detail?.data?.product_data?.og_url &&
                    <meta property="og:url" content={getData?.detail?.data?.product_data?.og_url}/>

                }
                {
                    productDetails?.images?.list?.[0]?.full_path &&
                    <meta property="og:image" content={productDetails ?.images?.list?.[0]?.full_path}/>

                }
            </Helmet>

            <StyledComponent>

                {/*<InnerBanner img={'/images/dynamic/about/Banner.jpg'} title={'PRODUCTS'}/>*/}
                <ProductDetails data={productDetails} relatedProduct={relatedProduct}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
