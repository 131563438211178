import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import MediaDetails from "../../components/news/MediaDetails";
import {useDispatch, useSelector} from "react-redux";
import {useHistory, useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchMedia, fetchMediaDetail} from "../../api/redux/media";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import InnerBanner from "../../components/InnerBanner";
import NewsList from "../../components/news/NewsList";
import { Helmet } from 'react-helmet';


const MyComponent = () => {
    const dispath = useDispatch()
    let {slug} = useParams();



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.BLOG_DATA
        let param = {
            [ApiParam.type]: 'slug',
            [ApiParam.value]: `${slug}`,
        }
        dispath(fetchMediaDetail([api_url, param]))
    }, [dispath])
    const getData = useSelector(store => store.mediaDetails)
    console.log(getData)
    const mediaDetails = getData?.detail?.data;

    const related= getData?.detail?.related_blog;

    console.log(getData?.detail?.related_blog);

    return (
        <>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | Media Center</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>
            <StyledComponent>
                <MediaDetails details={mediaDetails} related_news={related}/>

            </StyledComponent>
        </>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
