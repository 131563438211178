import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {Img} from "../Img";
import NavButton from "../NavButton";
import TitleTwo from "../TitleTwo";

const MyComponent = ({title,data}) => {
    const [offset, setOffset] = useState()
    // useEffect(() => {
    //     setOffset(document.querySelector('.container').offsetLeft)
    // }, [])
    useEffect(() => {

        // ScrollTrigger.refresh();
        // gsap.registerPlugin(ScrollTrigger);


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        setOffset(document.querySelector(' .container')?.offsetLeft)
    }, [])

    const swiperRef = useRef(null);
    const containerRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    swiperInstance?.autoplay?.start();
                } else {
                    swiperInstance?.autoplay?.stop();
                }
            },
            { threshold: 0.1 } // Adjust this threshold as needed
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [swiperInstance]);
    return (
        <StyledComponent offset={offset} className={'pt-150 partners pb-150'} ref={containerRef}>
            <Container>
                <Row className={'management-team'}>
                    <Col md={10}>
                        {
                            data?.section_data?.subtitle &&
                            <TitleTwo text={data?.section_data?.subtitle}/>

                        }
                    </Col>
                    <Col md={2}>
                        <div className="testimonial__head__navigation">
                            <NavButton prev_id={'partner-prev'} next_id={'partner-next'}/>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container fluid className={'p-0'}>
                <Row>
                    <Col>
                        {
                            data?.images?.list && data?.images?.list?.length>0 &&
                        <Swiper
                            onSwiper={setSwiperInstance}
                            slidesPerView={5}
                            spaceBetween={30}
                            autoplay={{
                                delay: 500,
                                disableOnInteraction: false,
                            }}
                            speed='500'
                            navigation={{
                                prevEl: '#partner-prev',
                                nextEl: '#partner-next',
                            }}
                            loop={false}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1025: {
                                    slidesPerView: 5,
                                    spaceBetween: 30,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}

                            className="mySwiper"
                        >
                            {
                                data?.images?.list && data?.images?.list.length>0 &&
                                data?.images?.list?.map((element,index)=>{
                                    return(
                            <SwiperSlide key={index}>
                                <div className="testimonial__single">
                                    <div className="testimonial__single__img">
                                        <Img src={element?.full_path}/>
                                    </div>
                                    <p>{element?.short_title}</p>
                                </div>
                            </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mobile-btn">
                            <NavButton prev_id={'partner-prev'} next_id={'partner-next'}/>
                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .management-team{
    margin-bottom: 60px;
    @media(max-width: 767px){
      margin-bottom: 0;
    }
  }
  .testimonial__single{
    overflow: hidden;
    &__img{
      position: relative;
      padding-top: calc(250 / 270 * 100%);
      border: 0.5px solid #CBB1B1;
      overflow: hidden;
      img{
        width: 180px;
        height: 180px;
        object-fit: contain;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      @media(max-width: 767px){
      }
    }
    p{
      text-align: center;
      margin-top: 27px;
    }
  }

  .testimonial__head__navigation{
    .navigation{
      justify-content: end;
      margin-top: 30px;
      @media(max-width: 767px){
        margin-top: 0;
      }
    }
    @media(max-width: 767px){
      display: none;
    }
  }
  .mobile-btn{
    display: none;
    margin-top: 40px;
    @media(max-width: 767px){
      display: block;
    }
  }
  .container-fluid{
    padding-left: ${props => props.offset ? props.offset +15+'px !important' : ''};
    @media (max-width: 767px) {
      padding-left: 15px !important;
    }
  }
  .swiper-initialized {
    padding-right: ${props => props.offset ? props.offset +15+'px !important' : ''};

    @media (min-width: 1500px) {
      padding-right: 140px;

    }
    @media (max-width: 767px) {
      padding-right: 75px !important;
    }
  }
  .swiper-slide{
    //width: 270px !important;
  }
`;

export default MyComponent;
