import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import HomeAbout from "../../components/home/HomeAbout";
import TimelineV2 from "../../components/about/Timeline-v2";
import Chairman from "../../components/about/Chairman";
import ManagingDirector from "../../components/about/ManagingDirector";
import Zigzag from "../../components/business-units/Zigzag";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {
    const dispath = useDispatch()
    const location=useLocation();



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;
        let api_params = {
            [ApiParam.type]: 'slug',
            [ApiParam.value]: 'business-units',
            [ApiParam.get_section]: 'yes',
            [ApiParam.image]: 'yes',
            [ApiParam.post]: 'yes',
            [ApiParam.file]: 'no',
            [ApiParam.gallery]: 'no',
        }
        dispath(fetchAbout([api_url , api_params]))

    }, [location?.pathname])

    const getData = useSelector((state) => state.about);

    let page_data = getData?.posts?.data?.page_data;
    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'business-unit-banner');
    const businessUnits = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mitali-business-units');
    const mitaliFashion = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mitali-fashions-limited');
    const zakariaApparels = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'zakaria-apperals-limited');
    const kssKnit = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'kss-knit-composite-limited');
    const zhApparels = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'zh-apparels-limited');
    const mitaliTrim = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mitali-trims-limited');
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | Business Units</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>


            <StyledComponent>

                <InnerBanner img={banner?.images?.list?.[0]?.full_path} title={getData?.posts?.data?.page_data?.title}/>

                {
                    businessUnits?.posts?.list && businessUnits?.posts?.list.length>0 &&
                    businessUnits?.posts?.list?.map((element,index)=>{
                        const isEvenIndex = index % 2 === 0;
                        return (
                            <div key={index}>
                                <Zigzag title={element?.data?.subtitle}
                                        desc={element?.data?.description}
                                        short_desc={element?.data?.short_desc}
                                        img={element?.images?.[0]?.full_path}
                                        reverse={element?.data?.reverse}
                                        bgColor={!isEvenIndex ? 'bg-color' : ''}
                                    // phone={mitaliFashion?.section_data?.phone_number}
                                    //     address={mitaliFashion?.section_data?.description}
                                />
                            </div>

                        )
                    })
                }
                {/*<Zigzag title={mitaliFashion?.section_data?.subtitle}*/}
                {/*        desc={mitaliFashion?.section_data?.short_desc}*/}
                {/*        img={mitaliFashion?.images?.list?.[0]?.full_path}*/}
                {/*        // phone={mitaliFashion?.section_data?.phone_number}*/}
                {/*        address={mitaliFashion?.section_data?.description}*/}
                {/*/>*/}
                {/*<Zigzag title={kssKnit?.section_data?.subtitle}*/}
                {/*        desc={kssKnit?.section_data?.short_desc}*/}
                {/*        img={kssKnit?.images?.list?.[0]?.full_path}*/}
                {/*        reverse*/}
                {/*        address={kssKnit?.section_data?.description}*/}
                {/*        // phone={kssKnit?.section_data?.phone_number}*/}
                {/*/>*/}
                {/*<Zigzag title={zakariaApparels?.section_data?.subtitle}*/}
                {/*        desc={zakariaApparels?.section_data?.short_desc}*/}
                {/*        img={zakariaApparels?.images?.list?.[0]?.full_path}*/}
                {/*        address={zakariaApparels?.section_data?.description}*/}
                {/*        // phone={zakariaApparels?.section_data?.phone_number}*/}
                {/*/>*/}
                {/*<Zigzag title={zhApparels?.section_data?.subtitle}*/}
                {/*        desc={zhApparels?.section_data?.short_desc}*/}
                {/*        img={zhApparels?.images?.list?.[0]?.full_path}*/}
                {/*        reverse*/}
                {/*        address={zhApparels?.section_data?.description}*/}
                {/*        // phone={zhApparels?.section_data?.phone_number}*/}
                {/*/>*/}
                {/*<Zigzag title={mitaliTrim?.section_data?.subtitle}*/}
                {/*        desc={mitaliTrim?.section_data?.short_desc}*/}
                {/*        img={mitaliTrim?.images?.list?.[0]?.full_path}*/}
                {/*        address={mitaliTrim?.section_data?.description}*/}
                {/*        // phone={mitaliTrim?.section_data?.phone_number}*/}
                {/*/>*/}

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
