import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TitleTwo from "../TitleTwo";
import {Img} from "../Img";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pt-150'}>
            <Container>
                <Row>
                    <Col md={6}>
                        {
                            data?.section_data?.subtitle &&
                            <TitleTwo text={data?.section_data?.subtitle}
                                      margin={'0px 0px 60px 0px'}
                            />
                        }

                    </Col>
                </Row>
                <Row>
                    <Col md={{span: 8,offset:2}} className={' higherMembers'}>
                            <Col md={6} sm={12} className="higherMembers__single">
                                <div className="higherMembers__single__img ">
                                    <Img src={data?.posts?.list?.[0]?.images?.[0]?.full_path}/>
                                </div>
                                <div className="higherMembers__single__content">
                                    <h5>{data?.posts?.list?.[0]?.images?.[0]?.short_title}</h5>
                                    <p>{reactHtmlParser(data?.posts?.list?.[0]?.images?.[0]?.short_desc)}</p>
                                </div>
                            </Col>
                            <Col md={6} sm={12} className="higherMembers__single ">
                                <div className="higherMembers__single__img ">
                                    <Img src={data?.posts?.list?.[0]?.images?.[1]?.full_path}/>
                                </div>
                                <div className="higherMembers__single__content">
                                    <h5>{data?.posts?.list?.[0]?.images?.[1]?.short_title}</h5>
                                    <p>{reactHtmlParser(data?.posts?.list?.[0]?.images?.[1]?.short_desc)}</p>
                                </div>
                            </Col>
                    </Col>
                </Row>
                <Row>
                    <Col md={{span:10,offset: 1}} className={'d-flex members'}>
                        {
                            data?.posts?.list?.[1]?.images && data?.posts?.list?.[1]?.images?.length>0 &&
                            data?.posts?.list?.[1]?.images?.map((element,i)=>{
                                return(
                            <Col md={4} className="members__single " key={i}>
                                <div className="members__single__img">
                                    <Img src={element?.full_path}/>
                                </div>
                                <div className="members__single__content">
                                    <h5>{reactHtmlParser(element?.short_title)}</h5>
                                    <p className={'members__single__content__position'}>{reactHtmlParser(element?.short_desc)}</p>
                                </div>
                            </Col>
                                )
                            })
                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  padding-bottom: 90px;
  background-color: #F5F5F5;
  @media(max-width: 767px){
    padding-bottom: 50px;
  }
  .higherMembers{
    display: flex;
    margin-bottom: 60px;
    &__single{
        will-change: transform;
      &__img{
        position: relative;
        padding-top: calc(375 / 320 * 100%);
          overflow: hidden;
          img{
              transition: 0.5s ease-in-out;
              transform: scale(1.01);
          }
          &:hover{
              img{
                  transform: scale(1.03);
              }
          }
      }
      &__content{
        margin-top: 10px;
        h5{
          margin-bottom: 5px;
          color: #202020;
        }
        p{
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
        }
      }
    }
    @media(max-width: 767px){
      flex-direction: column;
      gap: 30px;
      margin-bottom: 30px;
    }
  }
  
  .members{
    flex-wrap: wrap;
    justify-content: center;
      will-change: transform;
    &__single{
      margin-bottom: 60px;
        will-change: transform;
      &__img{
        position: relative;
        padding-top: calc(350 / 300 * 100%);
          overflow: hidden;
          img{
              transition: 0.5s ease-in-out;
              transform: scale(1.01);
          }
          &:hover{
              img{
                  transform: scale(1.03);
              }
          }
      }
      &__content{
        margin-top: 10px;
        h5{
          margin-bottom: 5px;
          color: #202020;
        }
        p{
          font-size: 16px;
          line-height: 24px;
          font-weight: 300;
        }
      }
    }
    @media(max-width: 767px){
      &__single{
        margin-bottom: 30px;
      }
    }
  }
`;

export default MyComponent;
