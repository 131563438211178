import {Route, Switch, useLocation} from 'react-router-dom';
import Error from './pages/404';
import GlobalStyle from "./styles/globalStyle";
import {ImageReveal} from "./components/animations/ImageReveal";
import {Parallax, ParallaxImg} from "./components/animations/Parallax";
import {gsap} from "gsap";
import {ScrollSmoother} from "gsap/ScrollSmoother";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import 'react-toastify/dist/ReactToastify.css';
import {AnimatePresence} from "framer-motion";



import {SplitText} from "gsap/SplitText";

// page imports
import About from './pages/about';
import Components from './pages/components';
import Home from './pages/home';
import Project from './pages/product';
import ProjectDetail from './pages/product/single';
import {SplitUp} from "./components/animations/TextAnimation";
import {useEffect, useLayoutEffect, useRef, useState} from "react";
import Menu from "./components/Menu";
import {Horizontal} from "./components/animations/TextMove";
import BusinessUnits from "./pages/business-units";
import Footer from "./components/Footer";
import Contact from "./pages/contact";
import MediaCenter from "./pages/media-center";
import MediaDetails from "./pages/media-center/single";
import Career from "./pages/career";
import WhatWeDo from "./pages/what-we-do";
import Sustainability from "./pages/sustainability";
import Product from "./pages/product";
import {useSelector} from "react-redux";
import {ToastContainer} from "react-toastify";
import CsrDetails from "./pages/sustainability/single";
import PageLoader from "./components/PageLoaderNew";
import Logo from "./components/Logo";
import PageLoaderNew from "./components/PageLoaderNew";
import {SplitUpNew} from "./components/animations/TextAnimationNew";
import {ParaUp} from "./components/animations/TextParagraphAnimation";
import {SplitOpacityUp} from "./components/animations/TextOpacityAnimation";
import PageTransition from "./components/PageTransition";

function App() {

    const location = useLocation();
    gsap.registerPlugin(ScrollTrigger, ScrollSmoother);
    let getPost = useSelector(state => state)

    const getMenuClass = () => {
        if (location.pathname.startsWith('/media-center/') || location.pathname.startsWith('/sustainability/') || location.pathname.startsWith('/product/')) {
            return 'menu-bg-color';
        }
        // Return default class if not in the specified routes
        return 'default-menu-class';
    }
    // smooth scroll init
    const el = useRef();
    useLayoutEffect(() => {
        let smoother = ScrollSmoother.create({
            smooth: 2, // how long (in seconds) it takes to "catch up" to the native scroll position
            effects: true, // looks for data-speed and data-lag attributes on elements
            // speed: 2
        });
        return () => {
            smoother.kill();
        };
    }, [location.pathname,getPost]);

    // route change scroll top & page class
    useEffect(() => {
        if (location.pathname === '/' ) {
            document.body.classList.add('in-home-page')

        } else {
            document.body.classList.remove('in-home-page')
        }

        window.scroll(0, 0)
    }, [location?.pathname])


    useEffect(() => {
        // Get the current URL from the router's full path
        const currentUrl = window.location.origin + location.pathname + location.search;

        // Set the canonical link dynamically
        const link = document.createElement('link');
        link.rel = 'canonical';
        link.href = currentUrl;
        document.head.appendChild(link);

        // Clean up the link when the component unmounts
        return () => {
            document.head.removeChild(link);
        };
    }, [location.pathname, location.search]);



    // animation functions
    // ImageReveal()
    Parallax()
    ParallaxImg()
    SplitUp()
    Horizontal()
    SplitUpNew()
    ParaUp()
    SplitOpacityUp()
    // page transition
    // PageTransition()
    // PageLoaderNew()

    return (
        <>
            <GlobalStyle/>
            <PageTransition/>
            <Menu getMenuClass={getMenuClass}/>
            <ToastContainer position="top-right" autoClose={4000} closeOnClick hideProgressBar={true}/>
            <div className="App" ref={el} id="smooth-wrapper">
                <div id="smooth-content">
                    <AnimatePresence exitBeforeEnter>
                    <Switch location={location} key={location.pathname}>
                        <Route exact path="/" component={Home}/>
                        <Route exact path="/components" component={Components}/>
                        <Route exact path="/about" component={About}/>
                        <Route exact path="/business-units" component={BusinessUnits}/>
                        <Route exact path="/contact" component={Contact}/>
                        <Route exact path="/media-center" component={MediaCenter}/>
                        <Route exact path={`/media-center/:slug`} component={MediaDetails}/>
                        <Route exact path="/what-we-do" component={WhatWeDo}/>
                        <Route exact path="/career" component={Career}/>
                        <Route exact path="/sustainability" component={Sustainability}/>
                        <Route exact path={`/sustainability/:slug`} component={CsrDetails}/>
                        <Route exact path={`/product`} component={Product}/>
                        <Route exact path={`/product/:slug`} component={ProjectDetail}/>
                        <Route component={Error}/>
                    </Switch>
                    </AnimatePresence>
                    <Footer/>
                </div>
            </div>
        </>


    );
}

export default App;
