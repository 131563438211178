import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import {useEffect} from "react";
import {useLocation} from "react-router-dom";
import {useSelector} from "react-redux";

export const SplitOpacityUp = () => {
    const location = useLocation();
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollTrigger);
    let getPost = useSelector(state => state)



    useEffect(() => {
        document.fonts.ready.then(() => {
            const splitTypes = document.querySelectorAll('.faded')
            splitTypes.forEach((char,i)=>{
                const text = new SplitText(char,{type:'chars'})

                gsap.from(text.chars,{
                    scrollTrigger:{
                        trigger:char,
                        start:'top 80%',
                        end:'top 10%',
                        scrub: true,
                        markers: false
                    },
                    opacity:0.2,
                    stagger:0.1
                })
            })
        });
    }, [location?.pathname,getPost])
}

/*
how to use:
- add 'split-up' class on the tag like(h1,h2,h3,p ... etc)
*/