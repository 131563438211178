import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Tabs, Tab} from "react-bootstrap";
import TitleTwo from "../TitleTwo";
import {hover} from "../../styles/globalStyleVars";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import {Img} from "../Img";
import Slick from "react-slick";
import reactHtmlParser from "react-html-parser";
import {useHistory, useLocation} from "react-router-dom";

const MyComponent = ({title,bgColor,data,sectionID}) => {


    const settings = {
        dots: true,
        loop: true,
        infinite: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 1500,
        autoplaySpeed: 2500,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                }
            },
        ]
    };


    const [activeKey, setActiveKey] = useState(0);
    useEffect(() => {
        // Extract the hash and query parameters separately
        const hash = window.location.hash; // This will give you '#textile'
        const params = new URLSearchParams(window.location.search);
        const activeKey = params.get('active_key'); // This will give you '1'

        // Use the hash to scroll to the section
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        }

        // Use the activeKey to set the defaultActiveKey in your Tabs component
        setActiveKey(activeKey);

    }, []);
    return (
        <StyledComponent className={`pt-120 pb-120 ${bgColor}`} id={sectionID}>
            <Container>
                <Row>
                    <Col>
                        <TitleTwo text={title} margin={'0 0 60px 0'}/>
                        {data?.posts?.list && data?.posts?.list?.length > 1 ? (
                            <Tabs defaultActiveKey={activeKey}>
                                {data?.posts?.list?.map((element, index) => (
                                    <Tab key={index} eventKey={index} title={reactHtmlParser(element?.data?.title)}>
                                        <Row className={'slider-content'}>
                                            <Col>
                                                <div className="description">
                                                    {reactHtmlParser(element?.data?.description)}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={'slider-content'}>
                                            <Col className={'slider-column'}>
                                                <div className="slider">
                                                    <Slick {...settings}>
                                                        {element?.images && element?.images?.length > 0 &&
                                                            element?.images?.map((e, i) => (
                                                                <div key={i} className="slider__single">
                                                                    <div className="slider__single__img">
                                                                        <Img alt={'banner'} src={e?.full_path} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </Slick>
                                                </div>
                                            </Col>
                                        </Row>
                                    </Tab>
                                ))}
                            </Tabs>
                        ) : (
                            <div>
                                {data?.posts?.list?.map((element, index) => (
                                    <div key={index}>
                                        <Row className={'slider-content'}>
                                            <Col>
                                                <div className="description">
                                                    {reactHtmlParser(element?.data?.description)}
                                                </div>
                                            </Col>
                                        </Row>
                                        <Row className={'slider-content'}>
                                            <Col className={'slider-column'}>
                                                <div className="slider">
                                                    <Slick {...settings}>
                                                        {element?.images && element?.images?.length > 0 &&
                                                            element?.images?.map((e, i) => (
                                                                <div key={i} className="slider__single">
                                                                    <div className="slider__single__img">
                                                                        <Img alt={'banner'} src={e?.full_path} />
                                                                    </div>
                                                                </div>
                                                            ))}
                                                    </Slick>
                                                </div>
                                            </Col>
                                        </Row>
                                    </div>
                                ))}
                            </div>
                        )}

                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .category-list{
    margin-bottom: 60px;
    margin-top: 60px;
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        border: 1px solid #000000;
        padding: 12px 32px;
        border-radius: 29px;
        margin-right: 20px;
        transition: all .3s ease;
        width: max-content;
        position: relative;
        overflow: hidden;
        &:last-child{
          margin-right: 0;
        }
        span {
          transition: color .3s ease;
          position: relative;
          z-index: 2;
          width: max-content;
          color: #000000;
        }
        &:before{
          top: 100%;
          content: "";
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          background-color: ${p => p.hoverBackground || hover};
          height: 100%;
          width: 100%;
          margin: auto;
          transition: all .5s ease;
          border-radius: 24px;
        }
        &:hover{
          span{
            color: #FFFFFF;
          }
          border: 1px solid #8A1313;
          &:before{
            top:0;
          }
        }
        &.active{
          background-color: ${hover};
          border: 1px solid ${hover};
          span{
            color: #FFFFFF;
          }
        }
        @media(max-width: 767px){
          margin-bottom: 20px;
          font-size: 14px;
        }
      }
    }
  }
  
  .slider-content{
    .description{
      
    }
    .slider-column{
      padding: 0;
    }
    .slider{
      margin-top: 40px;
      position: relative;
      &__single{
        padding: 0px 15px;
        &__img{
          padding-top: calc(300 / 370 * 100%);
          position: relative;
        }
          @media (max-width: 767px) {
              //padding: 0px 7.5px;
          }
      }
    }
    .slick-dots{
      display: flex !important;
      justify-content: center;
      align-items: center;
      bottom: -40px;
      li{
        height: 8px;
        width: 8px;
        border: 1px solid ${hover};
        border-radius: 50%;
        background-color: ${hover};
        &.slick-active{
          height: 16px;
          width: 16px;
          background-color: #FFFFFF;
        }
        &:first-child{
          margin-right: 5px;
        }
      }
    }
    .slick-dots li button:before{
      display: none;
    }
  }
  
  .nav-tabs{
    display: flex;
    border-bottom: none;
    margin-bottom: 40px;
      gap: 20px;
    li{
      button{
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        border: 1px solid #000000;
        padding: 12px 32px;
        border-radius: 29px;
        margin-right: 20px;
        transition: all .3s ease;
        width: max-content;
        position: relative;
        overflow: hidden;
        &:before{
          top: 100%;
          content: "";
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          background-color: ${p => p.hoverBackground || hover};
          height: 100%;
          width: 100%;
          margin: auto;
          transition: all .5s ease;
          border-radius: 24px;
          z-index: -1;
        }
        &.active{
          background-color: ${hover};
          border: 1px solid ${hover};
          color: #FFFFFF;
        }
        &:hover{
          color: #FFFFFF;
          &:before{
            top:0;
          }
        }
      }
        @media(max-width: 767px){
            width: 100%;
            button{
                width: 100%;
            }
        }
    }
  }
    @media(max-width: 767px){
        padding-bottom: 100px !important;
    }
`;

export default MyComponent;
