import React, {useEffect, useState} from "react";
import Select, {components} from "react-select";
import { hover} from "../../styles/globalStyleVars";
import {BsChevronDown, BsX} from "react-icons/bs";
import styled from "styled-components";
import {Accordion, Col, Container, Form, Modal, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
// import iDropdown from "../../public/images/static/i-dropdown.svg";
import Button from "../Button";
import {Link, useHistory, useLocation} from 'react-router-dom';
import Title from "../Title";
import TitleTwo from "../TitleTwo";
import ApplyButton from "../ApplyButton";
import {postForm} from "../../api/redux/career";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import store from "../../api/store";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";

const customStyles = {
    option: (provided, state) => ({
        ...provided,
        borderRadius: 0,
        color: state.isSelected ? '#FFF' : '#221F1F',
        backgroundColor: state.isSelected ? '#D05110' : '#FFF',
        margin: 0,
        fontSize: 12,
        cursor: 'pointer',
        paddingLeft: 25,
        letterSpacing: 2,
        textTransform : 'uppercase',
    }),
    menu: (provided, state) => ({
        ...provided,
        color: '#FFF',
        backgroundColor: state.isSelected ? 'rgba(0,0,0,0)' : 'rgba(255,255,255,0)',
        margin: 0,
        borderRadius: 23,
        fontSize: 12,

    }),
    menuList: (provided, state) => ({
        ...provided,
        backgroundColor: state.isSelected ? '#000' : '#FFF',
        borderRadius: 0,
        fontWeight: '600',
        color: '#FFF',
        fontSize: 12,
        letterSpacing: 2,
        textTransform : 'uppercase',

    }),

};


const IndustrialLube = ({queryParam,data,activeSubCat,setActiveSubCat,filterList,setActiveCategory,activeCategory}) => {

    const [isInitialRender, setIsInitialRender] = useState(true);
    const [catTitle, setCatTitle] = useState('');

    const newData = data?.filter((item) => item?.data?.category_id === activeCategory);
    const finalData = newData?.filter((item) => item?.data?.post === activeSubCat);

    const postArray = newData?.map((item) => ({
        value: item?.data?.post,
        label: item?.data?.post,
    }));

    useEffect(() => {
        if (newData && newData.length > 0 && isInitialRender) {
            setIsInitialRender(false);
            setCatTitle(filterList?.find((cat) => cat.id === activeCategory)?.title);
        }
    }, [newData, isInitialRender,activeCategory,filterList]);

    useEffect(() => {
        if (newData && newData.length > 0 && !activeSubCat) {
            setActiveSubCat(newData?.[0]?.data?.post);
        }
        else{
            setActiveSubCat(finalData?.[0]?.data?.post);
        }
    }, [newData]);



    const handleCategoryClick = (element) => {
        setActiveCategory(element?.id);
        setActiveSubCat(finalData[0]?.data?.post);
        setCatTitle(element?.title);
        console.log("Category clicked:", activeCategory);
        console.log("Subcategory:", activeSubCat);
    };

    const handleSubCatClick = (subCat) => {
        setActiveSubCat(subCat);
        console.log("Subcategory clicked:", activeSubCat);
    };






    const [show, setShow] = useState(false);
    const [winWidth, setWinWidth] = useState(true)
    const [popupTitle, setPopupTitle] = useState('')


    const handleClose = () => setShow(false);
    const handleShow = (e) =>{
        setShow(true);
        setPopupTitle(e?.data?.title)
    }

    useEffect(() => {
        let getLists = document.querySelector('.desktop-lists');
        let getLi = getLists.querySelectorAll('li');
        if (typeof (queryParam) === 'undefined') {
            getLi[0]?.classList.add('active')
        } else {
            getLi[0]?.classList.remove('active')
        }
    }, [queryParam])


    // const optionsOne = theData?.map(location => (
    //     {value: location?.slug, label: location?.title}
    // ));
    //
    const optionsOne = [
        {value: 'Aviation-Products', label: 'Aviation Products'},
        {value: 'circulating-oils', label: 'Circulating Oils'},
        {value: 'compressor-oils', label: 'Compressor Oils'}
    ]

    let handleMobileSelect = (e) => {
        // Router.push({
        //     pathname: '/industrial-specialty-lubricants',
        //     query: {item: e},
        // })
    }

    const [selectedCVName, setSelectedCVName] = useState('');
    const [cv, setCv] = useState('');


    const handleCVChange = (event) => {
        console.log(event)
       setCv(event.target.files[0])
        setSelectedCVName(event.target.files[0].name);
    };


    const DropdownIndicator = props => {
        return (
            <components.DropdownIndicator {...props}>
                <CaretDownIcon/>
            </components.DropdownIndicator>
        );
    };



    const CaretDownIcon = () => {
        return <svg xmlns="http://www.w3.org/2000/svg" width="13.414" height="7.414" viewBox="0 0 13.414 7.414">
            <g id="Group_15659" data-name="Group 15659" transform="translate(1479.207 -192.793) rotate(90)">
                <line id="Line_3883" data-name="Line 3883" x2="6" y2="6" transform="translate(193.5 1466.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
                <line id="Line_3884" data-name="Line 3884" y1="6" x2="6" transform="translate(193.5 1472.5)" fill="none"
                      stroke="#B2A89F" stroke-linecap="round" stroke-width="1"/>
            </g>
        </svg>;
    };
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.career);

    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset, watch} = useForm({
        mode: "all",
    })

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.SUBMITFORM;

        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('message', e?.message);
        formData.append('file', cv);
        formData.append('form_id', 'career-form');

        if (e.email !== '' && e.name !== '' && e.phone !== '' && e.file !=='') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
        setSelectedCVName('');
    };

    let count=0;


    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        // if(count>0){
        //     toast.error('Please fill out the correct inputs');
        // }
        count=0;
    };
    // useEffect(() => {
    //     if (responseData && responseData?.error !== '') {
    //         error(responseData?.error)
    //     }
    //     if (responseData && responseData?.success !== '') {
    //         success(responseData?.success)
    //     }
    //
    // }, [responseData])

    const [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])

    return (
        <StyledFaq className="job-lists pt-120 pb-120">
            <Container>

                <Row className={'category-list'}>
                    <Col>
                        <TitleTwo text={'Available Jobs'} color={'#000000'} lineColor={'#000000'}/>
                        <ul>
                            {
                                filterList && filterList?.length >0 &&
                                filterList?.map((element,index)=>{
                                    return(
                                        <li className={element?.id === activeCategory ? 'active' : ''} key={index} onClick={() => handleCategoryClick(element)}>
                                            <a >{reactHtmlParser(element?.title)}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>
                <Row>
                    <Col sm={4} className='job-lists__sidebar'>
                        <h3>{reactHtmlParser(catTitle)}</h3>
                        <ul className='desktop-lists'>
                            {
                                postArray && postArray?.length > 0 &&
                                postArray?.map((e, i) => {
                                    return (
                                        <li className={e?.label === activeSubCat ? 'active' : ''} key={i}
                                            onClick={() => handleSubCatClick(e.value)}>
                                            <a>{reactHtmlParser(e?.label)}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                        <div className={'share-area'}>
                            <p>Share:</p>
                            <ul className={'share-area__icons'}>
                                <li className={''}>
                                    <svg id="Component_142_59" data-name="Component 142 – 59"
                                         xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Component_141_1" data-name="Component 141 – 1">
                                            <circle id="Ellipse_366" data-name="Ellipse 366" cx="17" cy="17" r="17"
                                                    fill="#8a1313"/>
                                        </g>
                                        <path id="Icon_11_"
                                              d="M6.368,9.419H4.633c-.28,0-.368-.105-.368-.368V6.931c0-.28.105-.368.368-.368H6.368V5.021a3.892,3.892,0,0,1,.473-1.98A2.907,2.907,0,0,1,8.4,1.745,3.9,3.9,0,0,1,9.75,1.517h1.717c.245,0,.35.105.35.35v2c0,.245-.105.35-.35.35-.473,0-.946,0-1.419.018a.633.633,0,0,0-.718.718c-.018.526,0,1.034,0,1.577h2.033c.28,0,.385.105.385.385v2.12c0,.28-.088.368-.385.368H9.329v5.712c0,.3-.088.4-.4.4H6.736c-.263,0-.368-.105-.368-.368V9.419Z"
                                              transform="translate(8.959 8.482)" fill="#fff"/>
                                    </svg>

                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={''}>
                                    <svg id="Component_142_58" data-name="Component 142 – 58"
                                         xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Component_141_1" data-name="Component 141 – 1">
                                            <circle id="Ellipse_366" data-name="Ellipse 366" cx="17" cy="17" r="17"
                                                    fill="#8a1313"/>
                                        </g>
                                        <path id="Path_14579" data-name="Path 14579"
                                              d="M454.119,21.753a8.117,8.117,0,0,0,8.172-8.172q0-.187-.008-.371a5.846,5.846,0,0,0,1.433-1.487,5.731,5.731,0,0,1-1.649.452,2.882,2.882,0,0,0,1.263-1.589,5.758,5.758,0,0,1-1.824.7,2.875,2.875,0,0,0-4.895,2.619,8.155,8.155,0,0,1-5.92-3,2.874,2.874,0,0,0,.889,3.835,2.852,2.852,0,0,1-1.3-.359c0,.012,0,.024,0,.037a2.874,2.874,0,0,0,2.3,2.816,2.869,2.869,0,0,1-1.3.049,2.876,2.876,0,0,0,2.683,1.995A5.763,5.763,0,0,1,450.4,20.5a5.833,5.833,0,0,1-.685-.04,8.131,8.131,0,0,0,4.4,1.29"
                                              transform="translate(-439.716 0.625)" fill="#fff"/>
                                    </svg>

                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={''}>
                                    <svg id="Component_142_56" data-name="Component 142 – 56"
                                         xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Component_141_1" data-name="Component 141 – 1">
                                            <circle id="Ellipse_366" data-name="Ellipse 366" cx="17" cy="17" r="17"
                                                    fill="#8a1313"/>
                                        </g>
                                        <path id="LinkedIn"
                                              d="M1101.435,3924.414v5.117h-3v-4.774c0-1.2-.434-2.018-1.521-2.018a1.64,1.64,0,0,0-1.539,1.085,2.008,2.008,0,0,0-.1.724v4.983h-3s.04-8.086,0-8.924h3v1.266l-.019.028h.019v-.028a2.985,2.985,0,0,1,2.705-1.475C1099.954,3920.4,1101.435,3921.674,1101.435,3924.414Zm-12.3-8.108a1.548,1.548,0,1,0-.04,3.084h.02a1.549,1.549,0,1,0,.02-3.084Zm-1.52,13.226h3v-8.924h-3Z"
                                              transform="translate(-1077.435 -3906.306)" fill="#fff"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                </li>
                            </ul>
                        </div>
                    </Col>

                    <Col sm={8} className='job-lists__content'>
                        <Accordion defaultActiveKey="0">
                            {
                                finalData && finalData?.length > 0 &&
                                finalData?.map((e, i) => {
                                    return (
                                        <Accordion.Item eventKey={`${i}`}>
                                            <Accordion.Header>
                                                {reactHtmlParser(e?.data?.title)}
                                                <span className={'prevhover'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="11.22" height="6.01"
                                             viewBox="0 0 11.22 6.01">
                                            <path id="Path_8257" data-name="Path 8257" d="M0,0,4.2,3.6,8.4,0"
                                                  transform="translate(9.81 4.6) rotate(180)" fill="none"
                                                  stroke="#fffdfc" stroke-linecap="round" stroke-linejoin="round"
                                                  stroke-width="2"/>
                                        </svg>
                                    </span>
                                            </Accordion.Header>
                                            <Accordion.Body>
                                                <p>
                                                    {reactHtmlParser(e?.data?.short_desc)}
                                                </p>
                                                {reactHtmlParser(e?.data?.description)}

                                                <div className="button-group d-flex" onClick={() => handleShow(e)}>
                                                    <ApplyButton text={'Apply Now'} borderColor={'#000000'}
                                                                 color={'#000000'}
                                                                 hoverBorderColor={'#8A1313'}
                                                    />
                                                </div>
                                            </Accordion.Body>
                                        </Accordion.Item>

                                    )
                                })
                            }
                        </Accordion>
                    </Col>

                </Row>


            </Container>
            <Modal show={show} className='job-modal' onHide={handleClose}>
                <Modal.Body>


                    <div className="modal-data">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="modal-title">
                                        <h5>{popupTitle}</h5>
                                        <div onClick={handleClose} className="modal-close dc-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32.121" height="32.121"
                                                 viewBox="0 0 32.121 32.121">
                                                <g id="Group_23302" data-name="Group 23302"
                                                   transform="translate(-1226.939 -58.939)">
                                                    <line id="Line_3591" data-name="Line 3591" x2="30" y2="30"
                                                          transform="translate(1228 60)" fill="none" stroke="#6e6e6e"
                                                          stroke-linecap="round" stroke-width="1.5"/>
                                                    <line id="Line_3592" data-name="Line 3592" y1="30" x2="30"
                                                          transform="translate(1228 60)" fill="none" stroke="#6e6e6e"
                                                          stroke-linecap="round" stroke-width="1.5"/>
                                                </g>
                                            </svg>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className='modal-data__content'>

                                    <Form>
                                        <div className="form__phoneEmail">
                                            <Form.Group className={'single-input'}>
                                                <Form.Control
                                                    className={errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                    {...register("name", {
                                                        required: 'Please enter your full name'
                                                    })}
                                                    type="text"
                                                    placeholder="Name*"
                                                />
                                                <p className={'form-error'}>{errors.name?.message}</p>

                                            </Form.Group>

                                            <Form.Group className={'single-input'}>
                                                <Form.Control
                                                    className={errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                    {...register("phone", {
                                                        required: {
                                                            value: true,
                                                            message: 'Please enter your phone first'
                                                        },
                                                        pattern: {
                                                            value: /^01[0-9]{9}$/,
                                                            message: 'Please enter a valid 11 digit phone number'
                                                        }
                                                    })}
                                                    type="number"
                                                    placeholder="Phone Number*"/>
                                                <p className={'form-error'}>{errors.phone?.message}</p>

                                            </Form.Group>

                                            <Form.Group className={'single-input'}>
                                                <Form.Control
                                                    className={errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                    {...register("email", {
                                                        required: {
                                                            value: true,
                                                            message: 'Please enter your email'
                                                        },
                                                        pattern: {
                                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                            message: 'Please enter a valid email address'
                                                        }
                                                    })}
                                                    type="email"
                                                    placeholder="Email*"/>
                                                <p className={'form-error'}>{errors.email?.message}</p>

                                            </Form.Group>


                                        </div>
                                        <Form.Control as={"textarea"}
                                                      className={errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                                      {...register("message", {
                                                          required: {
                                                              // value:true,
                                                              message: 'Please enter your Message'
                                                          },

                                                      })}
                                                      type="text"
                                                      placeholder="Cover Letter"/>

                                        <div className="button-group">
                                            <div className="attachCvName">
                                                <div className="attach-cv">

                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16.107"
                                                         height="19.125" viewBox="0 0 16.107 19.125">
                                                        <path id="Icon_ionic-ios-attach"
                                                              data-name="Icon ionic-ios-attach"
                                                              d="M.589,12.115a.593.593,0,0,0,.592-.592V4.14a3.063,3.063,0,0,1,.836-2.093,3.006,3.006,0,0,1,2.108-.9h.517a2.952,2.952,0,0,1,2.081.934,3.049,3.049,0,0,1,.908,2.1V13.7a2.064,2.064,0,0,1-.657,1.5,2.26,2.26,0,0,1-1.557.619A2.114,2.114,0,0,1,3.281,13.7V4.808a1.2,1.2,0,0,1,1.1-1.223A1.167,1.167,0,0,1,5.454,4.808V9.882a.592.592,0,0,0,1.185,0V4.808A2.278,2.278,0,0,0,2.761,3.1,2.4,2.4,0,0,0,2.1,4.808V13.7a3.319,3.319,0,0,0,3.331,3.316,3.333,3.333,0,0,0,2.362-.968A3.277,3.277,0,0,0,8.773,13.7V4.174A4.244,4.244,0,0,0,7.535,1.212,4.105,4.105,0,0,0,4.645,0H4.128A4.148,4.148,0,0,0,1.246,1.219,4.266,4.266,0,0,0,.338,2.533,3.976,3.976,0,0,0,0,4.132v7.391A.588.588,0,0,0,.589,12.115Z"
                                                              transform="translate(8.509 0) rotate(30)" fill="#fff"/>
                                                    </svg>

                                                    <Form.Control
                                                        {...register('cv', {
                                                            required: {
                                                                value: true,
                                                                message: 'Please upload your resume'
                                                            },
                                                        })}
                                                        type="file"
                                                        accept={"application/pdf"}
                                                        id="cv-upload"
                                                        style={{display: "none"}}
                                                        onChange={handleCVChange}
                                                    />

                                                    <Form.Label htmlFor="cv-upload" className="cv-upload-label"
                                                                style={{display: selectedCVName ? "none" : "block"}}>
                                                        Attach Cv
                                                    </Form.Label>
                                                    {selectedCVName && (
                                                        <div className="file-name">
                                                            {selectedCVName.length > 10 ? (
                                                                <>
                                                                    {selectedCVName.substring(0, 10)}...{selectedCVName.split('.').pop()}
                                                                </>
                                                            ) : (
                                                                selectedCVName
                                                            )}
                                                        </div>
                                                    )}
                                                    <p className={'form-error'}>{errors.cv?.message}</p>

                                                </div>
                                                <div onClick={handleSubmit(onSubmit, onError)}>
                                                    <ApplyButton text={'Submit'} background={'#8A1313'} color={'#FFFFFF'}
                                                                 svgColor={'#FFFFFF'}
                                                    />
                                                </div>

                                            </div>
                                        </div>
                                    </Form>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </Modal.Body>
            </Modal>

        </StyledFaq>
    );
};

const StyledFaq = styled.section`
  min-height: 70vh;
  background: #FFFFFF;

  .category-list{
    margin-bottom: 100px;
    
    ul{
      display: flex;
      flex-wrap: wrap;
      margin-top: 60px;
      li{
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        border: 1px solid #000000;
        padding: 12px 32px;
        border-radius: 29px;
        margin-right: 20px;
        transition: all .3s ease;
        width: max-content;
        position: relative;
        overflow: hidden;
        &:last-child{
          margin-right: 0;
        }
        a {
          transition: color .3s ease;
          position: relative;
          z-index: 2;
          width: max-content;
          color: #000000;
        }
        &:before{
          top: 100%;
          content: "";
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          background-color: ${p => p.hoverBackground || hover};
          height: 100%;
          width: 100%;
          margin: auto;
          transition: all .5s ease;
          border-radius: 24px;
        }
        &:hover{
          a{
            color: #FFFFFF !important;
          }
          border: 1px solid #8A1313;
          &:before{
            top:0;
          }
        }
        &.active{
          background-color: ${hover};
          border: 1px solid ${hover};
          a{
            color: #FFFFFF;
          }
        }
        @media(max-width: 767px){
          margin-bottom: 20px;
            width: 100%;
            margin-right: 0;
            a{
                text-align: center;
                width: 100%;
                display: block;
            }
        }
      }
    }
    @media(max-width: 767px){
      margin-bottom: 60px;
    }
  }

  .top-title{
    display: flex;
    .col-sm-8{
      padding: 0; 
    }
    margin-bottom: 60px;
    h4{
      font-size: 36px;
      line-height: 40px;
      font-weight: 600;
    }
  }
  
  .job-lists__sidebar {

    &__mobile-dropdown {
      @media (min-width: 551px) {
        display: none;
      }

      img {
        margin: auto;
      }
    }

    p {
      font-size: 32px;
      line-height: 36px;
      font-weight: 600;
      width: 80%;
    }
    h3{
      font-weight: 300;
      font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
      margin-bottom: 20px;
      color: ${hover};
    }

    ul {

      li {
        margin-bottom: 10px;

        a {
          font-size: 16px;
          font-weight: 400;
          line-height: 16px;
          position: relative;
            cursor: pointer;

          // &:after {
          //   content: '';
          //   position: absolute;
          //   border-radius: 50%;
          //   top: 4px;
          //   bottom: 0;
          //   right: -25px;
          //   margin: auto;
          //   height: 10px;
          //   width: 10px;
          //   background-color: ${hover};
          //   display: none;
          // }
        }

        &.active {
          a {
            color: ${hover};

            &:after {
              display: block;
            }
          }
        }
      }
    }
  }

  .accordion-item {
    margin-bottom: 40px;

    &:nth-last-child(1) {
      margin-bottom: 0;
    }
  }

  .accordion-header {
    position: relative;
    margin: 0;

    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: #8A1313;
      font-size: 40px;
      font-weight: 300;
      line-height: 52px;
      text-transform: capitalize;
      padding-bottom: 15px;
      border-bottom: 1px solid #8A1313;
      width: 100%;
      text-align: left;
      font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;

      //margin-bottom: 50px;
      transition: all .4s ease;
        @media(max-width: 767px){
            font-size: 28px;
        }

      &.collapsed {
        color: #1C1718;
        border-color: #3C4348;

        span {
          background-color: #1C1718;

          svg {
            transform: rotate(180deg);
          }

          &:after {
            background-color: ${hover} !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 40px;
      width: 40px;
      border-radius: 50%;
      color: #ffffff;
      background-color: ${hover};
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        //height: 0;
        //width: 0;
        background-color: #252525;
        //border-radius: 50%;
        //opacity: 0;
        //transition: all .4s ease;
        //content: '';
        //position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(0deg);
      }

    }

    &:hover {
      span {
        //&:after {
        //  height: 100%;
        //  width: 100%;
        //  opacity: 1;
        //}
      }

      button {
        color: ${hover};
        border-color: ${hover};
      }
    }
  }

  .accordion-body {
    margin-bottom: 80px;
    p {
      font-size: 16px;
      line-height: 22px;
      font-weight: 600;
      padding-top: 40px;
      color: rgba(28, 23, 24, 0.8);
    ;
    }

    h4 {
      font-size: 20px;
      line-height: 28px;
      margin-bottom: 20px;
    }

    ul {
      margin-top: 20px;
      margin-bottom: 40px;


      li {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 10px;
        position: relative;
        padding-left: 20px;

        &:after {
          position: absolute;
          content: '';
          height: 8px;
          width: 8px;
          border-radius: 50%;
          background-color: ${hover};
          left: 0px;
          top: 8px;
        }

        &:nth-last-child(1) {
          margin-bottom: 0;
        }
      }
    }
  }
  
  
  
  .button-group {
    margin-top: 45px;
  }

 
  
  
 


   .css-13cymwt-control{
     border-radius: 23px;
     border: 1px solid ${hover} !important;
   }


  .css-yk16xz-control, .css-1pahdxg-control {
    border-radius: 23px !important;
    border-color: 1px solid ${hover};
    height: 40px;
    background-color: ${hover};
    cursor: pointer;
    padding-left: 15px;
  }

  .css-yk16xz-control .css-1wa3eu0-placeholder, .css-1pahdxg-control .css-1wa3eu0-placeholder, .css-1uccc91-singleValue {
    color: #ffffff !important;
    font-size: 12px !important;
    font-weight: bold;
    cursor: pointer;
    letter-spacing: 2px !important;
    text-transform: uppercase !important;
  }

  .css-yk16xz-control .css-tlfecz-indicatorContainer, .css-1pahdxg-control .css-tlfecz-indicatorContainer, .css-yk16xz-control .css-1gtu0rj-indicatorContainer, .css-1pahdxg-control .css-1gtu0rj-indicatorContainer {
    margin-right: 20px;
    margin-top: 2px;
  }


  @media (max-width: 767px) {
    &.dc-btn a{
      width: 100% !important;
    }
    overflow: visible;
    .container {
      overflow: visible;
    }

    .job-lists__sidebar {
      margin-bottom: 50px;

      p {
        max-width: 100%;
      }
    }
  }

    .share-area {
        margin-top: 60px;
        p {
            margin-bottom: 10px;
            font-size: 16px;
            line-height: 24px;
            font-weight: 400;
        }

        &__icons {
            display: flex;
            gap: 10px;

            li {
                position: relative;

                button {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }

`;
export default IndustrialLube;
