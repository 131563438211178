import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import moment from "moment";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import {hover, text} from "../../styles/globalStyleVars";


const MyComponent = ({link,img,title,short_desc}) => {

    return (
        <StyledComponent className=''>
            <Link to={link}>
                <div className='presslist__single'>
                    <div className='presslist__single__img'>
                        <Img src={img}/>
                        <div className="arrow">
                            <svg xmlns="http://www.w3.org/2000/svg" width="15" height="10.004" viewBox="0 0 15 10.004">
                                <path id="Icon_ionic-ios-arrow-round-forward" data-name="Icon ionic-ios-arrow-round-forward" d="M17.441,11.443a.681.681,0,0,0-.005.959L20.6,15.575H8.547a.677.677,0,0,0,0,1.355H20.6L17.43,20.1a.686.686,0,0,0,.005.959.675.675,0,0,0,.953-.005l4.293-4.324h0a.761.761,0,0,0,.141-.214.646.646,0,0,0,.052-.261.679.679,0,0,0-.193-.474l-4.293-4.324A.664.664,0,0,0,17.441,11.443Z" transform="translate(-7.875 -11.252)" fill="#8a1313"/>
                            </svg>
                        </div>

                    </div>
                    <div className='presslist__single__content'>
                        <div className='presslist__single__content__text'>
                            <h3>{title}</h3>
                            <p>{short_desc}</p>
                        </div>

                    </div>
                </div>
            </Link>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .presslist {
    &__single {
      margin-bottom: 30px;
      //box-sizing: border-box;
      transition: 1s ease-in-out;
      -webkit-transition: all 0.2s ease-in-out;
      border: 1px solid #F2F2F2;
      height: 100%;
      //border-radius: 12px 12px 0 0;

      &__img {
        position: relative;
        padding-top: calc(260 / 420 * 100%);
        //border-radius: 12px 12px 0 0;
        overflow: hidden;

        .global-image {
          overflow: hidden;

          img {
            transform: scale(1.01);
            transition: 1.3s ease;
          }
        }
        .arrow{
          position: absolute;
          width: 30px;
          height: 30px;
          top: 23px;
          right: 23px;
          background-color: #ffffff;
          border-radius: 50%;
          display: flex;
          justify-content: center;
          align-items: center;
          svg{
            transform: rotate(315deg);
          }
        }

        img {
          width: 100%;
          height: 100%;
          object-fit: contain;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
            background-color: #f9f9f9;
        }

        &:hover {
          .global-image img {
            transform: scale(1.06) !important;
          }
        }

      }

      &__content {
        padding: 20px 20px 30px 20px;
        position: relative;
        background-color: #F9F9F9;
        height: 100%;
          @media(max-width: 767px){
              padding: 20px 20px 30px 0px;
          }
        &__text {
          min-height: 100px;
        }

        p {
          font-size: 12px;
          line-height: 20px;
          font-weight: 400;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          text-overflow: ellipsis;

        }

        h3 {
          font-weight: 400;
          margin-bottom: 15px;
            font-size: 16px;
            line-height: 24px;
        }

        //
        //p {
        //  color: #222222;
        //  margin-bottom: 30px;
        //  //height: 80px;
        //  display: -webkit-box;
        //  -webkit-line-clamp: 3;
        //  -webkit-box-orient: vertical;
        //  overflow: hidden;
        //  text-overflow: ellipsis;
        //  font-weight: 400;
        //
        //}

      }

      @media (max-width: 767px) {
        margin-bottom: 20px !important;
        :last-child {
          margin-bottom: 0px;
        }
      }

    }
  }
`;

export default React.memo(MyComponent);
