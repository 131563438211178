import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "./Img";
import { gsap } from 'gsap';
import {ScrollTrigger} from "gsap/ScrollTrigger";


const MyComponent = ({ title, img, link, date }) => {
    const isExternal = link && (link.startsWith('http://') || link.startsWith('https://') || link.startsWith('www'));

    const formattedDate = new Date(date);
    const dayOfMonth = formattedDate.getDate();
    const monthAbbreviation = formattedDate.toLocaleString('default', { month: 'short' });
    const yearAbbreviation = formattedDate.getFullYear();

    return (
        <StyledComponent className={'media-single'}>
            {isExternal ? (
                <>
                <a href={link} target="_blank" rel="noopener noreferrer"></a>
                    <div className="media-single__img">
                        <Img src={img} />
                    </div>
                    <div className="media-single__content">
                        <h4>{title}</h4>
                        <p>
                            <span className={'date'}>{dayOfMonth}</span>
                            <span className={'monthYear'}>{`${monthAbbreviation} ${yearAbbreviation}`}</span>
                        </p>
                    </div>
                </>
            ) : (
                <>
                <Link to={link}/>
                    <div className="media-single__img">
                        <Img src={img} />
                    </div>
                    <div className="media-single__content">
                        <h4>{title}</h4>
                        <p>
                            <span className={'date'}>{dayOfMonth}</span>
                            <span className={'monthYear'}>{`${monthAbbreviation} ${yearAbbreviation}`}</span>
                        </p>
                    </div>
                </>
            )}
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  margin-bottom: 40px;

  a {
    position: absolute;
    height: 100%;
    width: 100%;
    z-index: 2;
    top: 0;
    left: 0;
  }

  .media-single__img {
    padding-top: calc(460 / 370 * 100%);
    position: relative;
    overflow: hidden;
&:after{
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0.9), /* Top (more black) */
            rgba(0, 0, 0, 0.5),
            rgba(0, 0, 0, 0.5),
                /* Center (less black) */
            rgba(0, 0, 0, 0.9)  /* Bottom (more black) */
    );
    pointer-events: none;
}
    img{
      transition: all 0.5s ease-in;
    }
  }

  .media-single__content {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    padding: 30px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: space-between;

    h4 {
      color: #FFFFFF;
      font-size: 18px;
      line-height: 24px;
      width: 100%;
      font-weight: 400;
    }

    p {
      color: #FFFFFF;
      width: 100%;
      display: flex;
      justify-content: space-between;
      padding-top: 20px;
      border-top: 1px solid #FFFFFF;
      .date{
        font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
        font-size: 40px;
        line-height: 52px;
      }
      .monthYear{
        font-size: 16px;
        line-height: 24px;
      }
    }
  }

  &:hover {
    img {
      transform: scale(1.04);
    }
  }

  @media (max-width: 767px) {
    .media-single__content {
      padding: 20px;

      h4 {
        font-size: 20px;
        line-height: 26px;
      }

    }
  }
`;

export default MyComponent;
