import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Form} from "react-bootstrap";
import ApplyButton from "../ApplyButton";
import TitleTwo from "../TitleTwo";
import {hover} from "../../styles/globalStyleVars";
import {useDispatch, useSelector} from "react-redux";
import {useForm} from "react-hook-form";
import {toast} from "react-toastify";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/career";
const MyComponent = () => {
    const [selectedFileName, setSelectedFileName] = useState('');
    const [file, setFile] = useState('')
    const handleFileChange = (event) => {
        setFile(event.target.files[0])
        setSelectedFileName(event.target.files[0].name);
    };
    const dispatch = useDispatch();
    const responseData = useSelector(state => state.career);

    const {register, control, handleSubmit, formState: {errors, isSubmitSuccessful}, reset, watch} = useForm({
        mode: "all",
    })

    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const onSubmit = (e) => {

        let api_services = apiEndPoints.SUBMITFORM;

        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('message', e?.message);
        formData.append('file', file);
        formData.append('form_id', 'join-form');
        console.log(e?.file)
        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
        }
        setSelectedFileName('');
    };

    let count=0;


    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        // if(count>0){
        //     toast.error('Please fill out the correct inputs');
        // }
        count=0;
    };
    return (
        <StyledComponent className={'pt-150 pb-150'}>
            <Container>
                <Row>
                    <Col md={12}>
                        <div className="modal-title">
                            <TitleTwo text={'Join Our Team'} color={'#000000'} lineColor={'#000000'}/>
                            <p>We are looking for motivated individuals who
                                are passionate about making a difference and eager to contribute to
                                our team's success. This is a fantastic opportunity to join a dynamic
                                and growing company that values innovation, collaboration,
                                and personal growth. </p>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col md={12} className='modal-data__content'>

                        <Form>
                            <div className="form__phoneEmail">
                                <Form.Group className={'single-input'}>
                                    <Form.Control
                                        className={errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("name", {
                                            required: 'Please enter your full name'
                                        })}
                                        type="text"
                                        placeholder="Name*"
                                    />
                                    <p className={'form-error'}>{errors.name?.message}</p>

                                </Form.Group>

                                <Form.Group className={'single-input'}>
                                    <Form.Control
                                        className={errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("phone", {
                                            required: {
                                                value: true,
                                                message: 'Please enter your phone first'
                                            },
                                            pattern: {
                                                value: /^01[0-9]{9}$/,
                                                message: 'Please enter a valid 11 digit phone number'
                                            }
                                        })}
                                        type="number"
                                        placeholder="Phone Number*"/>
                                    <p className={'form-error'}>{errors.phone?.message}</p>

                                </Form.Group>

                                <Form.Group className={'single-input'}>
                                    <Form.Control
                                        className={errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                        {...register("email", {
                                            required: {
                                                value: true,
                                                message: 'Please enter your email'
                                            },
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: 'Please enter a valid email address'
                                            }
                                        })}
                                        type="email"
                                        placeholder="Email*"/>
                                    <p className={'form-error'}>{errors.email?.message}</p>

                                </Form.Group>


                            </div>
                            <Form.Control as={"textarea"}
                                          className={errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                          {...register("message", {
                                              required: {
                                                  // value:true,
                                                  message: 'Please enter your Message'
                                              },

                                          })}
                                          type="text"
                                          placeholder="Cover Letter"/>

                            <div className="button-group">
                                <div className="attachCvName">
                                    <div className="attach-cv">

                                        <svg xmlns="http://www.w3.org/2000/svg" width="16.107" height="19.125"
                                             viewBox="0 0 16.107 19.125">
                                            <path id="Icon_ionic-ios-attach" data-name="Icon ionic-ios-attach"
                                                  d="M.589,12.115a.593.593,0,0,0,.592-.592V4.14a3.063,3.063,0,0,1,.836-2.093,3.006,3.006,0,0,1,2.108-.9h.517a2.952,2.952,0,0,1,2.081.934,3.049,3.049,0,0,1,.908,2.1V13.7a2.064,2.064,0,0,1-.657,1.5,2.26,2.26,0,0,1-1.557.619A2.114,2.114,0,0,1,3.281,13.7V4.808a1.2,1.2,0,0,1,1.1-1.223A1.167,1.167,0,0,1,5.454,4.808V9.882a.592.592,0,0,0,1.185,0V4.808A2.278,2.278,0,0,0,2.761,3.1,2.4,2.4,0,0,0,2.1,4.808V13.7a3.319,3.319,0,0,0,3.331,3.316,3.333,3.333,0,0,0,2.362-.968A3.277,3.277,0,0,0,8.773,13.7V4.174A4.244,4.244,0,0,0,7.535,1.212,4.105,4.105,0,0,0,4.645,0H4.128A4.148,4.148,0,0,0,1.246,1.219,4.266,4.266,0,0,0,.338,2.533,3.976,3.976,0,0,0,0,4.132v7.391A.588.588,0,0,0,.589,12.115Z"
                                                  transform="translate(8.509 0) rotate(30)" fill="#fff"/>
                                        </svg>

                                        <Form.Control
                                            {...register('file', {
                                                required: {
                                                    value: true,
                                                    message: 'Please upload your resume'
                                                },
                                            })}
                                            type="file"
                                            accept=".pdf"
                                            id="resume-upload"
                                            style={{display: "none"}}
                                            onChange={handleFileChange}
                                        />

                                        <Form.Label htmlFor="resume-upload" className="resume-upload-label"
                                                    style={{display: selectedFileName ? "none" : "block"}}>
                                            Attach CV
                                        </Form.Label>
                                        {selectedFileName && (
                                            <div className="file-name">
                                                {selectedFileName.length > 10 ? (
                                                    <>
                                                        {selectedFileName.substring(0, 10)}...{selectedFileName.split('.').pop()}
                                                    </>
                                                ) : (
                                                    selectedFileName
                                                )}
                                            </div>
                                        )}

                                        <p className={'form-error'}>{errors.file?.message}</p>


                                    </div>
                                    <div onClick={handleSubmit(onSubmit, onError)}>
                                    <ApplyButton src={'#'} text={'Submit'} background={'#8A1313'} color={'#FFFFFF'}
                                                     svgColor={'#FFFFFF'}
                                        />
                                    </div>

                                </div>
                            </div>
                        </Form>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F9F9F9;
  .modal-title {
    margin-bottom: 60px;
    p{
      margin-top: 40px;
      color: #000000;
    }
  }
  .modal-data__content{
    position: relative;

  }
  .form-control{
    background-color: transparent;
    border:none;
    border-bottom: 1px solid rgba(34,31,31,0.3);
    margin-bottom: 40px;
    padding-left: 0;
    padding-bottom: 20px;
      color: black;
    font-size: 12px;
    line-height: 20px;
    font-weight: 400;
    &::placeholder{
      color: rgba(42,38,39,0.5);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
    }
  }
  .form__phoneEmail{
    display: flex;
    gap: 20px;
    @media(max-width: 767px){
      flex-direction: column;
      input{
        width: 100% !important;
      }
    }
    input{
      width: calc(33.33% - 20px);
    }
  }
  input[type="textarea"]{
    padding-bottom: 80px;
  }

  .attachCvName{
    display: flex;
    gap: 40px;
    margin-bottom: 60px;

    .attach-cv{
      width: 222px;
      cursor: pointer;
      border: 1px solid #fff;
      border-radius: 28px;
      height: 48px;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 10px;
      background: ${hover};
      label{
        margin-bottom: 0;
        cursor: pointer;
        color: #FFFFFF;
      }
      @media(max-width: 767px){
        //width: 100%;
      }
    }
    @media(max-width: 767px){
      flex-direction: column;
    }
  }
  .dc-btn{
    @media(max-width: 767px){
      display: flex;
      //justify-content: center;
    }
  }
  .file-name{
    font-size: 14px;
    opacity: 0.8;
    color: #FFFDFB;
    padding-left: 10px;
  }

  p{
    color: #FFFDFB;
    margin-bottom: 60px;
    @media(max-width: 767px){
      margin-bottom: 40px;
    }
  }
    .single-input{
        flex: 0 0 33%;
        position: relative;
        input{
            width: 100%;
        }
        .form-error{
            top: 50%;
        }
    }
    
    .button-group{
        position: relative;
        .form-error{
            top: 100%;
            left: 0;
        }
    }
`;

export default MyComponent;
