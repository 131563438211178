import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover} from "../../styles/globalStyleVars";

const MyComponent = ({data}) => {
    return (
        <StyledComponent className={'mission-vision__box pb-150'}>
                <Container>
                    <Row className={'mission-vision__box__inner d-flex align-items-stretch'}>
                                <Col md={6} className={''} >
                                    <div className={'mission-vision__box__inner__content'}>
                                        <h2>{data?.posts?.list?.[0]?.data?.title}</h2>
                                        <p className={''}>
                                            {data?.posts?.list?.[0]?.data?.description}
                                        </p>
                                    </div>
                                </Col>

                                <Col md={6} className={''}>
                                    <div className={'mission-vision__box__inner__content'}>
                                        <h2>{data?.posts?.list?.[1]?.data?.title}</h2>
                                        <p className={''}>
                                            {data?.posts?.list?.[1]?.data?.description}
                                        </p>
                                    </div>
                                </Col>
                    </Row>
                </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background: rgb(245, 245, 245);
  margin-top: -10%;
  .mission-vision__box__inner{
    gap: 30px;
    .col-md-6{
      flex: 0 0 calc(50% - 15px);
      background-color: #FFF;
      //box-shadow: 0 3px 10px rgba(0,0,0,0.1);
        box-shadow: rgba(0, 12, 60, 0.1) 0px 30px 80px, 
                    rgba(0, 12, 60, 0.1) 0px 12px 24px;
        @media (max-width: 767px){
            box-shadow: none;
        }
    }
    &__content{
      padding: 40px 70px 60px 40px;
      h2{
        font-size: 40px;
        line-height: 52px;
        color: ${hover};
        margin-bottom: 60px;
          padding-bottom: 10px;
          border-bottom: 1px solid rgba(110, 110, 110, 0.3);
      }
        p{
            text-align: justify;
        }
    }
    @media(max-width: 767px){
      flex-direction: column;
      &__content{
        padding: 30px 15px 30px 15px;
        h2{
          margin-bottom: 40px;
        }
      }
    }
  }
  @media(max-width: 767px){
    //margin-top: 30%;
  }
`;

export default MyComponent;
