import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Link} from "react-router-dom";
import {Img} from "../Img";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import ReactHtmlParser from "react-html-parser";

// animation
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/dist/ScrollTrigger";
import {ImgParallax} from "../ImgParallax";
import {hover} from "../../styles/globalStyleVars";


gsap.registerPlugin(ScrollTrigger);


const MyComponent = ({timeline, data}) => {
    const [nav1, setNav1] = useState(null);
    const [nav2, setNav2] = useState(null);

    // Synchronize sliders on mount or when the sliders are set
    useEffect(() => {
        if (nav1 && nav2) {
            nav1.slickGoTo(nav2.innerSlider?.state.currentSlide);
        }
    }, [nav1, nav2]);

    // animation
    ScrollTrigger.refresh()

    // animation
    useEffect(() => {
        let allAnim = document.querySelectorAll('.fade-up');

        allAnim.forEach((el, index) => {
            gsap.fromTo(el, {
                autoAlpha: 0,
                y: 50,
                ease: "none",
            }, {
                y: 0,
                autoAlpha: 1,
                ease: "power2",
                duration: 1,
                scrollTrigger: {
                    id: `${index + 1}`,
                    trigger: el,
                    // start: 'top center+=100',
                    toggleActions: 'play none none reverse',
                }
            })
        })
    })


    const sliderSettings = {
        dots: false,
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 500,
        autoplaySpeed: 5500,
        pauseOnHover: false,
    };
    const dotSettings = {
        dots: false,
        slidesToShow: 7,
        slidesToScroll: 1,
        arrows: false,
        autoplay: true,
        speed: 1000,
        autoplaySpeed: 5500,
        pauseOnHover: false,
        focusOnSelect: true,
        infinite: true,
        centerMode: true, // Enable center mode
        centerPadding: '50px',
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 5,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 4,
                    slidesToScroll: 1,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 1
                }
            }
        ]
    };
    return (
        <StyledComponent className='timeline-slider fade-up' id={'milestone'}>
            <ImgParallax src={data?.images?.list?.[0]?.full_path}/>

            <div className="container">
                <Row>
                    <Col sm={7}>
                        <div className="timeline-slider__content">
                            <Slick {...sliderSettings} asNavFor={nav2} ref={(slider1) => setNav1(slider1)}>
                                {
                                    data?.posts?.list && data?.posts?.list?.length>0 &&
                                    data?.posts?.list?.map((element,index)=>{
                                        return(
                                    <div className='timeline-slider__content__single'
                                         key={index}>
                                        <h2>{element?.data?.title}</h2>
                                        {ReactHtmlParser(element?.data?.description)}
                                    </div>
                                        )
                                    })
                                }
                            </Slick>
                        </div>
                    </Col>

                    <div className="timeline-slider__dots" >
                        <ul>
                            {
                                data?.posts?.list && data?.posts?.list?.length>0 &&
                                <Slick asNavFor={nav1}
                                       ref={(slider2) => setNav2(slider2)}  {...dotSettings}>
                                    {

                                        data?.posts?.list?.map((element)=>{
                                            return(
                                                <li><span></span><p>{element?.data?.title}</p></li>
                                            )
                                        })
                                    }

                                </Slick>
                            }

                        </ul>
                    </div>
                </Row>

            </div>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
  //height: calc(100vh + 232px);
  height: 1000px;
  padding-top: 120px;
  
  
  //padding-top: calc(1000 / 1366 * 100%);
  //.slick-track {
  //  width: 100% !important;
  //}

  ul.list{
    padding-left: 0px;
    counter-reset: count 0;
    display: flex;
    -webkit-box-pack: justify;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 30px;
  }
  
  ul.list li{
    position: relative;
    counter-increment: count 1;
    padding: 0px 0px 20px 0px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
    margin: 0px 0px 30px;
    flex: 0 0 calc(100% - 15px);
  }

  //ul.list li::before {
  //  content: counter(count, upper-alpha);
  //  position: absolute;
  //  height: 25px;
  //  width: 25px;
  //  padding: 0px;
  //  background: linear-gradient(rgb(173, 0, 0) 0%, rgb(255, 0, 0) 100%);
  //  border-radius: 50%;
  //  color: white;
  //  top: 0px;
  //  left: 0px;
  //  display: flex;
  //  -webkit-box-pack: center;
  //  justify-content: center;
  //  -webkit-box-align: center;
  //  align-items: center;
  //  font-size: 12px;
  //  font-weight: 500;
  //  line-height: 18px;
  //}
  
  @media(min-width: 1024px){
    //height: 100vh;

  }
  .container {
    position: relative;
    height: 100%;
    z-index: 2;
  }

  .timeline-slider__content {
    //margin-top: 120px;
    position: relative;
    z-index: 5;

    &__single {
      opacity: 0;
      padding: 50px 40px;
      border: 1px solid #FFF;
      //background: linear-gradient(135deg, rgba(255, 255, 255, 0.4) 0%, rgba(34, 34, 34, 0.1) 100%);
      //backdrop-filter: blur(30px) saturate(100%);
      background-color: #F9F9F9;
      //backdrop-filter: blur(30px) brightness(0) opacity(1) saturate(150%);
      transition: opacity .3s ease;
      transition-delay: .2s;
      .title{
        margin-bottom: 0;
      }
      h2{
        font-weight: 300;
        color: ${hover};
        font-size: 40px;
        @media(max-width: 767px){
          font-size: 28px;
          line-height: 36px;
        }
      }

      ul {
        //margin: 0;

        li {
          color: #2A2627;
          font-size: 14px;
          line-height: 20px;
          font-weight: 500;
          border-bottom: 1px solid rgba(110, 110, 110, 0.30);
          margin-bottom: 15px;

          //&:nth-last-of-type(1) {
          //  margin-bottom: 0;
          //}
        }
      }
      @media(min-width: 1550px){
        padding: 70px 40px;

      }
    }

    .slick-active {
      .timeline-slider__content__single {
        opacity: 1;
        transition-delay: 0s;
      }
    }

  }
  .slick-list, .slick-slider {
    position: relative;
    display: block;
  }
  .slick-slider .slick-list, .slick-slider .slick-track {
    transform: translateZ(0);
  }
  .timeline-slider__dots {
    position: absolute;
    bottom: 360px;
    width: 100%;
    @media(max-width: 767px){
      bottom: 120px;

    }
    //padding-left: 15px;

    .slick-cloned{
      //display: none;
      @media(max-width: 767px){
        display: block;
      }
    }
    ul {
      position: relative;

      &:after {
        content: '';
        position: absolute;
        width: 200vw;
        left: -50vw;
        right: 0;
        background-color: #ffffff;
        height: 1px;
        bottom: 21px;
        z-index: -1;
      }

      li {
        position: relative;
        height: 100px;

        span {
          height: 40px;
          width: 40px;
          background-color: #fff;
          display: block;
          border-radius: 50%;
          position: absolute;
          bottom: -5px;
          z-index: 2;
          left: 0;
          right: 0;
          margin: auto;
          cursor: pointer;

          &:after {
            content: '';
            position: absolute;
            height: 10px;
            width: 10px;
            left: 0;
            right: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            background: red;
            border-radius: 50%;
          }
        }

        p {
          font-size: 20px;
          line-height: 30px;
          color: #ffffff;
          position: absolute;
          bottom: 38px;
          width: 100%;
          text-align: center;
          transition: all .4s ease-out;
          font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
        }
      }

      .slick-current {
        li p {
          font-size: 60px;
          font-weight: 300;
          -webkit-text-stroke-width: 1px;
          -webkit-text-stroke-color: white;
          color: transparent;
          bottom: 50px;
        @media (max-width: 767px) {
            left: 0;
        }
        }
      }
    }
  }

  @media (max-width: 1024px) {
    .col-sm-7 {
      min-width: 70%;
    }
  }

  @media (max-width: 768px) {
    height: 100vh;
    .timeline-slider__content {
      //margin-top: 80px;

      &__single ul li:nth-last-of-type(n+3) {
        display: none;
      }
    }


    .timeline-slider__dots ul {
      .slick-current li p {
        font-size: 40px;
        line-height: 40px;
      }

      li span {
        width: 30px;
        height: 30px;
        bottom: 0;
      }
    }


  }
`;

export default MyComponent;
