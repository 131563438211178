import React, {useState, useEffect, useRef, memo} from 'react';
import styled from 'styled-components';
import {Col, Container, Form, Row} from "react-bootstrap";
import Button from "../Button";
import {toast} from "react-toastify";
import {Img} from "../Img";
import {useForm} from "react-hook-form";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {postForm} from "../../api/redux/contact";
import ReCAPTCHA from 'react-google-recaptcha';


const ListWithForm = ({data}) => {


    const dispatch = useDispatch();
    const responseData = useSelector(state => state.contact);

    // const {register, handleSubmit, formState, reset} = useForm({mode: 'all'});
    const {register, control,formState, handleSubmit, formState: {errors, isSubmitSuccessful}, reset} = useForm({
        mode: "all",
    })
    //--- form submit
    const success = (msg) => toast.success(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    const error = (msg) => toast.error(msg, {
        position: "top-right",
        autoClose: 4000,
        closeOnClick: true,
        progress: undefined,

    });

    // const [isCaptchaChecked, setIsCaptchaChecked] = useState(false);
    // const recaptchaRef = React.createRef();
    //
    // const onReCAPTCHAChange = (value) => {
    //     // Handle reCAPTCHA response
    //     console.log('reCAPTCHA response:', value);
    //     setIsCaptchaChecked(true);
    //
    // };
    const onSubmit = (e) => {

        // const recaptchaResponse = document.querySelector('.g-recaptcha-response');
        //
        // if (!isCaptchaChecked) {
        //     alert('Please check the reCAPTCHA button');
        //     return;
        // }

        let api_services = apiEndPoints.SUBMITFORM;

        var formData = new FormData();
        formData.append('name', e?.name);
        formData.append('email', e?.email);
        formData.append('phone', e?.phone);
        formData.append('message', e?.message);
        formData.append('form_id', 'contact-form');
        // formData.append('recaptchaResponse', e?.recaptchaResponse);

        if (e.email !== '' && e.name !== '' && e.phone !== '') {
            dispatch(postForm([api_services, formData]));
            success('Successfully Submitted')
            reset();
            // if (recaptchaRef.current) {
            //     recaptchaRef.current.reset();
            // }
        }
    };
    let count = 0;
    const onError = (errors) => {
        Object.values(errors).forEach((error) => {
            count++;
        });
        // if (count > 0) {
        //     toast.error('Please fill out the correct inputs');
        // }
        count = 0;
    };
    // useEffect(() => {
    //     if (responseData && responseData?.error !== '') {
    //         error(responseData?.error?.message)
    //     }
    //     if (responseData && responseData?.success !== '') {
    //         success(responseData?.success)
    //
    //     }
    //     reset();
    //
    // }, [responseData])

    return (
        <StyledListWithForm>
            <div className="form-title">
                <h4>Get in touch today!</h4>
                <p>For information related to other operations, consumer products and services, please give us a message.</p>
            </div>
            <div className="form_wrapper">

                <Form className={'form'} onSubmit={handleSubmit(onSubmit, onError)}>

                    <input name={'form_id'} value={'contact-form'} type='hidden'/>
                    <div className="form-group">
                        <Form.Group controlId="formBasicEmail">

                            <Form.Control
                                className={formState?.errors?.name?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("name", {
                                    required: 'Please enter your full name',

                                })}
                                type="text"
                                placeholder="Name*"
                            />
                            <p className={'form-error'}>{errors.name?.message}</p>
                        </Form.Group>
                    </div>
                    <div className="phoneEmail">
                        <div className="form-group">
                            <Form.Group controlId="formBasicEmail">

                                <Form.Control
                                    className={formState?.errors?.email?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("email", {
                                        required: {
                                            value: true,
                                            message: 'Please enter a valid email address'
                                        },
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: 'Please enter a valid email address'
                                        }
                                    })}
                                    type="email"
                                    placeholder="Email*"/>
                                <p className={'form-error'}>{errors.email?.message}</p>
                            </Form.Group>
                        </div>
                        <div className="form-group">
                            <Form.Group controlId="formBasicPhone">

                                <Form.Control
                                    className={formState?.errors?.phone?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                    {...register("phone", {
                                        required: {
                                            value: true,
                                            message: 'Please enter your phone number'
                                        },
                                        pattern: {
                                            value: /^01[0-9]{9}$/,
                                            message: 'Please enter a valid 11 digit phone number'
                                        }
                                    })}
                                    type="number"
                                    placeholder="Phone Number*"/>
                                <p className={'form-error'}>{errors.phone?.message}</p>
                            </Form.Group>
                        </div>
                    </div>

                    <div className="form-group">
                        <Form.Group controlId="formBasicPhone">

                            <Form.Control
                                as="textarea"
                                rows={4}
                                className={formState?.errors?.message?.message ? 'has-error form-control-lg' : 'form-control-lg'}
                                {...register("message", {
                                    required: {
                                        value:true,
                                        message: 'Please enter your Message'
                                    },

                                })}
                                // type="textarea"
                                placeholder="Message*"/>
                            <p className={'form-error'}>{errors.message?.message}</p>
                        </Form.Group>
                    </div>
                    {/*<div className="form-group">*/}
                    {/*    <ReCAPTCHA*/}
                    {/*        ref={recaptchaRef}*/}
                    {/*        sitekey="6LdCaWIpAAAAAG1DbIfA54AHHfm-YbfvhzwKNXN9"*/}
                    {/*        onChange={onReCAPTCHAChange}*/}
                    {/*    />*/}

                    {/*</div>*/}

                    <div className={`'form-group width-fit'}`}>
                        <div onClick={handleSubmit(onSubmit, onError)}>
                            <Button src={'#'} hoverColor={'#FFFFFF'} hoverBackground={'#8A1313'} color={'#000000'}
                                    text={'Submit'} border={'1px solid #000000'} hoverBorderColor={'#8A1313'}
                            />
                        </div>

                    </div>

                </Form>
            </div>
        </StyledListWithForm>
    )
};

const StyledListWithForm = styled.div`
    padding-left: 60px;
    @media (max-width: 767px) {
        padding-left: 0px;
    }

    .form-title {
        margin-bottom: 60px;
    h4{
      font-size: 24px;
      font-weight: 300;
      line-height: 32px;
      font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
      margin-bottom: 20px;
      color: #8A1313;
    }
    @media(max-width: 767px){
      margin-bottom: 0;
    }
    
  }
  
  .form_wrapper {
    height: 100%;

    form {
      display: flex;
      height: 100%;
      flex-direction: column;
      justify-content: space-between;
    }

    .title {
      font-size: 24px;
      font-weight: 4000;
      line-height: 28px;
      color: #F9F9F9;
      margin: 0 0 40px;
    }

    .form-group {
      margin-bottom: 30px;

      &:last-child {
        margin-bottom: 0;
      }

      .form-control {
        box-shadow: none;
        outline: 0;
        border-radius: 0;
        background: transparent;
        height: auto;
        border-left: none;
        border-right: none;
        border-top: none;
        border-color: rgba(198, 198, 198, 0.5);
        padding: 0 0 8px;
        color: #3C3C3B;
        font-size: 16px;
        font-weight: 500;
        line-height: 24px;

        &:focus {
          border-color: #3C3C3B;
        }

        &::placeholder {
          color: #C6C6C6;
          font-size: 14px;
          font-weight: 400;
          line-height: 20px;
        }
      }

      textarea {
        min-height: 80px;
      }
    }


    .filter__placeholder {
      color: #C4C4C4;
      font-size: 14px;
      font-weight: 400;
      line-height: 20px;
    }

    .filter__control {
      height: 30px !important;
      padding: 0 !important;
      background: transparent !important;
      border-left: none !important;
      border-right: none !important;
      border-top: none !important;
      border-color: rgba(246, 246, 247, 0.2) !important;

      .filter__single-value {
        color: #C4C4C4 !important;
        font-size: 14px !important;
        font-weight: 400 !important;
        line-height: 20px !important;
      }

      .filter__indicator-separator {
        display: none;
      }

      .filter__value-container {
        padding: 0;
      }

      .filter__dropdown-indicator, .filter__input-container, .filter__placeholder {
        margin: 0;
      }
    }
  }

  @media (max-width: 767px) {
    padding-top: 0;
    padding-bottom: 0;
    .form_wrapper {
      padding: 60px 0px;
      margin-top: 0px;
    }
  }
  .phoneEmail{
    display: flex;
    gap: 25px;
    .form-group{
      flex: 0 0 48%;
    }
    @media (max-width: 767px) {
        flex-direction: column;
        gap: 0px;
      .form-group{
        flex: 0 0 100%;
          &:last-child{
              margin-bottom: 30px;
          }
      }
    }
  }
`;

export default React.memo(ListWithForm);
