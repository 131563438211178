import React, {useEffect} from 'react';
import styled from "styled-components";
import {gsap, TimelineLite} from "gsap";
import {hover, text} from "../styles/globalStyleVars";
import {useLocation} from "react-router-dom";

const MyComponent = () => {
    let tl = new TimelineLite();
    let t2 = new TimelineLite();
    const location = useLocation();
    useEffect(() => {
        tl.fromTo('.page-change', .6, {
            display: 'flex',
            opacity: 1,
        }, {
            duration: 1,
            delay: 1,
            opacity: 0,
            display: 'none'
        })
        // t2.fromTo('.page-change svg path', {
        //     drawSVG: "0%",
        // }, {
        //     duration: 3,
        //     drawSVG: "100%",
        //     ease: 'Cubic.easeOut',
        //     stagger: .02
        // })
    }, [location.pathname])

    return (
        <StyledComponent className={'page-change'}>
            <img height={60} width={180} src="/images/static/mitali-logo.svg" alt=""/>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    height: 100vh;
    position: fixed;
    background-color: ${text};
    width: 100%;
    left: 0;
    top: 0;
    z-index: 9999999999999999999;
    display: flex;
    align-items: center;
    justify-content: center;
`;

export default React.memo(MyComponent);
