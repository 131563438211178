import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {Img} from "../Img";
import {FacebookShareButton, LinkedinShareButton, TwitterShareButton} from "react-share";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";
import {useHistory} from "react-router-dom";

const MyComponent = ({details}) => {
const history = useHistory();
    const [shareUrl, setShareUrl] = useState('')
    useEffect(() => {
        setShareUrl(window.location.href)
    }, [])
    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col>
                        <Button text={'Back'} background={'#8A1313'} margin={'40px 0 40px 0'} onClick={() => history.push('/sustainability#CSR')} />
                    </Col>
                </Row>
                <Row className={'details'}>
                    <Col md={3}>
                        <div className={'share-area'}>
                            <p>Share:</p>
                            <ul className={'share-area__icons'}>
                                <li className={''}>
                                    <svg id="Component_142_59" data-name="Component 142 – 59"
                                         xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Component_141_1" data-name="Component 141 – 1">
                                            <circle id="Ellipse_366" data-name="Ellipse 366" cx="17" cy="17" r="17"
                                                    fill="#8a1313"/>
                                        </g>
                                        <path id="Icon_11_"
                                              d="M6.368,9.419H4.633c-.28,0-.368-.105-.368-.368V6.931c0-.28.105-.368.368-.368H6.368V5.021a3.892,3.892,0,0,1,.473-1.98A2.907,2.907,0,0,1,8.4,1.745,3.9,3.9,0,0,1,9.75,1.517h1.717c.245,0,.35.105.35.35v2c0,.245-.105.35-.35.35-.473,0-.946,0-1.419.018a.633.633,0,0,0-.718.718c-.018.526,0,1.034,0,1.577h2.033c.28,0,.385.105.385.385v2.12c0,.28-.088.368-.385.368H9.329v5.712c0,.3-.088.4-.4.4H6.736c-.263,0-.368-.105-.368-.368V9.419Z"
                                              transform="translate(8.959 8.482)" fill="#fff"/>
                                    </svg>

                                    <FacebookShareButton url={shareUrl}/>
                                </li>
                                <li className={''}>
                                    <svg id="Component_142_58" data-name="Component 142 – 58"
                                         xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Component_141_1" data-name="Component 141 – 1">
                                            <circle id="Ellipse_366" data-name="Ellipse 366" cx="17" cy="17" r="17"
                                                    fill="#8a1313"/>
                                        </g>
                                        <path id="Path_14579" data-name="Path 14579"
                                              d="M454.119,21.753a8.117,8.117,0,0,0,8.172-8.172q0-.187-.008-.371a5.846,5.846,0,0,0,1.433-1.487,5.731,5.731,0,0,1-1.649.452,2.882,2.882,0,0,0,1.263-1.589,5.758,5.758,0,0,1-1.824.7,2.875,2.875,0,0,0-4.895,2.619,8.155,8.155,0,0,1-5.92-3,2.874,2.874,0,0,0,.889,3.835,2.852,2.852,0,0,1-1.3-.359c0,.012,0,.024,0,.037a2.874,2.874,0,0,0,2.3,2.816,2.869,2.869,0,0,1-1.3.049,2.876,2.876,0,0,0,2.683,1.995A5.763,5.763,0,0,1,450.4,20.5a5.833,5.833,0,0,1-.685-.04,8.131,8.131,0,0,0,4.4,1.29"
                                              transform="translate(-439.716 0.625)" fill="#fff"/>
                                    </svg>

                                    <TwitterShareButton url={shareUrl}/>
                                </li>
                                <li className={''}>
                                    <svg id="Component_142_56" data-name="Component 142 – 56"
                                         xmlns="http://www.w3.org/2000/svg" width="34" height="34" viewBox="0 0 34 34">
                                        <g id="Component_141_1" data-name="Component 141 – 1">
                                            <circle id="Ellipse_366" data-name="Ellipse 366" cx="17" cy="17" r="17"
                                                    fill="#8a1313"/>
                                        </g>
                                        <path id="LinkedIn"
                                              d="M1101.435,3924.414v5.117h-3v-4.774c0-1.2-.434-2.018-1.521-2.018a1.64,1.64,0,0,0-1.539,1.085,2.008,2.008,0,0,0-.1.724v4.983h-3s.04-8.086,0-8.924h3v1.266l-.019.028h.019v-.028a2.985,2.985,0,0,1,2.705-1.475C1099.954,3920.4,1101.435,3921.674,1101.435,3924.414Zm-12.3-8.108a1.548,1.548,0,1,0-.04,3.084h.02a1.549,1.549,0,1,0,.02-3.084Zm-1.52,13.226h3v-8.924h-3Z"
                                              transform="translate(-1077.435 -3906.306)" fill="#fff"/>
                                    </svg>
                                    <LinkedinShareButton url={shareUrl}/>
                                    {/*<InstapaperIcon url={shareUrl}/>*/}
                                </li>
                                {/*<li className={'hover'}>*/}
                                {/*    <svg xmlns="http://www.w3.org/2000/svg" width="9.063" height="8.561"*/}
                                {/*         viewBox="0 0 9.063 8.561">*/}
                                {/*        <path id="LinkedIn"*/}
                                {/*              d="M1096.5,3921.554v3.313h-1.942v-3.091c0-.776-.281-1.306-.984-1.306a1.061,1.061,0,0,0-1,.7,1.3,1.3,0,0,0-.065.469v3.226h-1.943s.026-5.234,0-5.777h1.943v.82l-.013.018h.013v-.018a1.932,1.932,0,0,1,1.751-.955C1095.539,3918.955,1096.5,3919.781,1096.5,3921.554Zm-7.964-5.249a1,1,0,1,0-.026,2h.013a1,1,0,1,0,.013-2Zm-.984,8.561h1.943v-5.777h-1.943Z"*/}
                                {/*              transform="translate(-1087.435 -3916.306)" fill="#fff"/>*/}
                                {/*    </svg>*/}
                                {/*    <InstagramShareButton url={shareUrl}/>*/}
                                {/*    /!*<InstapaperIcon url={shareUrl}/>*!/*/}
                                {/*</li>*/}

                            </ul>
                        </div>
                    </Col>
                    <Col md={9}>
                        <div className="news-title">
                            <h2>{details?.[0]?.page_data?.subtitle}</h2>

                        </div>
                        <div className="news-img">
                            <Img src={details?.[0]?.images?.list?.[1]?.full_path}/>
                        </div>
                        <div className="news-description">
                            {reactHtmlParser(details?.[0]?.page_data?.description)}
                        </div>

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    .news-title {
        margin-bottom: 50px;

        h2 {
            font-size: 40px;
            line-height: 52px;
            font-weight: 300;
            margin-bottom: 20px;
        }

        p {
            line-height: 28px;
            font-weight: 300;

            span {
                height: 6px;
                width: 6px;
                background: #000000;
                border-radius: 50%;
                margin-right: 8px;
                margin-left: 8px;
                display: inline-flex;
            }
        }

        @media (max-width: 767px) {
            h2 {
                font-size: 28px;
                line-height: 36px;
                margin-bottom: 10px;
            }
        }
    }

    .news-img {
        position: relative;
        padding-top: calc(450 / 970 * 100%);
        margin-bottom: 60px;

        img {
            object-fit: fill;
        }

        @media (max-width: 767px) {
            //padding-top: calc(325 / 344 * 100%);
            object-fit: contain;
        }
    }

    .news-description {
        margin-bottom: 100px;
        padding-bottom: 20px;
        border-bottom: 1px solid #E9E9E9;

        p {
            margin-bottom: 40px;

            &:last-child {
                margin-bottom: 0;
            }
        }

        ul {
            margin-bottom: 40px;

            li {
                margin-bottom: 25px;
                padding-left: 20px;
                position: relative;

                &:last-child {
                    margin-bottom: 0;
                }

                &:before {
                    content: '';
                    position: absolute;
                    width: 7px;
                    height: 7px;
                    top: 35%;
                    left: 0;
                    border-radius: 50%;
                    background: black;
                }
            }
        }

        @media (max-width: 767px) {
            margin-bottom: 60px;
        }
    }

    .share-area {
        p {
            margin-bottom: 10px;
        }

        &__icons {
            display: flex;
            gap: 10px;

            li {
                position: relative;

                button {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
    
    .details{
        @media(max-width: 767px){
            display: flex;
            flex-direction: column-reverse;
            gap: 40px
        }
    }

`;

export default MyComponent;
