export const PageAnimationParent = {
    init: {
        height: "100%",
        transition: {
            duration: 1.2,
            ease: 'easeInOut'
        },
    },
    anim: {
        height: "0%",
        transition: {
            duration: 1.2,
            ease: 'easeInOut',
            // delayChildren: 0.3,
            staggerChildren: .06
        },
    },
    exit: {
        height: "100%",
        transition: {
            duration: .8,
            // delayChildren: 0.3,
            staggerChildren: .06
        },
    }
};