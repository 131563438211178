import React, {useEffect, useState} from 'react';
import {useSelector, useDispatch} from "react-redux";
import {fetchPosts, fetchPostDetail} from "../../api/redux/post";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import Button from "../../components/Button";
import {Img} from "../../components/Img";
import Title from "../../components/Title";
import InnerBanner from "../../components/InnerBanner";
import {Loading} from "../../components/Loading";
import Typography from "../../components/Typography";
import NavButton from "../../components/NavButton";
import {Container} from "react-bootstrap";
import BannerSlider from "../../components/home/BannerSlider";
import HomeBanner from "../../components/home/HomeBanner";
import HomeAbout from "../../components/home/HomeAbout";
import CompanyAtaGlance from "../../components/home/CompanyAtaGlance";
import Awards from "../../components/home/Awards";
import Cta from "../../components/home/Cta";
import Partners from "../../components/home/Partners";
import BlogSlider from "../../components/home/BlogSlider";
import PartnerNew from "../../components/home/PartnerNew";
import {fetchHome} from "../../api/redux/home";
import {Helmet, HelmetProvider} from "react-helmet-async";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import CompanyAtaGlanceV2 from "../../components/home/CompanyAtaGlanceV2";
import CompanyAtaGlanceV3 from "../../components/home/CompanyAtaGlanceV3";




const Home = () => {

    const dispath = useDispatch()
    const location=useLocation();



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;
        let api_params = {
            [ApiParam.type]: 'slug',
            [ApiParam.value]: 'home',
            [ApiParam.get_section]: 'yes',
            [ApiParam.image]: 'yes',
            [ApiParam.post]: 'yes',
            [ApiParam.file]: 'no',
            [ApiParam.gallery]: 'no',
        }
        dispath(fetchHome([api_url , api_params]))

    }, [dispath,location?.pathname])

    const getData = useSelector(state => state.home);

    let page_data = getData?.posts?.data?.page_data;
    const bannerData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-banner-slider');
    const homeAbout = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-about');
    const homeCAAG = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'company-at-a-glance');
    const homeAwards = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'awards--certifications');
    const homeCTA = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-cta');
    const partner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'partners');
    const mediaData = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'home-media');
    const homeMedia = getData?.posts?.blog_list;
    const homeMediaWithoutCategory12 = homeMedia?.filter(post => post.data.category_id !== 12);


    return (
        <HelmetProvider>
            <Helmet>
                <title>{page_data?.title_tag}</title>
                <meta property="og:title" content={page_data?.og_title}/>
                <meta property="og:subtitle" content={page_data?.og_title} />
                <meta property="og:description" content={page_data?.og_description}/>
                <meta property="og:url" content={window.location.href} />
                <meta property="og:type" content="website"/>
                <meta name="description" content={page_data?.og_description} />
            </Helmet>

        <StyledSection>

            <HomeBanner data={bannerData?.posts?.list}/>
            <HomeAbout desc={homeAbout?.section_data?.description}
                       leftText={homeAbout?.section_data?.short_desc}
                       title={homeAbout?.section_data?.subtitle}
                       img={homeAbout?.images?.list?.[0]?.full_path}
                       url={homeAbout?.images?.list?.[0]?.short_desc}

            />

            <CompanyAtaGlanceV2 data={homeCAAG}/>
            <Awards data={homeAwards}/>
            <Cta data={homeCTA}/>
            <Partners data={partner}/>
            <BlogSlider data={homeMediaWithoutCategory12} titledata={mediaData}/>
        </StyledSection>
        </HelmetProvider>
    );
};

const StyledSection = styled.section`

`;

export default Home;
