import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {
    BrowserRouter as Router,
    Switch,
    Route,
    useParams,
    useHistory, Link, useLocation
} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import postReducer, {fetchPostDetail, fetchPosts} from "../../api/redux/post";
import {useDispatch, useSelector} from "react-redux";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {Loading} from "../../components/Loading";
import ProductList from "../../components/products/ProductList";
import InnerBanner from "../../components/InnerBanner";
import {fetchProducts} from "../../api/redux/product";
import {ApiParam} from "../../api/network/apiParams";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {

    const dispatch = useDispatch()

    const location = useLocation();
    const history = useHistory();

    const categoryParam = new URLSearchParams(location?.search).get('category_id');
    const [activeCategory, setActiveCategory] = useState(categoryParam || 13);
    const [activeSearch, setActiveSearch] = useState('');
    const [isLoading, setIsLoading] = useState(true);

    // api call
    // useEffect(() => {
    //     const api_url = apiEndPoints.PRODUCT_FILTER
    //     const param = {
    //         [ApiParam.category]: activeCategory,
    //         [ApiParam.keyword]: activeSearch,
    //         [ApiParam.file]: 'yes',
    //         [ApiParam.image]: 'yes',
    //         [ApiParam.post]: 'yes',
    //         [ApiParam.specification]: 'yes',
    //         [ApiParam.gallery]: 'yes',
    //     }
    //     const searchParams = new URLSearchParams(location.search);
    //     searchParams.set("category_id", activeCategory);
    //     searchParams.set('keyword', activeSearch);
    //     history.push({ search: searchParams.toString() });
    //     dispatch(fetchProducts([api_url,param]))
    //
    // }, [activeCategory,activeSearch,dispatch, location.search, history])
    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);

                const api_url = apiEndPoints.PRODUCT_FILTER;
                const param = {
                    [ApiParam.category]: activeCategory,
                    [ApiParam.keyword]: activeSearch,
                    [ApiParam.file]: 'yes',
                    [ApiParam.image]: 'yes',
                    [ApiParam.post]: 'yes',
                    [ApiParam.specification]: 'yes',
                    [ApiParam.gallery]: 'yes',
                };

                const searchParams = new URLSearchParams(location.search);
                searchParams.set("category_id", activeCategory);
                searchParams.set('keyword', activeSearch);
                history.push({ search: searchParams.toString() });

                // Dispatch the action to fetch products
                await dispatch(fetchProducts([api_url, param]));

                // The API call has completed, set isLoading to false
                setIsLoading(false);
            } catch (error) {
                // Handle errors if needed
                console.error("Error fetching products:", error);
                setIsLoading(false);
            }
        };

        fetchData();
    }, [activeCategory, activeSearch, dispatch, location.search, history]);


    let getPost = useSelector(state => state.products)
    let page_data = getPost?.posts?.page_data?.page_data
    const productBanner = getPost?.posts?.page_data?.sections?.find(f => f?.section_data?.slug === 'product-banner');
    const productList = getPost?.posts?.product_list;
    const catList = getPost?.posts?.product_category;

    console.log(getPost)
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | Products</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>

            <StyledComponent>

                {isLoading && <div className={'loader'}><span className={'spinner'}></span></div>}
                <InnerBanner img={productBanner?.images?.list?.[0]?.full_path} title={productBanner?.section_data?.subtitle}/>
                <ProductList data={productList}
                catList={catList} activeCategory={activeCategory} setActiveCategory={setActiveCategory}
                             activeSearch={activeSearch} setActiveSearch={setActiveSearch}
                />
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`
  position: relative;
  .loader{
    position: fixed; /* Use fixed instead of absolute for viewport centering */
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.5); /* Adjust the alpha channel for transparency */
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 999;
    .spinner {
      border: 4px solid #8A1313;
      border-radius: 50%;
      border-top: 4px solid #ffffff;
      width: 60px;
      height: 60px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
  }
`;

export default MyComponent;
