import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import HomeAbout from "../../components/home/HomeAbout";
import TimelineV2 from "../../components/about/Timeline-v2";
import Chairman from "../../components/about/Chairman";
import ManagingDirector from "../../components/about/ManagingDirector";
import Zigzag from "../../components/business-units/Zigzag";
import NewsList from "../../components/news/NewsList";
import CareerList from "../../components/career/CareerList";
import JoinTeam from "../../components/career/JoinTeam";
import OurSector from "../../components/whatwedo/OurSector";
import Goals from "../../components/sustainability/Goals";
import WaterConservation from "../../components/sustainability/WaterConservation";
import CsrList from "../../components/sustainability/CsrList";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchAbout} from "../../api/redux/about";
import {fetchCsr} from "../../api/redux/sustainability";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {ScrollToPlugin} from "gsap/ScrollToPlugin";

const MyComponent = () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // (or in the top-level file in your React app)

    const dispath = useDispatch()
    const location=useLocation();



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;
        let api_params = {
            [ApiParam.type]: 'slug',
            [ApiParam.value]: 'sustainability',
            [ApiParam.get_section]: 'yes',
            [ApiParam.image]: 'yes',
            [ApiParam.post]: 'yes',
            [ApiParam.file]: 'no',
            [ApiParam.gallery]: 'no',
        }
        dispath(fetchCsr([api_url , api_params]))

    }, [location?.pathname])

    const getData = useSelector((state) => state.csr);

    let page_data = getData?.posts?.data?.page_data
    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'sustainability-banner');
    const goals = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'sustainability-goals');
    const water = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'water-conservation');
    const community = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'community-engagement');
    const csrSection = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'csr');
    const csrList = getData?.posts?.csr_list;
    console.log(csrList);

    useEffect(() => {
        if (location.hash && !getData?.loading) {
            const targetElement = document.querySelector(location.hash);
            if (targetElement) {
                gsap.to(window, {
                    duration: 0.8,
                    scrollTo: { y: targetElement.offsetTop },
                });
            }
        } else if (!location.hash) {
            gsap.to(window, { duration: 0, scrollTo: { y: 0 } });
        }
    }, [location, getData]);
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | Sustainability</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>


            <StyledComponent>

                <InnerBanner img={banner?.images?.list?.[0]?.full_path} title={getData?.posts?.data?.page_data?.title}/>
                <Goals data={goals}/>
                <WaterConservation data={water}/>
                <CsrList data={csrList} sectionData={csrSection} csrID={'CSR'}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
