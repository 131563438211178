import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import HomeAbout from "../../components/home/HomeAbout";
import TimelineV2 from "../../components/about/Timeline-v2";
import Chairman from "../../components/about/Chairman";
import ManagingDirector from "../../components/about/ManagingDirector";
import Zigzag from "../../components/business-units/Zigzag";
import NewsList from "../../components/news/NewsList";
import CareerList from "../../components/career/CareerList";
import JoinTeam from "../../components/career/JoinTeam";
import OurSector from "../../components/whatwedo/OurSector";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchWhatWeDo} from "../../api/redux/whatwedo";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // (or in the top-level file in your React app)

    const dispath = useDispatch()
    const location=useLocation();



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;
        let api_params = {
            [ApiParam.type]: 'slug',
            [ApiParam.value]: 'what-we-do',
            [ApiParam.get_section]: 'yes',
            [ApiParam.image]: 'yes',
            [ApiParam.post]: 'yes',
            [ApiParam.file]: 'no',
            [ApiParam.gallery]: 'no',
        }
        dispath(fetchWhatWeDo([api_url , api_params]))

    }, [location?.pathname])

    const getData = useSelector((state) => state.whatWeDo);

    let page_data = getData?.posts?.data?.page_data;
    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'what-we-do-banner');
    const textile = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'textile');
    const garments = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'garments');
    const printing = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'printing');
    const embroidery = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'embroidery');
    const trims = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'trims');
    const rAndD = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'rd');
    console.log(textile);
    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    useEffect(() => {
        if (location.hash && !getData?.loading) {
            const targetElement = document.querySelector(location.hash);
            const topPx = targetElement?.offsetTop;
            console.log(topPx);
            if (targetElement) {
                gsap.to(window, {
                    duration: 0.8,
                    scrollTo: topPx
                });
            }
        }
    }, [location, getData]);
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | What We Do</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>


            <StyledComponent>
                {/*<InnerBanner img={banner?.images?.list?.[0]?.full_path} title={getData?.posts?.data?.page_data?.title}/>*/}
                {/*{*/}
                {/*    textile &&*/}
                {/*    <OurSector title={textile?.section_data?.subtitle} data={textile} sectionID={'textile'}/>*/}
                {/*}*/}
                {/*{*/}
                {/*    garments &&*/}
                {/*    <OurSector title={garments?.section_data?.subtitle} bgColor={'bg-color'} data={garments} sectionID={'garments'}/>*/}

                {/*}*/}
                {/*{*/}
                {/*    printing &&*/}
                {/*    <OurSector title={printing?.section_data?.subtitle} data={printing} sectionID={'printing'}/>*/}

                {/*}*/}
                {/*{*/}
                {/*    embroidery &&*/}
                {/*    <OurSector title={embroidery?.section_data?.subtitle} bgColor={'bg-color'} data={embroidery} sectionID={'embroidery'}/>*/}
                {/*}*/}
                {/*{*/}
                {/*    trims &&*/}
                {/*    <OurSector title={trims?.section_data?.subtitle} data={trims} sectionID={'trims'}/>*/}
                {/*}*/}
                {/*{*/}
                {/*    rAndD?.section_data?.subtitle &&*/}
                {/*    <OurSector title={rAndD?.section_data?.subtitle} bgColor={'bg-color'} data={rAndD} sectionID={'research-development'}/>*/}
                {/*}*/}

                <InnerBanner img={banner?.images?.list?.[0]?.full_path} title={getData?.posts?.data?.page_data?.title} />
                {getData?.posts?.data?.sections?.slice(1).map((section, index) => {
                    const isEvenIndex = index % 2 === 0;
                    return (
                        <OurSector
                            key={section.section_data.slug}
                            title={section.section_data.subtitle}
                            bgColor={!isEvenIndex ? 'bg-color' : ''}
                            data={section}
                            sectionID={section.section_data.slug}
                        />
                    );
                })}

            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
