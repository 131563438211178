import React, {useEffect, useRef} from 'react';
import styled from 'styled-components';
import {hover, text, title, title_ms60} from "../styles/globalStyleVars";
import ReactHtmlParser from "react-html-parser";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import gsap from 'gsap';
const Title = ({
                   text,
                   fontSize,
                   fontWeight,
                   color,
                   letterSpacing,
                   lineHeight,
                   textTransform,
                   margin,
                   padding,
                   borderColor,
                   varient,
                   center,
                   classname,
                   small_text,
                   lineColor
               }) => {

    const titleRef = useRef(null);
    const spanRef = useRef(null);

    useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        gsap.fromTo(
            spanRef.current,
            {
                width: 0,
            },
            {
                width: 100,
                ease: 'power2.out',
                scrollTrigger: {
                    trigger: titleRef.current,
                    start: 'top 80%', // Adjust the start position as needed
                    end: '+=200', // Adjust the end position as needed
                    scrub: true,
                },
            }
        );
    }, []);
    return (

        <StyledTitle className={`title fade-up ${classname}`}
                     fontSize={fontSize}
                     fontWeight={fontWeight}
                     color={color}
                     lineHeight={lineHeight}
                     LetterSpacing={letterSpacing}
                     textTransform={textTransform}
                     margin={margin}
                     padding={padding}
                     varient={varient}
                     center={center}
                     borderColor={borderColor}
                     lineColor={lineColor}
                     ref={titleRef}
        >

            <span ref={spanRef}></span>
            <h2 className={'split-up'}>{ReactHtmlParser(text)} </h2>


        </StyledTitle>

    )
};


const StyledTitle = styled.div`
  margin: ${props => props.margin || '0px'};
  position: relative;
  width: 100%;
  font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
  text-align: ${props => props?.center ? 'center' : ''};
  padding: ${p => p.padding};
  span{
    width: 100px;
    height: 5px;
    display: block;
    margin-bottom: 30px;
    border-radius: 3px;
    background: ${props => props.lineColor || hover};
  }
  h2 {
    font-size: ${props => props.fontSize || 40}px;
    line-height: ${props => props.lineHeight || 52}px;
    font-weight: ${props => props.fontWeight || '400'};
    color: ${props => props.color || hover};
      font-kerning: none;
    
  }


  @media (max-width: 767px) {
    margin-bottom: 40px;
    padding: 0;
    h2 {
      font-size: 32px !important;
      line-height: 36px !important;
    }
  }
`;


export default Title;














