import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {CSSPlugin, gsap, TimelineLite} from "gsap";

import reactHtmlParser from "react-html-parser";
import {Img} from "./Img";


gsap.registerPlugin(CSSPlugin);
let tl = new TimelineLite();
const MyComponent = ({img, name, designation}) => {

    // offset
    const [offset, setOffset] = useState(90)
    useEffect(() => {
        setOffset(document.querySelector('.container').offsetLeft)
    }, [])


    // handle popup
    // useEffect(() => {
    // let getAllItem = document.querySelector('.leaders__slider__single');
    // getAllItem.addEventListener('click', function () {
    //
    //     tl.to('.popup-overlay', {
    //         display: 'block',
    //         duration: 0
    //     }).to('.popup-overlay', {
    //         opacity: '1',
    //         duration: .5
    //     }, '-=.2').to('.dc-popup', {
    //         display: 'block',
    //         duration: 0
    //     }, '-=.4').to('.dc-popup', {
    //         x: 0,
    //         duration: .6
    //     }, '-=.4')
    // });

    // close
    //     let getAllCloseItem = document.querySelectorAll('.dc-popup__close');
    //     getAllCloseItem.forEach(e => {
    //         e.addEventListener('click', function () {
    //             tl.to('.popup-overlay', {
    //                 opacity: '0',
    //                 duration: .5
    //             }, '-=.2').to('.popup-overlay', {
    //                 display: 'none',
    //             }).to('.dc-popup', {
    //                 x: '100%',
    //                 duration: .6
    //             }, '-=.6').to('.dc-popup', {
    //                 display: 'none',
    //             }, '-=.4')
    //         });
    //     })
    //
    //     let getAllCloseOverlay = document.querySelectorAll('.popup-overlay');
    //     getAllCloseOverlay.forEach(e => {
    //         e.addEventListener('click', function () {
    //             tl.to('.popup-overlay', {
    //                 opacity: '0',
    //                 duration: .5
    //             }, '-=.2').to('.popup-overlay', {
    //                 display: 'none',
    //             }).to('.dc-popup', {
    //                 x: '100%',
    //                 duration: .6
    //             }, '-=.6').to('.dc-popup', {
    //                 display: 'none',
    //             }, '-=.4')
    //         });
    //     })
    // }, [])


    return (
        <>
            <StyledComponent>
                <div className="leaders__slider__single">
                    <div className="leaders__slider__single__img">
                        <Img src={img}/>
                    </div>
                    <div className="leaders__slider__single__content">
                        {name && <h4 className={'split-up'}>{name}</h4>}
                        {designation && <p className={'split-up'}>{designation}</p>}
                    </div>
                </div>
            </StyledComponent>

        </>
    );
};
const StyledComponent = styled.section`
  .leaders__slider {
    &__single {
      cursor: pointer;

      &__img{
        position: relative;
        padding-top: calc(250 / 270 * 100%);
        border: 0.5px solid #CBB1B1;
        img{
          width: 120px;
          height: 100px;
          object-fit: contain;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        @media(max-width: 767px){
        }
      }


      &__content {
        margin-top: 20px;

        h4 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #C1C1C1;
        }
      }
    }
  }

`

export default MyComponent;