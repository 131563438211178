import React, { useEffect, useState } from "react";
import styled from "styled-components";
import {Link} from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import {hover} from "../styles/globalStyleVars";
import moment from "moment";
import {Parallax, ParallaxProvider} from "react-scroll-parallax";
import ModalVideo from "react-modal-video";
import "react-modal-video/css/modal-video.min.css";
import {Img} from "./Img";
import {useDispatch, useSelector} from "react-redux";
import {fetchPosts} from "../api/redux/globalapi";
import {apiEndPoints} from "../api/network/apiEndPoints";


const MyComponent = () => {
    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState("");
    let [windowWidth, setWindowWidth] = useState("");

    const dispatch = useDispatch();

    const store = useSelector(store => store.globalapi)

    const footerData = store?.posts?.data;

    useEffect(() => {
        let api_url = apiEndPoints.GLOBAL

        dispatch(fetchPosts([api_url]))
    }, [])

    let handelOpen = (open, id) => {
        setOpen(open);
        setVideo(id);
    };

    useEffect(() => {
        setWindowWidth(window.innerWidth);
    }, []);

    return (
        <>
            <StyledComponent className="footer pb-60">
                <Img src={'/images/static/footer.jpg'}/>
                <Container>
                    <Row>
                        <Col sm={3} className="footer__logo">
                            <img src={'/images/static/mitali-logo.svg'} alt="" />
                            <div className="contact-details">
                                <p>
                                    <a target='_blank' href="https://maps.app.goo.gl/me5dqT7VNNs6phHT9">{footerData?.office_location}</a>
                                </p>
                                <p><a target='_blank' href={`tel:${footerData?.office_phone}`}>Phone : {footerData?.office_phone}</a></p>
                                <p><a target='_blank' href={`tel:${footerData?.office_fax}`}>Fax : {footerData?.office_fax}</a></p>
                                <p><a target='_blank' href={`mailto:${footerData?.info_email}`}>E-mail : {footerData?.info_email}</a></p>
                            </div>

                            <ul>
                                <li className="hover-here hover">
                                    <a target="_blank" href={footerData?.facebook}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.552" height="14" viewBox="0 0 7.552 14">
                                            <path id="Icon_11_" d="M6.368,9.419H4.633c-.28,0-.368-.105-.368-.368V6.931c0-.28.105-.368.368-.368H6.368V5.021a3.892,3.892,0,0,1,.473-1.98A2.907,2.907,0,0,1,8.4,1.745,3.9,3.9,0,0,1,9.75,1.517h1.717c.245,0,.35.105.35.35v2c0,.245-.105.35-.35.35-.473,0-.946,0-1.419.018a.633.633,0,0,0-.718.718c-.018.526,0,1.034,0,1.577h2.033c.28,0,.385.105.385.385v2.12c0,.28-.088.368-.385.368H9.329v5.712c0,.3-.088.4-.4.4H6.736c-.263,0-.368-.105-.368-.368V9.419Z" transform="translate(-4.265 -1.517)" fill="#fff"/>
                                        </svg>
                                    </a>
                                </li>

                                <li className="hover-here hover">
                                    <a target="_blank" href={footerData?.twitter}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18"
                                             viewBox="0 0 24 24">
                                            <path
                                                d="M20.83 21H18.84L14.1 13.76 8.54 21H4.88L11.61 12 5.16 3H8.11L12.98 8.92 17.73 3H21.29L14.56 12 20.83 21Z"
                                                fill="#fff"/>
                                        </svg>

                                    </a>
                                </li>

                                {
                                    footerData?.instagram &&
                                    <li className="hover-here hover">
                                        <a target="_blank" href={footerData?.instagram}>
                                            <svg id="Group_23616" data-name="Group 23616"
                                                 xmlns="http://www.w3.org/2000/svg"
                                                 width="14" height="14" viewBox="0 0 14 14">
                                                <path id="Path_5912" data-name="Path 5912"
                                                      d="M7,1.261c1.869,0,2.09.007,2.829.041a3.872,3.872,0,0,1,1.3.241,2.169,2.169,0,0,1,.8.524,2.169,2.169,0,0,1,.524.8,3.876,3.876,0,0,1,.241,1.3c.034.738.041.96.041,2.829s-.007,2.09-.041,2.829a3.876,3.876,0,0,1-.241,1.3,2.318,2.318,0,0,1-1.329,1.329,3.872,3.872,0,0,1-1.3.241c-.738.034-.959.041-2.829.041s-2.091-.007-2.829-.041a3.872,3.872,0,0,1-1.3-.241,2.169,2.169,0,0,1-.8-.524,2.169,2.169,0,0,1-.524-.8,3.872,3.872,0,0,1-.241-1.3C1.268,9.09,1.261,8.869,1.261,7s.007-2.09.041-2.829a3.872,3.872,0,0,1,.241-1.3,2.169,2.169,0,0,1,.524-.8,2.169,2.169,0,0,1,.8-.524,3.872,3.872,0,0,1,1.3-.241c.738-.034.96-.041,2.829-.041M7,0C5.1,0,4.86.008,4.114.042a5.139,5.139,0,0,0-1.7.325,3.431,3.431,0,0,0-1.24.807,3.431,3.431,0,0,0-.807,1.24,5.139,5.139,0,0,0-.325,1.7C.008,4.86,0,5.1,0,7S.008,9.14.042,9.886a5.139,5.139,0,0,0,.325,1.7,3.431,3.431,0,0,0,.807,1.24,3.433,3.433,0,0,0,1.24.807,5.139,5.139,0,0,0,1.7.325C4.86,13.992,5.1,14,7,14s2.14-.008,2.886-.042a5.139,5.139,0,0,0,1.7-.325,3.579,3.579,0,0,0,2.047-2.047,5.139,5.139,0,0,0,.325-1.7C13.992,9.14,14,8.9,14,7s-.008-2.14-.042-2.886a5.139,5.139,0,0,0-.325-1.7,3.433,3.433,0,0,0-.807-1.24,3.431,3.431,0,0,0-1.24-.807,5.139,5.139,0,0,0-1.7-.325C9.14.008,8.9,0,7,0"
                                                      fill="#fff"/>
                                                <path id="Path_5913" data-name="Path 5913"
                                                      d="M24.271,20.676a3.595,3.595,0,1,0,3.595,3.595,3.595,3.595,0,0,0-3.595-3.595m0,5.928A2.333,2.333,0,1,1,26.6,24.271,2.333,2.333,0,0,1,24.271,26.6"
                                                      transform="translate(-17.271 -17.271)" fill="#fff"/>
                                                <path id="Path_5914" data-name="Path 5914"
                                                      d="M61.767,15.553a.84.84,0,1,1-.84-.84.84.84,0,0,1,.84.84"
                                                      transform="translate(-50.19 -12.289)" fill="#fff"/>
                                            </svg>

                                        </a>
                                    </li>
                                }


                                <li className="hover-here hover">
                                    <a target="_blank" href={footerData?.linkedin}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="13.225"
                                             viewBox="0 0 14 13.225">
                                            <path id="LinkedIn"
                                                  d="M1101.435,3924.414v5.117h-3v-4.774c0-1.2-.434-2.018-1.521-2.018a1.64,1.64,0,0,0-1.539,1.085,2.008,2.008,0,0,0-.1.724v4.983h-3s.04-8.086,0-8.924h3v1.266l-.019.028h.019v-.028a2.985,2.985,0,0,1,2.705-1.475C1099.954,3920.4,1101.435,3921.674,1101.435,3924.414Zm-12.3-8.108a1.548,1.548,0,1,0-.04,3.084h.02a1.548,1.548,0,1,0,.02-3.084Zm-1.52,13.225h3v-8.924h-3Z"
                                                  transform="translate(-1087.435 -3916.306)" fill="#fff"/>
                                        </svg>

                                    </a>
                                </li>
                            </ul>
                        </Col>
                        <Col className="p-0" sm={{span: 2, offset: 1}}>
                            <p className={'footer-menu-title'}>Quick navigation</p>
                            <ul>
                                <li>
                                    <Link to={"/"}>
                                        Home
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/about"}>
                                        About us
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/business-units"}>
                                        Business Units
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/product"}>
                                        Products
                                    </Link>
                                </li>
                            </ul>
                        </Col>

                        <Col className="p-0 m-dn" sm={2}>
                            <p className={'footer-menu-title'}>What We Do</p>
                            <ul>
                                <li>
                                    <Link to={"/what-we-do#textile"}>
                                        Textile
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/what-we-do#garments"}>
                                        Garments
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/what-we-do#printing"}>
                                        Printing
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col className="p-0" sm={2}>
                            <p className={'footer-menu-title'}>What We Do</p>
                            <ul>
                                <li>
                                    <Link to={'/what-we-do#embroidery'}>
                                        Embroidery
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/what-we-do#trims"}>
                                        Trims
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/what-we-do#rd"}>
                                        R&D
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                        <Col className="p-0" sm={2}>
                            <p className={'footer-menu-title'}>Quick Navigation</p>
                            <ul>
                                <li>
                                    <Link to={"/sustainability"}>
                                        Sustainability
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/media-center"}>
                                        Media
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/career"}>
                                        Career
                                    </Link>
                                </li>
                                <li>
                                    <Link to={"/contact"}>
                                        Contact Us
                                    </Link>
                                </li>
                            </ul>
                        </Col>
                    </Row>

                    <Row className="copyright">
                        <Col className="d-flex justify-content-between">
                            <p>© {moment(Date()).format("YYYY")}  {footerData?.copyright_text}</p>
                            <a href={"https://dcastalia.com/"} target="_blank">
                                Design & Developed by <span>Dcastalia</span> </a>

                        </Col>
                    </Row>
                </Container>
            </StyledComponent>
        </>
    );
};


const StyledComponent = styled.section`
   background-color: #222222;
   position: relative;
   overflow: hidden;
   z-index: 99;
  padding-top: 100px;
  padding-bottom: 50px;
  margin-top: -3px;
    @media(max-width: 767px){
        
    }
  
  .col-sm-2{
    margin-top: 60px;
  }

   .parallax-logo {
      position: absolute;
      height: 100%;
      top: 0;
      left: 0;
   }

   .footer__logo {
     .contact-details{
       margin-top: 60px;
     }
      p {
        &:first-child{
          margin-bottom: 15px;
        }
        a{
          color: rgb(255, 255, 255);
          &:hover{
            color: ${hover} !important;
          }
        }
        
      }

      ul {
         margin-top: 60px;
         display: flex;
        
        li {
          margin-bottom: 0 !important;

          &:not(:nth-last-of-type(1)) {
            margin-right: 15px;
          }

          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          border: 0.5px solid #FFFFFF;
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

          svg {
            line {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }

          }

          &.hover:after {
            background-color: ${hover};
            border: 1px solid ${hover};
          }

          &:first-child {
            margin-right: 20px;
          }

          position: relative;
          border-radius: 50%;
          height: 34px;
          width: 34px;

          &:hover{
            border: 1px solid ${hover};
            line{
              stroke: #FFFFFF;
            }
          }
        }
      }
       @media(max-width: 767px){
           img{
               height: 40px;
           }
       }
   }

   ul {
      li {
         &:not(:nth-last-of-type(1)) {
            margin-bottom: 18px;
         }

         a {
            color: #ffffff;
            font-weight: 400;
           font-size: 14px;
           line-height: 24px;
         }
      }
   }

   .copyright {
      padding-top: 20px;
      margin-top: 60px;
      position: relative;

      &:before {
         position: absolute;
         content: "";
         top: 0;
         left: 15px;
         right: 15px;
         height: 1px;
        background-color: rgba(255,255,255,0.2);
      }

      p,
      a {
         color: #ffffff;
        font-size: 12px;
        line-height: 20px;
          span{
              color: #009975;
              font-weight: 700;
              font-size: 12px;
          }
      }
     
   }

   @media (min-width: 768px) {
      .social {
         display: none;
      }
   }

   @media (max-width: 767px) {
      .footer__logo {
         min-width: 100%;
         margin-bottom: 80px;
      }

      .col-sm-2 {
         margin: 0 0 60px 0 !important;
         min-width: 33.333%;
         padding: 0 15px !important;
      }

      .social {
         display: flex;

         li {
            margin-right: 20px !important;
         }
      }

      .copyright {
         margin-top: 0;

         .justify-content-between {
            flex-wrap: wrap;
         }
      }
   }

   @media (max-width: 580px) {
      .col-sm-2 {
         min-width: 50%;
         max-width: 50%;
      }
     

      .parallax-logo {
         width: 100%;

         img {
            position: absolute;
            bottom: 0;
            width: 99%;
         }
      }
   }
  
  .footer-menu-title{
    font-size: 12px;
    line-height: 20px;
    color: rgba(255,255,255,0.6);
    margin-bottom: 40px;
  }
  a{
    color: #FFFFFF;
    font-size: 14px;
    line-height: 24px;
  }
  a:hover{
    color: rgba(255, 255, 255,0.8) !important;
  }
    
    .hover-here{
        display: flex;
        a{
            width: 100%;
            height: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
`;

export default MyComponent;
