import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover} from "../../styles/globalStyleVars";
import Media from "../Media";
import WhitePaper from "./WhitePaper";
import Button from "../Button";
import TitleTwo from "../TitleTwo";

const MyComponent = ({data,filterList,handleCategoryClick,setActiveCategory,activeCategory}) => {
    const [displayedItems, setDisplayedItems] = useState(6);

    const handleLoadMoreClick = () => {
        setDisplayedItems((prevDisplayedItems) => prevDisplayedItems + 6);
    };
    return (
        <StyledComponent className={'pt-120 pb-120 blog-list'}>
            <Container>
                
                <Row className={'category-list'}>
                    <Col>
                        <ul>
                            {
                                filterList && filterList?.length >0 &&
                                filterList?.map((element,index)=>{
                                    return(
                                        <li className={element?.id === activeCategory ? 'active' : ''} key={index} onClick={() => handleCategoryClick(element?.id)}>
                                            <a >{element?.title}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                </Row>
                <Row>
                    {activeCategory === 12 ? (
                        <Col md={12}>
                            <WhitePaper paperData={data} />
                        </Col>
                    ) : (
                        data && data?.length > 0 &&
                        data.slice(0, displayedItems)?.map((element, index) => (
                            <Col md={4} key={index}>
                                    <Media
                                        title={element?.data?.title}
                                        img={element?.images?.list?.[0]?.full_path}
                                        link={element?.data?.link ? element?.data?.link : `/media-center/${element?.data?.slug}`}
                                        date={element?.data?.date}
                                    />
                            </Col>
                        ))
                    )}
                </Row>
                {data && data.length > displayedItems && (
                    <Row>
                        <Col className={'load-button'}>
                            <Button
                                text={'Load More'}
                                border={'1px solid #000000'}
                                hoverBorderColor={'#8A1313'}
                                color={'#000000'}
                                onClick={handleLoadMoreClick}
                            />
                        </Col>
                    </Row>
                )}
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .category-list{
    margin-bottom: 60px;
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        border: 1px solid #000000;
        padding: 10px 32px;
        border-radius: 29px;
        margin-right: 20px;
        transition: all .3s ease;
        width: max-content;
        position: relative;
        overflow: hidden;
        &:last-child{
          margin-right: 0;
        }
        a {
          transition: color .3s ease;
          position: relative;
          z-index: 2;
          width: max-content;
          color: #000000;
        }
        &:before{
          top: 100%;
          content: "";
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          background-color: ${p => p.hoverBackground || hover};
          height: 100%;
          width: 100%;
          margin: auto;
          transition: all .5s ease;
          border-radius: 24px;
        }
        &:hover{
          a{
            color: #FFFFFF !important;
          }
          border: 1px solid #8A1313;
          &:before{
            top:0;
          }
        }
        &.active{
          background-color: ${hover};
          border: 1px solid ${hover};
          a{
            color: #FFFFFF;
          }
        }
        @media(max-width: 767px){
          margin-bottom: 20px;
        }
      }
    }
      @media(max-width: 767px){
          ul{
              li{
                  width: 100%;
                  margin-right: 0;
                  a{
                      display: block;
                      width: 100%;
                      text-align: center;
                  }
              }
          }
      }
  }
  .load-button{
    .dc-btn{
      margin: auto;
    }
  }
    .media-single__img{
        padding-top: 100%;
    }
`;

export default MyComponent;
