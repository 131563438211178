import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {hover} from "../../styles/globalStyleVars";

const MyComponent = ({paperData}) => {
    return (
        <StyledComponent className={''}>
                <Row>
                    {
                        paperData && paperData?.length > 0 &&
                        paperData?.map((element, index) => (
                    <Col md={4} >
                        <div className={'whitePaper-single'}>
                            <div className="name">
                                <p className={'date'}>{element?.data?.date}</p>
                                <p className={'file-name'}>{element?.data?.title}</p>
                            </div>
                            <div className="logo">
                                <a href={element?.images?.list?.[0]?.full_path} target="_blank">
                                    <svg id="Component_236_4" data-name="Component 236 – 4" xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50">
                                        <circle id="Ellipse_392" data-name="Ellipse 392" cx="25" cy="25" r="25" fill="#2a2627"/>
                                        <circle id="Ellipse_393" data-name="Ellipse 393" cx="25" cy="25" r="0" fill="#8A1313"/>
                                        <g id="Group_13685" data-name="Group 13685" transform="translate(-388 -2224.5)">
                                            <line id="Line_10" data-name="Line 10" x2="10" transform="translate(408 2257.5)" fill="none" stroke="#cecbcb" stroke-linecap="round" stroke-width="1"/>
                                            <g id="Group_13682" data-name="Group 13682" transform="translate(1)">
                                                <line id="Line_3" data-name="Line 3" x2="10" transform="translate(412 2252) rotate(-90)" fill="none" stroke="#cecbcb" stroke-linecap="round" stroke-width="1"/>
                                                <line id="Line_3580" data-name="Line 3580" x2="5" y2="5" transform="translate(407 2247.5)" fill="none" stroke="#cecbcb" stroke-linecap="round" stroke-width="1"/>
                                                <line id="Line_3581" data-name="Line 3581" x1="5" y2="5" transform="translate(412 2247.5)" fill="none" stroke="#cecbcb" stroke-linecap="round" stroke-width="1"/>
                                            </g>
                                        </g>
                                    </svg>
                                </a>
                            </div>
                        </div>
                    </Col>
                    )
                    )}

                </Row>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .whitePaper-single{
    display: flex;
    justify-content: space-between;
    padding: 30px;
    background: #F2F2F2;
    margin-bottom: 40px;
    .name{
      .date{
        font-size: 14px;
        line-height: 24px;
        color: rgba(42,38,39,0.5);
      }
    }
    .logo{
      svg{
        overflow: hidden;
        #Ellipse_393{
          transition: 0.5s ease-in-out;
        }
      }
    }
    
    &:hover{
      .logo{
        svg{
          overflow: hidden;
          #Ellipse_393{
            r:25;
          }
        }
      }
    }
  }
  
`;

export default MyComponent;
