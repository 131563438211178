import {createSlice} from "@reduxjs/toolkit";

const initialState = {
    globalLoader: true,
}

const globalSlice = createSlice({
    name: 'global',
    initialState,
    reducers: {
        loading: (state) => {
            state.globalLoader = true
        },
        loaded: (state) => {
            state.globalLoader = false
        }
    },
    extraReducers: {
        ['fetchHome/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchAbout/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCsr/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCsrDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchBusiness/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProduct/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProjectsList/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchProductDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMedia/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaList/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchMediaDetail/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchWhatWeDo/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchBuyer/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchContact/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareer/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchCareerList/fulfilled']: (state, action) => {
            state.globalLoader = false
        },
        ['fetchData/fulfilled']: (state, action) => {
            state.globalLoader = false
        }
    }
})


export default globalSlice.reducer
export const {loaded, loading} = globalSlice.actions
