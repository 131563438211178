import React, {useEffect, memo} from 'react';
import styled from "styled-components";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import InnerBanner from "../../components/InnerBanner";
import {Col, Container, Row} from "react-bootstrap";
import Accordion from "../../components/contact/Accordion";
import ListWithForm from "../../components/contact/ListWithForm";
import {fetchContact} from "../../api/redux/contact";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {


    // api call start
    const dispath = useDispatch()

    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS
        let param = {
            [ApiParam.type]: 'slug',
            [ApiParam.value]: 'contact',
            [ApiParam.get_section]: 'yes',
            [ApiParam.image]: 'yes',
            [ApiParam.post]: 'yes',
            [ApiParam.file]: 'no',
            [ApiParam.gallery]: 'no',
        }

        dispath(fetchContact([api_url, param]))
    }, [dispath])

    let getPost = useSelector(state => state.contact)

    let page_data = getPost?.posts?.data?.page_data
    const contactBanner = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-banner');
    const contactAddress = getPost?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'contact-details');
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | Contact</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>

            <StyledComponent>
                {/*inner banner */}
                <InnerBanner img={contactBanner?.images?.list?.[0]?.full_path} title={contactBanner?.section_data?.subtitle}/>

                {/*map and form section */}
                <section className={'map-accordion-form pt-150 pb-150'}>

                    <Container>
                        <Row>
                            <Col md={6}>
                                <Accordion data={contactAddress} />
                            </Col>
                            <Col>
                                <ListWithForm/>
                            </Col>
                        </Row>
                    </Container>
                </section>


                {/*feedback*/}
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`
  .map-accordion-form {
    position: relative;

    .float-text {
      position: absolute;
      right: 0;
      top: 200px;

      p {
        font-size: 200px;
        font-weight: 500;
        line-height: 145px;
        color: #F2F2F2;
        text-transform: uppercase;
      }

      &.float-text-second {
        top: 45%;
        right: unset;
        left: 0;
      }
    }
  }

  @media (max-width: 767px) {
    .float-text {
      display: none;
    }
  }
`;

export default MyComponent;
