import React, {useEffect, useState} from "react";
import styled from "styled-components";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slick from "react-slick";
import {Img} from "../Img";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";
import {hover} from "../../styles/globalStyleVars";


const BannerSlider = ({data, offset}) => {
    const [index, setIndex] = useState(0);
    const [nextDisable, setNextDisable] = useState(false);

    useEffect(() => {
        setTimeout(() => {
            document.querySelector('.banner-slider').classList.add('show-texts')
        }, 1500)

    }, [data])

    function RightArrow(props) {
        const {onClick} = props;
        return (
            <Container className="arrow-container">
                <li
                    className={`go-right hover`}
                    onClick={onClick}
                >
                    <svg xmlns="http://www.w3.org/2000/svg" width="8.121" height="14.121" viewBox="0 0 8.121 14.121">
                        <g id="Group_23869" data-name="Group 23869" transform="translate(787.061 442.061)">
                            <line id="Line_352" data-name="Line 352" x2="6" y2="6" transform="translate(-786 -429) rotate(-90)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1.5"/>
                            <line id="Line_353" data-name="Line 353" x1="6" y2="6" transform="translate(-786 -435) rotate(-90)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1.5"/>
                        </g>
                    </svg>

                </li>
            </Container>
        );
    }

    function LeftArrow(props) {
        const {onClick} = props;
        return (
            <Container className="arrow-container">
                <li
                    className={`go-left hover`}
                    onClick={onClick}
                >
                        <svg xmlns="http://www.w3.org/2000/svg" width="8.121" height="14.121" viewBox="0 0 8.121 14.121">
                            <g id="Group_23869" data-name="Group 23869" transform="translate(869.061 442.061)">
                                <line id="Line_352" data-name="Line 352" x2="6" y2="6" transform="translate(-862 -441) rotate(90)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1.5"/>
                                <line id="Line_353" data-name="Line 353" x1="6" y2="6" transform="translate(-862 -435) rotate(90)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1.5"/>
                            </g>
                        </svg>
                </li>
            </Container>
        );
    }

    // before change slider handler for disable next prev
    const beforeChange = (e) => {
        setIndex(e);
        if (e === document.querySelectorAll(".banner-slider__single").length - 1) {
            setNextDisable(true);
        } else {
            setNextDisable(false);
        }
    };

    const settings = {
        dots: false,
        loop: true,
        infinite: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: true,
        autoplay: false,
        speed: 1500,
        autoplaySpeed: 5500,
        pauseOnHover: false,
        nextArrow: <RightArrow/>,
        prevArrow: <LeftArrow/>,
        afterChange: beforeChange,
        responsive: [
            {
                breakpoint: 768, // Breakpoint width in pixels
                settings: {
                    infinite: true,
                   dots: true
                },
            },

        ],
    };

    // api data

    const getData = data;
    const bannerSlider = data?.posts?.list;

    return (
        <StyledBanner className="banner-slider">

            <Slick {...settings}>

                    <Slick {...settings}>
                            <div key={0} className="banner-slider__single">
                                    <Img alt={'banner'} layout={"fill"} src={'/images/dynamic/banner1.jpg'}/>

                                <Container>
                                    <div className="banner-slider__single__text">
                                        <Row>
                                            <Col md={12}>
                                                <h2>Crafting Dreams,</h2>
                                                <h1>Tailoring Fashion</h1>
                                            </Col>
                                        </Row>

                                    </div>

                                </Container>

                            </div>
                        <div key={1} className="banner-slider__single">
                            <Img alt={'banner'} layout={"fill"} src={'/images/dynamic/banner1.jpg'}/>

                            <Container>
                                <div className="banner-slider__single__text">
                                    <Row>
                                        <Col md={12}>
                                            <h2>Crafting Dreams,</h2>
                                            <h1>Tailoring Fashion</h1>
                                        </Col>
                                    </Row>

                                </div>

                            </Container>

                        </div>
                        <div key={2} className="banner-slider__single">
                            <Img alt={'banner'} layout={"fill"} src={'/images/dynamic/banner.jpg'}/>

                            <Container>
                                <div className="banner-slider__single__text">
                                    <Row>
                                        <Col md={12}>
                                            <h2>Crafting Dreams,</h2>
                                            <h1>Tailoring Fashion</h1>
                                        </Col>
                                    </Row>

                                </div>

                            </Container>

                        </div>
                    </Slick>

            </Slick>

        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  height: 100vh;
  position: relative;

  .message_wrapper {
    position: unset;
  }
  

  li {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #FFFFFF;
    transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

    svg {
      line {
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
      }

    }

    &.hover:after {
      background-color: ${hover};
      border: 1px solid ${hover};
    }

    &:first-child {
      margin-right: 20px;
    }

    position: relative;
    border-radius: 50%;
    height: 40px;
    width: 40px;

    &:hover{
      border: 1px solid ${hover};
      line{
        stroke: #FFFFFF;
      }
    }
  }

  .banner-slider__single {
    position: relative;
    height: 100vh;
    opacity: 0;
    transition: opacity .5s ease;

    &:before {
      content: "";
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: rgba(0, 0, 0, 0.25);
      z-index: 1;
      left: 0;
      top: 0;
    }

    video {
      position: absolute;
      height: 100%;
      width: 100%;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      object-fit: cover;
    }

    .container {
      position: relative;
      z-index: 9;
      height: 100%;

      .banner-slider__single__text {
        position: absolute;
        bottom: 100px;
        margin: 0;
        //max-width: 80%;
        z-index: 9;
        
        h2{
          color: #FFFFFF;
          font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
        }

        h1 {
          color: #FFFFFF;
          text-transform: uppercase;
          font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
        }
        
      }


    }

  }

  .arrow-container {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
    bottom: 200px;
    height: 40px;

    svg {
      color: #fff;
      //transition: color 0.3s ease;
      transition: color .5s cubic-bezier(0.5, 1, 0.89, 1);;
      z-index: 2;
    }

    .hover {
      cursor: pointer;

      &:after {
        border-radius: 50%;
      }

      &:hover {
        svg {
          color: #fff;
        }
      }
    }

    .go-right {
      position: absolute;
      right: 15px;
      z-index: 1;
      cursor: pointer;


      &.disable {
        pointer-events: none;

      }
      
    }

    .go-left {
      position: absolute;
      right: 80px;
      z-index: 1;
      cursor: pointer;

      &.disable {
        pointer-events: none;
      }
    }
  }

  .socila-icon__single svg {
    border: unset;
    border-radius: unset;
  }

  .social-icon {
    position: absolute;
    bottom: 60px;
    right: 0;
    display: none;

    ul {
      display: flex;

      li {
        margin-right: 30px;
        z-index: 99;

        &:last-child {
          margin-right: 0px;
        }


        a {
          margin-bottom: 20px;

          svg {
            path {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            }
          }

          &:hover {
            svg {
              g {
                path {
                  opacity: .6;
                }
              }
            }
          }
        }
      }
    }
  }

  .slick-dots{
    display: flex !important;
    justify-content: center;
    align-items: center;
    bottom: 40px;
    li{
      height: 10px;
      width: 10px;
      &.slick-active{
        height: 14px;
        width: 14px;
        background-color: #FFFFFF;
      }
      &:first-child{
        margin-right: 10px;
      }
      &:hover{
        border:none;
      }
    }
  }
  .slick-dots li button:before{
    display: none;
  }

  @media (max-width: 991px) {
    .banner-slider__single {
      .container {
        h1 {
          bottom: 150px;
          max-width: calc(100% - 30px);
        }
      }
    }

    /* Tablet desktop :768px. */
    @media (min-width: 768px) and (max-width: 991px) {
      .banner-slider__single {
        .container {
          h1 {
            max-width: unset;
          }
        }
      }

      .arrow-container {
        bottom: 60px;
      }
    }

    .social-icon {
      padding-right: 15px;
    }

    .arrow-container {
      .go-left {
        left: 15px;
        right: auto;
        height: 30px;
        width: 30px;
      }

      .go-right {
        left: 60px;
        right: auto;
        height: 30px;
        width: 30px;
      }
    }
  }

  /* small mobile :320px. */
  @media (max-width: 767px) {
    .social-icon {
      bottom: 40px;
      display: block;

      ul {
        padding-right: unset !important;
      }
    }

    .arrow-container {
      bottom: 150px;
      display: none;
    }

    .banner-slider__single {
      .container {
        h1 {
          max-width: unset;
        }
      }
    }
    
    .banner-slider__single .container .banner-slider__single__text {
      position: absolute;
      bottom: 150px;
      margin: 0px;
      z-index: 9;
      //top: 0;
      display: flex;
      align-items: center;
    }

    .banner-slider__single .container .banner-slider__single__text h1 {
    }

  }

  &.show-texts {
    .banner-slider__single {
      opacity: 1;
    }
  }
`;
export default React.memo(BannerSlider);
