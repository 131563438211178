import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import {useDispatch, useSelector} from "react-redux";
import {useLocation, useParams} from "react-router-dom";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchCsr, fetchCsrDetail} from "../../api/redux/sustainability";
import CsrDetails from "../../components/sustainability/CsrDetails";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";
import {Helmet, HelmetProvider} from "react-helmet-async";
import InnerBanner from "../../components/InnerBanner";
import Goals from "../../components/sustainability/Goals";
import WaterConservation from "../../components/sustainability/WaterConservation";
import CsrList from "../../components/sustainability/CsrList";
import RelatedCsr from "../../components/sustainability/RelatedCsr";

const MyComponent = () => {
    const dispath = useDispatch()
    const location=useLocation();
    let {slug} = useParams();



    // api call
    useEffect(() => {

        let api_services = apiEndPoints.CHILD_PAGE;
        let param = {
            [ApiParam.page_id]: '48',
            [ApiParam.value]: 'sustainability',
            [ApiParam.image]: 'yes',
            [ApiParam.post]: 'yes',

        }
        dispath(fetchCsrDetail([api_services, param]))

    }, [dispath])


    let getPost = useSelector(state => state.csrDetails)

    let page_data = getPost?.posts?.data?.page_data;
    const DetailsPage = getPost?.detail?.data?.list;
    let csrDetails=Array.isArray(DetailsPage)? DetailsPage.filter(f => f?.page_data?.slug === slug) : '';
    console.log('csr',DetailsPage);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>{`${page_data?.title ? page_data?.title : ''}`}</title>
                {
                    page_data?.meta_title &&
                    <meta name="title" content={page_data?.meta_title}/>

                }
                {
                    page_data?.meta_description &&
                    <meta name="description" content={page_data?.meta_description}/>

                }
                {
                    page_data?.og_title &&
                    <meta property="og:title" content={page_data?.og_title}/>

                }
                {
                    page_data?.og_description &&
                    <meta property="og:description" content={page_data?.og_description}/>

                }
                {
                    page_data?.og_type &&
                    <meta property="og:type" content={page_data?.og_type}/>

                }
                {
                    page_data?.og_url &&
                    <meta property="og:url" content={page_data?.og_url}/>

                }
                {
                    csrDetails?.images?.list?.[1]?.full_path &&
                    <meta property="og:image" content={csrDetails?.images?.list?.[1]?.full_path}/>

                }
            </Helmet>

            <StyledComponent>
                <CsrDetails details={csrDetails}/>
                <RelatedCsr data={DetailsPage}/>
            </StyledComponent>
        </HelmetProvider>

    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
