import React, {useEffect, useRef, useState} from "react";
import styled from "styled-components";
import {Swiper, SwiperSlide} from "swiper/react";
import {hover, title} from '../../styles/globalStyleVars';
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import {Autoplay, Navigation, Pagination} from "swiper";
import {Col, Container, Row} from "react-bootstrap";
import {Link} from "react-router-dom";
import ReactHtmlParser from "react-html-parser";
import Button from "../Button";
import {gsap, TweenLite} from "gsap";
import {useLocation} from "react-router-dom";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import {SplitText} from "gsap/SplitText";
import {Img} from '../Img';
import LeftSvg from "../LeftSvg";
import RightSvg from "../RightSvg";
import {ImageParallax} from "../ImageParallax";
import reactHtmlParser from "react-html-parser";



// for button

// Function to handle button animation

// const applyButtonAnimation = () => {
//     gsap.from(".banner-button", {
//         duration: 1.3,
//         translateY: 250,
//         opacity: 1,
//         delay:.5,
//         rotationZ: "15",
//         ease: "power4.out",
//         scrollTrigger: {
//             trigger: ".banner-button",
//             toggleActions: "restart none none reset",
//         },
//     });
// };

const Banner = ({data, feature_data}) => {


    console.log(data);
    const desktop_images = data?.posts?.list?.[0]?.find(f => f?.images?.banner === 'on') ;

    const swiperRef = useRef(null);
    const swiperRefTwo = useRef(null);
    const AutoplayDelay = 2000;
    const [offset, setOffset] = useState(90)
    const location = useLocation();
    const buttonRef = useRef(null);


    // navigation
    const prev = () => {
        document.querySelector('.home-banner .swiper-button-prev').click();
    };

    const next = () => {
        document.querySelector('.home-banner .swiper-button-next').click();
    };

    useEffect(() => {


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container')?.offsetLeft)

        })
        setOffset(document.querySelector(' .container')?.offsetLeft)
    }, [data])


    // handle slider progress
    const handleProgress = (swiper, event) => {
        var interleaveOffset = 0.5;
        for (let i = 0; i < swiper.slides.length; i++) {
            var slideProgress = swiper.slides[i].progress;
            var innerOffset = swiper.width * interleaveOffset;
            var innerTranslate = slideProgress * innerOffset;
            swiper.slides[i].querySelector(".global-image").style.transform =
                "translate3d(" + innerTranslate + "px, 0, 0)";
        }

    };

    // hand touch move not required this slider
    const handleTouchStart = (swiper) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = "";
        }
    };


    // handle image transition on change
    const handleSetTransition = (swiper, speed) => {
        for (let i = 0; i < swiper.slides.length; i++) {
            swiper.slides[i].style.transition = `${speed}ms`;
            swiper.slides[i]
                .querySelector(".global-image")
                .style.transition = `${speed}ms`;
        }
    };


    // slider-speed-control for mobile and laptop
    const [swiperSpeed, setSwiperSpeed] = useState('2500');

    // Check if the device is a mobile
    useEffect(() => {
        const isMobileDevice = /Mobi/i.test(navigator.userAgent);

        // If it's a mobile device, set the speed to '1500'
        if (isMobileDevice) {
            setSwiperSpeed('1000');
        }
    }, []);

    // autoplay off in mobile

    // useEffect(() => {
    //     const swiper = swiperRef.current?.swiper;
    //
    //     // Check if the user is on a mobile device
    //     const shouldDisableAutoplay = isMobile();
    //
    //     // Disable autoplay if on a mobile device
    //     if (shouldDisableAutoplay) {
    //         swiper.autoplay.stop();
    //     } else {
    //         swiper.autoplay.start();
    //     }
    // }, []);


    // handle pagination
    var pagination_title = ['.']
    const pagination = {
        clickable: true,
        renderBullet: function (index, className) {
            return '<div class="' + className + '"><span>' + pagination_title[index] + "</span></div>";
        },
    };
    const [activeSlideIndex, setActiveSlideIndex] = useState(0);


    const handleSlideChange = (event) => {

        const newActiveSlideIndex = event.realIndex;
        setActiveSlideIndex(newActiveSlideIndex);
        if (swiperRefTwo.current) {
            swiperRefTwo.current.swiper.slideTo(newActiveSlideIndex);
        }


    };

    const handleButtonClickPagination = (index) => {
        if (swiperRef.current) {
            setActiveSlideIndex(index);
            swiperRef.current.swiper.slideToLoop(index);
        }
    };




    return (
        <StyledBanner offset={offset} className={'home-banner'}>
            {
                data && data?.length>0 &&

                <Swiper
                    ref={swiperRef}
                    spaceBetween={0}
                    loop={true}
                    autoplay= {{delay: AutoplayDelay, disableOnInteraction: false}}
                    /*{isMobile() ? false : { delay: AutoplayDelay, disableOnInteraction: false }}*/
                    speed={swiperSpeed}
                    // swiperSpeed
                    onSlideChange={handleSlideChange}
                    onProgress={handleProgress}
                    touchStart={handleTouchStart}
                    onSetTransition={handleSetTransition}
                    grabCursor={true}
                    watchSlidesProgress={true}
                    mousewheelControl={false}
                    keyboardControl={true}
                    navigation={{
                        prevEl: '#banner-next',
                        nextEl: '#banner-prev',
                    }}
                    pagination={pagination}
                    modules={[Autoplay, Pagination, Navigation]}
                    sName="mySwiper main-swiper"
                >
                    {
                        data && data?.length>0 &&
                        data?.map((element,index)=>{
                            return(
                                <SwiperSlide key={index}>
                                    <div className="slide-inner">
                                        {
                                            window.innerWidth > 767 ?

                                                <div className="desktop">
                                                    <Img alt={'banner'} layout={"fill"} src={element?.images?.[0]?.full_path}/>
                                                </div>

                                                :

                                                <div className="mobile">
                                                    <Img alt={'banner'} layout={"fill"} src={element?.images?.[1]?.full_path}/>
                                                </div>

                                        }


                                        <Container>
                                            <Row>
                                                <Col className={''}>
                                                    {
                                                        element?.data?.subtitle &&
                                                        <div className="slide-inner__info">
                                                            {
                                                                index === 0 ?
                                                                    <h1>{element?.data?.subtitle}</h1> :
                                                                    <h2>{element?.data?.subtitle}</h2>
                                                            }

                                                            <h2>{element?.data?.short_desc}</h2>
                                                        </div>
                                                    }


                                                </Col>
                                            </Row>
                                            <Row>
                                                <Col>
                                                    <div className="slide-inner__details">
                                                        <p>
                                                            {
                                                                reactHtmlParser(element?.data?.description)
                                                            }
                                                        </p>
                                                    </div>
                                                </Col>
                                            </Row>
                                        </Container>
                                    </div>
                                    </SwiperSlide>
                            )
                        })
                    }

                </Swiper>
            }


            <div className="navigation">
                <Container>
                    <Row>
                        <Col>
                            <ul>
                                <li id={'banner-next'} className={'hover'}>
                                    <LeftSvg/>
                                </li>
                                <li id={'banner-prev'} className={'hover'}>
                                    <RightSvg/>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </Container>
            </div>
        </StyledBanner>
    );
};

const StyledBanner = styled.section`
  height: 100vh;
  overflow: hidden;
  position: relative;

  .swiper-button-prev, .swiper-button-next, .swiper-pagination, .mobile {
    display: none;
  }

  .swiper-slide {
    overflow: hidden;
  }

  .swiper-pagination {
    display: none;
  }

  .swiper-button-disabled {
    opacity: 0.5 !important;
  }
  

  .global-image {
    transition: clipPath .8s cubic-bezier(.29, .73, .45, 1), border-color 3.6s linear, -webkit-clipPath .8s cubic-bezier(.29, .73, .45, 1);
    will-change: clipPath;
    overflow: hidden;
    -webkit-clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  }

  .swiper-slide-active, .swiper-slide-visible {
    .global-image {
      clipPath: polygon(0 0, 100% 0, 100% 100%, 0 100%);
    }

    .slide-inner {
      .row{
        &:first-child{
          
        }
      }
      &__info{
        h2 {
          transform: translateY(0px);
          transition: 3s all cubic-bezier(0.4, 0, 0, 1);
          //width: 50%;
          @media(max-width: 767px){
            //width: 65% !important;
          }
        }

        h1 {
          transform: translateY(0px);
          transition: 3s all cubic-bezier(0.4, 0, 0, 1);
        }
      }
      &__details{
        margin-top: 20px;
        p{
          color: #FFFFFF;
          font-weight: 300;
        }
      }
      img {
        height: 100vh !important;
      }
    }
  }
  

  .slide-inner {
    position: relative;
    height: 100vh;

    .container {
      position: absolute;
      left: 0;
      right: 0;
      //top: calc(50% - 15vh);
      bottom: 120px;
      //transform: translateY(-50%);
      z-index: 2;
      overflow: hidden;
    }

    .global-image {
      &:after {
        content: '';
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        top: 0;
        z-index: 1;
        background: linear-gradient(to right, rgba(0, 0, 0, 0.4), rgba(0, 0, 0, 0));
      }
    }

    &__info {
      position: relative;
      overflow: hidden;
      border-bottom: 1px solid #FFFFFF;
      padding-bottom: 20px;
      h2{
        color: #FFFFFF;
        transform: translateY(400px);
        margin: 0;
        //transition-delay: 5s;
        transition: 9s all cubic-bezier(0.4, 0, 0, 1);
        text-transform: capitalize;
        font-size: 48px;
        line-height: 62px;
      }
      h1{
        color: #FFFFFF;
        transform: translateY(400px);
        //transition-delay: 5s;
        transition: 9s all cubic-bezier(0.4, 0, 0, 1);
        text-transform: capitalize;
        font-size: 56px;
        line-height: 82px;
      }

      .banner-button {
        opacity: 0;
        overflow: hidden;
        .dc-btn {
          position: relative;
          overflow: hidden;
        }
      }

      @media (min-width: 1024px) {
        //width: 70%;
      }

      h1 {
        .line {
          overflow: hidden;
        }
      }
      
    }

    &__details{
      margin-top: 20px;
      p{
        color: #FFF;
        width: 50%;
      }
    }
  }

  @media (max-width: 991px) {
    .slide-inner__info {
      margin-right: 50px;

      h2 {
        //width: 60% !important;
      }
    }
    .slide-inner__details{
      p{
        width: 100%;
      }
    }
  }



  //navigation
  .navigation {
    position: absolute;
    right: ${props => props.offset ? props.offset + 5 + 'px' : '140px'};
    //top: calc(50% - 10vh);
    bottom: 105px;
    transform: translateY(-50%);
    display: inline-block;
    z-index: 1;

    ul {
      display: flex;
      //flex-direction: column-reverse;
      gap: 20px;
      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: transparent;
        border: 1px solid #F9F5F2;
        transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

        &:last-child{
          //margin-top: 20px;
        }

        &.hover:after {
          background-color: ${hover};
          border: 1px solid ${hover};
        }

        position: relative;
        border-radius: 50%;
        height: 50px;
        width: 50px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }
        &:hover{
          border: 1px solid ${hover};
          svg{
            g{
              line{
                stroke:  #FCFEFF;
              }
            }
          }
        }
      }
    }
    @media(max-width: 767px){
      top: unset;
      left: 0;
      bottom: 75px;
      ul{
        flex-direction: unset;
        li{
          &:last-child{
            margin-top: 0;
          }
        }
      }
    }
  }


  //responsive
  @media (min-width: 1550px) {
    //.slide-inner {
    //  &__info {
    //    position: relative;
    //    overflow: hidden;
    //
    //    h2 {
    //      font-size: 80px !important;
    //      line-height: 80px;
    //    }
    //
    //    //.dc-btn {
    //    //  a {
    //    //    font-size: 22px;
    //    //    line-height: 33px;
    //    //  }
    //    //}
    //  }
    //}

    .feature-box-container {
      bottom: -150px;
    }

    .feature-box-container {
      .single-item-wrapper {
        min-height: 300px;

        .content {
          min-height: 250px;
        }
      }
    }
  }
  @media (max-width: 992px) {
    .swipper-navigation-slider-custom {
      bottom: 250px;

    }
  }

  @media (max-width: 992px) and (min-width: 768px) {
    height: 100vh;
    .custom-navigation {
      bottom: 0;
    }

    .swipper-navigation-slider-custom {
      bottom: 60px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: 45%;
      }
    }

    .feature-box-container {
      display: none;
      position: relative;
      left: 0px;
      right: 0px;
      bottom: 200px;
      z-index: 999;
      width: calc(100% - 30px);
      margin: 0px auto;


      .single-item-wrapper a .content {
        align-items: flex-start;
      }

      .feature-box-container_item:last-child {
        order: 1;
        flex: 0 0 100%;
        max-width: 100%;
      }

      .feature-box-container_item:first-child {
        order: 2;
        flex: 0 0 100%;
        max-width: 100%;

        .single-item-wrapper {
          border-right: none;
          border-bottom: 1px solid rgba(198, 198, 198, 0.5);
        }

      }

      .feature-box-container_item:nth-of-type(2) {
        order: 3;
        flex: 0 0 50%;
        max-width: 50%;
      }

      .feature-box-container_item:nth-of-type(3) {
        order: 4;
        flex: 0 0 50%;
        max-width: 50%;

        .single-item-wrapper {
          border-right: none;

        }

      }
    }


  }
  @media (max-width: 767px) {
    height: 100vh;
    .desktop {
      display: none;
    }

    .mobile {
      display: block;
    }

    .slide-inner {
      height: 100vh;

      .container {
        top: unset;
        transform: unset;
        bottom: 220px;
      }

      &__info {
        position: relative;
        overflow: hidden;


        h2 {
          font-size: 32px !important;
          line-height: 42px;
          //width: 60%;
        }
        
        h1{
          line-height: 52px;
          font-size: 40px;
        }
        

        .dc-btn {
          margin-top: 30px;
        }
      }
    }

    .swipper-navigation-slider-custom {
      padding-left: 15px !important;
      padding-right: 15px !important;
      bottom: 0px;

      .swiper-initialized {
        padding-right: 30%;
      }
    }


    .scrollTo {
      left: 15px;
      right: unset;
      display: inline-block;
      bottom: 20px;
      transform: translateX(0);
    }


    .feature-box-container {

      display: none;
      position: relative;
      bottom: 150px;

      .feature-box-container_item {
        &:first-child {
          order: 2;
        }

        &:nth-of-type(2) {
          order: 3;
        }

        &:nth-of-type(3) {
          order: 4;
        }

        &:last-child {
          order: 1;

          .content {
          }
        }
      }

      .single-item-wrapper {
        border-right: none;
        border-bottom: 1px solid rgba(198, 198, 198, 0.5);

        .content {
          align-items: flex-start !important;
        }

        &.last {
          border: none;

          .content {
            align-items: center !important;
          }
        }


      }

      .container {
        padding: 0;
      }
    }
  }







`;

export default Banner;