import React, {useEffect, useState} from "react";
import styled from "styled-components";
import {Col, Container, Modal, Row} from "react-bootstrap";
import {CSSPlugin, gsap, TimelineLite} from "gsap";

import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import Title from "../Title";
import {Swiper, SwiperSlide} from "swiper/react";
import {Navigation, Pagination} from "swiper";
import {Img} from "../Img";
import {hover, text} from "../../styles/globalStyleVars";
import SimpleBar from "simplebar-react";
import ReactHtmlParser from "react-html-parser";
import SinglePartner from "../SinglePartner";

const MyComponent = ({title, id, padding, getAfterHeight, bg, img, full, project, nolnk, remove, data, after_bg}) => {
    gsap.registerPlugin(CSSPlugin);
    const tl = new TimelineLite()
    const [getAfter, setgetAfter] = useState(90)
    const [offset, setOffset] = useState(90)

    const [height, setHeight] = useState()
    const [width, setWidth] = useState()
    // modal
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    useEffect(() => {
        setHeight(window.innerHeight)
        setWidth(window.innerWidth)
    }, [])


    useEffect(() => {

        // ScrollTrigger.refresh();
        // gsap.registerPlugin(ScrollTrigger);


        window.addEventListener('load', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        window.addEventListener('resize', function () {
            setOffset(document.querySelector(' .container').offsetLeft)

        })
        setOffset(document.querySelector(' .container').offsetLeft)
    }, [data])


    // custom scroll height set
    const [scrollHeight, setScrollHeight] = useState()

    const [popupData, setPopupData] = useState('')
    // handle popup
    const handlePopup = (data) => {
        setTimeout(() => {
            setScrollHeight(window.innerHeight - (document.querySelector('.dc-popup__header')?.clientHeight))
        }, 100)

        setPopupData(data)
        setShow(true)
    }


    // half background calculation
    const [theHeight, setTheHeight] = useState()
    useEffect(() => {
        setTimeout(() => {
            if (window.innerWidth > 767) {
                const titleHeight = document.querySelector('.management-team .title')?.clientHeight + 90
                const getItemHeight = document.querySelector('.management-team .leaders__slider__single__img')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 200))
            } else {
                const titleHeight = document.querySelector('.management-team .title')?.clientHeight
                const getItemHeight = document.querySelector('.management-team .leaders__slider__single__img')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 290))
            }
        }, 600)


        window.addEventListener('resize', () => {
            if (window.innerWidth > 767) {
                const titleHeight = document.querySelector('.management-team .title')?.clientHeight + 90
                const getItemHeight = document.querySelector('.management-team .leaders__slider__single__img')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 200))
            } else {
                const titleHeight = document.querySelector('.management-team h2')?.clientHeight
                const getItemHeight = document.querySelector('.management-team .leaders__slider__single__img')?.clientWidth / 2
                setTheHeight((titleHeight + getItemHeight + 290))
            }
        })

    }, [data])


    return (
        <>
            <StyledComponent theHeight={theHeight} getAfterHeight={getAfter} remove={remove} id={`${id ? id : ''}`}
                             offset={offset}
                             className={`pionneering management-team parallax-inner-box pt-200 pb-200 divider`}>
                <Container>
                    <Row>
                        <Col md={12}>
                            <div className="d-flex navigation_wrapper">
                                <div className="title">
                                    <Title text={'Partner'}/>
                                </div>
                                <ul className="navigation">
                                    <li className={'prev_swipper1 hover'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                             viewBox="0 0 7.828 12.828">
                                            <g id="Group_6" data-name="Group 6"
                                               transform="translate(1.414 11.414) rotate(-90)">
                                                <line id="Line_4" data-name="Line 4" y1="5" x2="5" fill="none"
                                                      stroke="#fff"
                                                      stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_5" data-name="Line 5" x1="5" y1="5"
                                                      transform="translate(5)"
                                                      fill="none" stroke="#fff" stroke-linecap="round"
                                                      stroke-width="2"/>
                                            </g>
                                        </svg>

                                    </li>
                                    <li className={'next_swipper1 hover'}>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="7.828" height="12.828"
                                             viewBox="0 0 7.828 12.828">
                                            <g id="Group_6" data-name="Group 6"
                                               transform="translate(-92.086 454.914) rotate(-90)">
                                                <line id="Line_4" data-name="Line 4" x2="5" y2="5"
                                                      transform="translate(443.5 93.5)" fill="none" stroke="#fff"
                                                      stroke-linecap="round" stroke-width="2"/>
                                                <line id="Line_5" data-name="Line 5" x1="5" y2="5"
                                                      transform="translate(448.5 93.5)" fill="none" stroke="#fff"
                                                      stroke-linecap="round" stroke-width="2"/>
                                            </g>
                                        </svg>

                                    </li>
                                </ul>
                            </div>
                        </Col>
                    </Row>
                </Container>
                <Container fluid className='about-section__bottom'>
                    <Row>
                            <Swiper
                                loop={false}
                                spaceBetween={30}
                                slidesPerView={4}
                                slideNextClass={'.next'}
                                allowSlideNext={true}
                                slidePrevClass={'.prev'}
                                allowSlidePrev={true}
                                allowTouchMove={true}
                                longSwipesMs={900}
                                speed={900}
                                breakpoints={{
                                    320: {
                                        slidesPerView: 1,
                                        spaceBetween: 15,
                                    },
                                    768: {
                                        slidesPerView: 2,
                                        spaceBetween: 15,
                                    },
                                    1024: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },

                                    1440: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },
                                    1920: {
                                        slidesPerView: 4,
                                        spaceBetween: 30,
                                    },

                                }}
                                navigation={{
                                    prevEl: '.prev_swipper1',
                                    nextEl: '.next_swipper1  ',
                                }}
                                modules={[Pagination, Navigation]}
                                // onSwiper={(swiper) => console.log(swiper)}
                            >
                                    <SwiperSlide >
                                        <SinglePartner
                                            img={'images/dynamic/partner1.svg'}
                                            name={'H&M'}
                                        />
                                    </SwiperSlide>
                                <SwiperSlide >
                                    <SinglePartner
                                        img={'images/dynamic/partner1.svg'}
                                        name={'H&M'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <SinglePartner
                                        img={'images/dynamic/partner1.svg'}
                                        name={'H&M'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <SinglePartner
                                        img={'images/dynamic/partner1.svg'}
                                        name={'H&M'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <SinglePartner
                                        img={'images/dynamic/partner1.svg'}
                                        name={'H&M'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <SinglePartner
                                        img={'images/dynamic/partner1.svg'}
                                        name={'H&M'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <SinglePartner
                                        img={'images/dynamic/partner1.svg'}
                                        name={'H&M'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <SinglePartner
                                        img={'images/dynamic/partner1.svg'}
                                        name={'H&M'}
                                    />
                                </SwiperSlide>
                                <SwiperSlide >
                                    <SinglePartner
                                        img={'images/dynamic/partner1.svg'}
                                        name={'H&M'}
                                    />
                                </SwiperSlide>


                            </Swiper>

                    </Row>
                </Container>
            </StyledComponent>

        </>

    );
};
const StyledComponent = styled.section`

  position: relative;
  background: #E9E9E9;
  overflow: hidden;

  .leaders__slider {
    &__single {
      &__img {
        padding-top: calc(370 / 370 * 100%);
        position: relative;

        .global-image {
          overflow: hidden;

          img {
            transform: scale(1.01);
            transition: 1.4s ease;
          }
        }
      }

      &:hover {
        .global-image img {
          transform: scale(1.06) !important;
        }
      }

      &__content {
        margin-top: 20px;

        h4 {
          font-size: 16px;
          line-height: 24px;
          font-weight: 500;
        }

        p {
          font-size: 16px;
          line-height: 24px;
          font-weight: 400;
          color: #C1C1C1;
        }
      }
    }
  }

  &:after {
    height: calc(100% - ${(p) => p.theHeight + 'px'});
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background: #F6F6F6;
    content: "";
    display: ${props => props.remove ? 'none' : 'block'};
    opacity: ${props => props.remove ? '0' : '1'};
    //z-index: -1;
  }

  .title_wrapper {
    display: flex;
  }

  .swiper-initialized {
    padding-right: 120px;
    @media (max-width: 767px) {
      padding-right: 0 !important;
      width: 100%;
    }
  }
  .swiper-slide{
    width: 296px !important;
  }

  .swiper-button-prev, .swiper-button-next {
    opacity: 0;
    visibility: hidden;
  }

  .navigation_wrapper {
    justify-content: space-between;
    margin-bottom: 80px;


    @media (max-width: 767px) {
      margin-bottom: 40px;
    }

    .title {
      h3 {
        font-weight: 500;
        font-size: 40px;
        line-height: 48px;
        color: #4F616B;
      }
    }

    .d-flex {
      justify-content: space-between;
    }

    .navigation {
      display: flex;
      align-items: center;

      li {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: #404040;

        &.hover:after {
          background-color: ${hover};
        }

        &:first-child {
          margin-right: 20px;
        }

        position: relative;
        border-radius: 50%;
        height: 52px;
        width: 52px;

        svg {
          position: relative;

          #Ellipse_4378 {
            transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
            r: 0;
          }

          &:hover {
            #Ellipse_4378 {
              transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
              r: 26px;
            }
          }
        }
      }
    }
  }

  .swiper-wrapper {
    height: auto !important;
  }

  .about-section__bottom {
    padding-left: ${props => props.offset ? props.offset + 30 + 'px' : '90px'};
    position: relative;

    @media (max-width: 767px) {
      padding-left: 30px !important;
      padding-right: 30px !important;
    }

    &__single {
      max-width: 100%;
    }
  }

  @media (max-width: 767px) {

    .navigation_wrapper {
      flex-wrap: wrap;
    }

    .navigation {
      min-width: 100%;
      margin-top: 40px;
      margin-bottom: 20px;
    }

    .about-section__bottom__single__inner span {
      top: 55px;
      left: 55px;
    }
  }

`
;
export default MyComponent;