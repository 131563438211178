import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";
import TitleTwo from "../TitleTwo";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({des, designation, img, name,data}) => {
    return (
        <StyledComponent className={'parallax-img horizontal-scroll'} data-speed-right={30} data-speed-left={-30}>
            <Container className="profile">

                <Row>
                    <p className={'scroll-right bg-text-one'}>Founder &</p>
                    <p className={'scroll-left bg-text-two'}>Chairman</p>
                    <Col md={'6'} className="profile__body">
                        <div className={'profile__body__inner'}>
                            <div className="profile__body__name">
                                {
                                    data?.section_data?.subtitle &&
                                    <TitleTwo text={data?.section_data?.subtitle} />
                                }
                                {
                                    reactHtmlParser(data?.section_data?.description)
                                }

                                <div className="designation">
                                    <h3 className={'split-up'}>{data?.images?.list?.[0]?.short_title}</h3>
                                    <p className={'split-up'}>{data?.images?.list?.[0]?.short_desc}</p>
                                </div>
                            </div>

                        </div>
                    </Col>
                    <Col md={{span:5, offset:1}} className={'profile-left'}>
                        {
                            data?.section_data?.subtitle &&
                            <TitleTwo text={data?.section_data?.subtitle} />
                        }
                        {
                            data?.images?.list?.[0]?.full_path &&
                            <div className="profile__image">
                                <Img src={data?.images?.list?.[0]?.full_path}/>
                            </div>
                        }

                    </Col>
                </Row>

            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #F5F5F5;
  position: relative;
  padding-bottom: 250px;
  
  .profile{

    &__body{
      &__name{
        padding-bottom: 60px;
        h2{
          font-weight: 300;
          margin-bottom: 60px;
        }
        p{
          margin-bottom: 24px;
          &:last-child{
            margin-bottom: 0;
          }
        }
        .designation{
          margin-top: 60px;
        }
      }
      @media(max-width: 767px){
        .title{
          display: none;
        }
      }
      
    }
    &__image{
      position: relative;
      padding-top: calc(610 / 470 * 100%);
        overflow: hidden;
    }
    
  }
  .profile-left{
    .title{
      display: none;
    }
  }

  @media (max-width: 767px) {
    .row{
      flex-direction: column-reverse !important;
    }
  }


  @media(max-width: 767px){
    .profile-left{
      padding-right: 15px;
      margin-bottom: 40px;
      .title{
        display: block;
      }
    }
    .profile{
      padding-bottom: unset;
      &__body{
        padding-bottom: unset;
        &__name{
          padding-bottom: 40px;
        }
      }
    }
    .designation{
        h3{
            font-size: 20px;
            line-height: 26px;
            font-weight: 400;
            margin-bottom: 10px;
        }
        p{
            font-size: 16px;
            line-height: 24px;
        }
    }
  }
  .bg-text-one, .bg-text-two {
    font-size: 140px;
    line-height: 200px;
    font-weight: 300;
    margin: 0;
    color: rgba(0,0,0,0.05);
    position: absolute;
    white-space: nowrap;
    font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  .bg-text-one {
    left: -5%;
    top: 70%;

    @media (max-width: 767px) {
      bottom: 120px;
    }
  }

  .bg-text-two {
    right: -5%;
    bottom: 0;
  }
  
  @media(max-width: 767px){
    padding-bottom: 60px;
    .bg-text-one, .bg-text-two{
      display: none;
    }
    
  }

`;

export default MyComponent;
