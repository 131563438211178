import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

import {Img} from "../Img";
import Button from "../Button";
import ReactHtmlParser from "react-html-parser";
import TitleTwo from "../TitleTwo";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({des, designation, img, name,data}) => {
    return (
        <StyledComponent className={'horizontal-scroll pt-150'} data-speed-right={30} data-speed-left={-30}>
            <Container className="profile">

                <Row>
                    <p className={'scroll-right bg-text-one'}>Sustainability</p>
                    <p className={'scroll-left bg-text-two'}>Goals</p>
                    <Col md={12} className={'upper-title'}>
                        {
                            data?.section_data?.subtitle &&
                            <TitleTwo text={data?.section_data?.subtitle} />
                        }
                        {
                            reactHtmlParser(data?.section_data?.description)
                        }

                    </Col>
                </Row>
                    <Row className="lower-part">
                        <Col md={'6'} className="profile__body">
                            <div className={'profile__body__inner'}>
                                {
                                    data?.posts?.list && data?.posts?.list?.length>0 &&
                                    data?.posts?.list?.map((element,index)=>{
                                        return(
                                <div className="profile__body__name" key={index}>
                                    <h3 className={'split-up'}>{element?.data?.title}</h3>
                                    <p className={'split-up'}>{element?.data?.description}</p>
                                </div>
                                        )
                                    })
                                }
                            </div>
                        </Col>
                        <Col md={{span:5, offset:1}} className={'profile-left'}>
                               <div className="profile__image">
                                    <Img src={data?.images?.list?.[0]?.full_path}/>
                                </div>
                        </Col>
                    </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  background-color: #FFFFFF;
  position: relative;
  padding-bottom: 250px;

  .profile{
    .upper-title{
      margin-bottom: 50px;
        p{
            &:not(:last-child){
                margin-bottom: 24px;
            }
        }
      .title{
        margin-bottom: 40px;
      }
    }

    &__body{
      &__name{
        margin-bottom: 40px;
        h3{
          font-weight: 400;
          margin-bottom: 20px;
        }
      }
      @media(max-width: 767px){
        
      }

    }
    &__image{
      position: relative;
      padding-top: calc(600 / 500 * 100%);
    }

  }
  .lower-part{
    display: flex;
  }
  

  @media (max-width: 767px) {
    .lower-part{
      display: flex;
      flex-direction: column-reverse;
      .profile-left{
      }
    }
  }


  @media(max-width: 767px){
    .profile-left{
      padding-right: 15px;
      margin-bottom: 40px;
      .title{
        display: block;
      }
    }
    .profile{
      padding-bottom: unset;
      &__body{
        padding-bottom: unset;
        margin-top: 40px;
        &__name{
        }
      }
    }
  }
  .bg-text-one, .bg-text-two {
    font-size: 140px;
    line-height: 200px;
    font-weight: 300;
    margin: 0;
    color: rgba(0,0,0,0.05);
    position: absolute;
    white-space: nowrap;
    font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;


    @media (max-width: 767px) {
      font-size: 120px;
      line-height: 120px;
    }
  }
  .bg-text-one {
    left: -5%;
    top: 80%;

    @media (max-width: 767px) {
      bottom: 120px;
    }
  }

  .bg-text-two {
    right: -5%;
    bottom: 0;
  }

  @media(max-width: 767px){
    padding-bottom: 60px;
    .bg-text-one, .bg-text-two{
      display: none;
    }

  }

`;

export default MyComponent;
