import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TitleTwo from "../TitleTwo";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars";
import {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react";
import NavButton from "../NavButton";
import RelatedProducts from "./RelatedProducts";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";
import {useHistory} from "react-router-dom";

const MyComponent = ({data,relatedProduct}) => {
    const history = useHistory();

    const handleBackButtonClick = () => {
        history.goBack();
    };
    return (
        <StyledComponent className={'pt-120 pb-120'}>
            <Container>
                <Row>
                    <Col>
                        <Button text={'Back'} background={'#8A1313'} margin={'40px 0 40px 0'} onClick={handleBackButtonClick}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <h2>{data?.product_data?.subtitle}</h2>
                    </Col>
                </Row>
                <Row>
                    <Col md={5}>
                        <div className="product-content">
                            <p>
                                {reactHtmlParser(data?.product_data?.short_desc)}
                            </p>
                            {reactHtmlParser(data?.product_data?.description)}
                        </div>
                    </Col>
                    <Col md={{span:6,offset:1}} className={'product-slider'}>
                        {
                            data?.galleries?.list &&
                            <div className="slider-nav">
                                <NavButton prev_id={'product-prev'} next_id={'product-next'}/>
                            </div>
                        }

                        {
                            data?.galleries?.list?.[0]?.items && data?.galleries?.list?.[0]?.items?.length>0 &&
                        <Swiper
                            slidesPerView={1}
                            speed='500'
                            navigation={{
                                prevEl: '#product-prev',
                                nextEl: '#product-next',
                            }}
                            initialSlide={1}
                            autoplay={true}
                            loop={true}
                            modules={[Autoplay, Pagination, Navigation]}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    pagination: {
                                        el: '.swiper-pagination',
                                        clickable: true,
                                    },
                                },
                                767: {
                                    slidesPerView: 1,
                                },
                            }}

                            className="mySwiper"
                        >
                            {
                            data?.galleries?.list?.[0]?.items && data?.galleries?.list?.[0]?.items?.length>0 &&
                                data?.galleries?.list?.[0]?.items?.map((element,index)=>{
                            return(
                            <SwiperSlide key={index}>
                                <div className="product-img">
                                    <Img src={element?.full_path}/>
                                </div>
                            </SwiperSlide>
                        )
                        })
                        }
                    </Swiper>
                        }
                    </Col>
                </Row>
            </Container>
            <RelatedProducts products={relatedProduct}/>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  
  h2{
    font-size: 40px;
    line-height: 52px;
    color: ${hover};
    margin-bottom: 50px;
  }
  .product-content{
    p{
     
    }
    table{
      margin-bottom: 60px;
      margin-top: 60px;
      tbody{
        tr{
          border-bottom: 0.5px solid rgba(32,32,32,0.2);
          td{
            padding-bottom: 10px;
            padding-top: 15px;
            &:nth-child(1){
              width: 20%;
            }
            &:nth-child(2){
              width: 10%;
            }
            &:nth-child(3){
              width: 70%;
            }
          }
        }
      }
    }
  }
  .product-img{
    position: relative;
    padding-top: calc(480 / 540 * 100%);
  }
  
  .product-slider{
    position: relative;
    .slider-nav{
      .prev_swipper{
        position: absolute;
        top: 47%;
        left: 5%;
        z-index: 555;
      }
      .next_swipper{
        position: absolute;
        top: 47%;
        right: 5%;
        z-index: 555;
      }
    }
    @media(max-width: 767px){
      margin-top: 40px;
    }
  }
  .swiper-pagination{
    display: block;
  background-color: #0b2e13;
  }
`;

export default MyComponent;
