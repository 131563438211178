import {createSlice, createAsyncThunk} from "@reduxjs/toolkit";
import {get, post, postFile} from "../../network/axiosServices";
// import {loaded} from "../global";


const initialState = {
    loading: false,
    posts: [],
    error: '',
    listLoading: false,
    listPosts: [],
    listError: ''
}

export const fetchCareer = createAsyncThunk("fetchCareer", (params, {dispatch}) => {
    return get(params);
});
export const fetchCareerList = createAsyncThunk("fetchCareerList", (params, {dispatch}) => {
    return get(params);
});

export const postForm = createAsyncThunk("career", (params) => {
    return postFile(params);
});

const postSlice = createSlice({
    name: 'career',
    initialState,
    extraReducers: builder => {
        builder.addCase(fetchCareer.pending, (state) => {
            state.loading = true
            state.posts = []
            state.error = ''
        })
        builder.addCase(fetchCareer.fulfilled, (state, action) => {
            state.loading = false
            state.posts = action.payload
            state.error = ''

        })
        builder.addCase(fetchCareer.rejected, (state, action) => {
            state.loading = false
            state.posts = []
            state.error = action.error
        })
        builder.addCase(fetchCareerList.pending, (state) => {
            state.listLoading = true
            state.listPosts = []
            state.listError = ''
        })
        builder.addCase(fetchCareerList.fulfilled, (state, action) => {
            state.listLoading = false
            state.listPosts = action.payload
            state.listError = ''

        })
        builder.addCase(fetchCareerList.rejected, (state, action) => {
            state.listLoading = false
            state.listPosts = []
            state.listError = action.error
        })
        //-- post
        builder.addCase(postForm.pending, (state) => {
            state.loading = true;
            state.success = [];
            state.error = "";
        });
        builder.addCase(postForm.fulfilled, (state, action) => {
            state.loading = false;
            state.success = action.payload.message;
            state.error = "";

        });
        builder.addCase(postForm.rejected, (state, action) => {
            state.loading = false;
            state.success = '';
            state.error = action.error;

        });


    }
})


export default postSlice.reducer
