import React from 'react';
import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

import TitleTwo from "../TitleTwo";
import {Img} from "../Img";
import {ImgParallax} from "../ImgParallax";
const AssetService = ({offset,img,icon,services,reverse,title,desc,text,address,phone,mapLink,data}) => {

    return (
        <StyledService className={'asset-seal pt-150 horizontal-scroll bg-color'} reverse={reverse} data-speed-right={30} data-speed-left={-30} >
            <Container className={` ${reverse ? 'reverse' : ''}`}>

                        <Row className={'water-row'}>
                            <p className={'scroll-right bg-text-one'}>Water</p>
                            <p className={'scroll-left bg-text-two'}>Conservation</p>
                            <Col sm={4} className='asset-seal__img'>

                                <div className="asset-seal__img__inner">
                                    <Img layout={'responsive'} src={data?.images?.list?.[0]?.full_path} alt=""/>
                                </div>
                            </Col>

                            <Col sm={{span: 7,offset:1}} className='asset-seal__text'>
                                <div className="asset-seal__text__top">
                                    {
                                        data?.posts?.list?.[0]?.data?.title &&
                                        <TitleTwo text={data?.posts?.list?.[0]?.data?.title} margin={'0 0 40px 0'}/>

                                    }
                                    {

                                        reactHtmlParser(data?.posts?.list?.[0]?.data?.description)
                                    }
                                </div>
                                <div className="asset-seal__text__bottom">
                                    {
                                        data?.posts?.list?.[1]?.data?.title &&
                                        <TitleTwo text={data?.posts?.list?.[1]?.data?.title} margin={'0 0 40px 0'}/>

                                    }
                                    {
                                        data?.posts?.list?.[1]?.data?.description &&
                                        <p className={'title-desc split-up'}>{reactHtmlParser(data?.posts?.list?.[1]?.data?.description)}</p>
                                    }
                                </div>


                                {/*<div className="asset-seal__text__list">*/}
                                {/*    {*/}
                                {/*        data?.posts?.list && data?.posts?.list?.length>0 &&*/}
                                {/*        data?.posts?.list?.map((element,index)=>{*/}
                                {/*            return(*/}
                                {/*                <div className="asset-seal__text__list__item" key={index}>*/}
                                {/*                    <h3 className={'split-up'}>{element?.data?.title}</h3>*/}
                                {/*                    <p className={'split-up'}>{element?.data?.description} </p>*/}
                                {/*                </div>*/}
                                {/*            )*/}
                                {/*        })*/}
                                {/*    }*/}

                                {/*</div>*/}
                            </Col>
                        </Row>


            </Container>
        </StyledService>
    );
};

const StyledService = styled.section`
  position: relative;
  padding-bottom: 250px;
    @media(max-width: 767px){
      padding-bottom: 80px;
    }
  
  .container-fluid{
    padding-right: ${props => props.offset ? props.offset : '120'}px;
    &.reverse{
      padding-left: ${props => props.offset ? props.offset : '120'}px;

      .security-seal__text{
        padding-left: 0;
        padding-right: 70px;
      }

      @media(max-width: 992px){
        .reverse-row{
          flex-direction: column-reverse;
        }
      }
    }
  }
  .asset-seal__img {
    .title{
      display: none;
    }
    p{
      display: none;
    }
    
    &__inner {
        padding-top: calc(680 / 530 * 100%);
      position: relative;
      overflow: hidden;
      @media(max-width: 767px){
        padding-top: calc(345 / 345 * 100%) ;
      }
    }
    @media(max-width: 767px){
      padding-left: 15px !important;
      padding-right: 15px !important;
      .title{
        display: block ;
      }
      p{
        display: block;
        margin-bottom: 40px;
      }
    }
  }

  .asset-seal__text {
&__top{
    margin-bottom: 60px;
}
    p {
      line-height: 24px;
      color: #3C3C3B;
      margin-bottom: 20px;
      :last-child{
        margin-bottom: 0px;
      }
    }
    
    //@media(max-width: 767px){
    //  .title{
    //    display: none !important;
    //  }
    //  .title-desc{
    //    display: none;
    //  }
    //}
    
    &__list{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      //column-gap: 100px;
      &__item{
        flex: 0 0 calc(50% - 50px);
        margin-bottom: 50px;
        h3{
          color: #2A2627;
          margin-bottom: 20px;
          font-weight: 400;
        }
        @media(max-width: 767px){
          flex: 0 0 100%;
          margin-bottom: 40px;
        }
      }
    }
    .title{
      display: block;
    }
    @media(max-width: 767px){
      
    }
  }

  @media(min-width: 768px){
    .asset-seal__text{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
    }
  }
  @media (max-width: 991px) {
    .container-fluid{
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    .asset-seal__img {
      min-width: 100%;
      padding: 0;
    }
    .asset-seal__text {
      min-width: 100%;
      margin-top: 60px;
      margin-left: 0;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  @media (max-width: 767px) {
    img{
      border-radius: 0px;
    }
    .dc-btn {
      margin-top: 30px;
    }
  }

  .bg-text-one, .bg-text-two {
    font-size: 140px;
    line-height: 200px;
    font-weight: 300;
    margin: 0;
    color: rgba(0,0,0,0.05);
    position: absolute;
    white-space: nowrap;
    font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;

  }
  .bg-text-one {
    left: -5%;
    top: 70%;
  }

  .bg-text-two {
    right: -15%;
    bottom: 0;
  }

  @media(max-width: 767px){
    .bg-text-one, .bg-text-two{
      display: none;
    }

  }
    
    .water-row{
        @media(max-width: 767px){
            flex-direction: column-reverse;
        }
        .asset-seal__text{
            margin-top: 0;
        }
    }


`;
export default React.memo(AssetService);
