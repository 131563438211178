import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import Typography from "../components/Typography";
import {Container} from "react-bootstrap";
import NavButton from "../components/NavButton";
import Button from "../components/Button";
import BannerSlider from "../components/home/BannerSlider";
import Cta from "../components/home/Cta";
import Footer from "../components/Footer";
import HomeAbout from "../components/home/HomeAbout";
import HomeBanner from "../components/home/HomeBanner";
import TimelineV2 from "../components/about/Timeline-v2";
import Awards from "../components/home/Awards";
import Partners from "../components/home/Partners";
import CompanyAtaGlance from "../components/home/CompanyAtaGlance";
import BlogSlider from "../components/home/BlogSlider";

const MyComponent = () => {
    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Title</title>
                <meta name="description" content="Description"/>
            </Helmet>

            <StyledComponent>
                {/*<BannerSlider/>*/}
                <HomeBanner/>
                <HomeAbout/>
                <CompanyAtaGlance/>
                <Awards/>
                <Cta/>
                <Partners/>
                <BlogSlider/>
                {/*<TimelineV2/>*/}
                <Footer/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
