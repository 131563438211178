import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TitleTwo from "../TitleTwo";
import CsrList from "./CsrList";
import CsrSingle from "./CsrSingle";
import Button from "../Button";

const MyComponent = ({data,sectionData}) => {
    const [displayedItems, setDisplayedItems] = useState(3);

    const handleLoadMoreClick = () => {
        setDisplayedItems((prevDisplayedItems) => prevDisplayedItems + 3);
    };
    return (
        <StyledComponent className={'pb-120'} id={'sustainability'}>
            <Container>
                <Row>
                    <Col md={10}>

                            <TitleTwo text={'Related CSR'} margin={'0 0 30px 0'}/>

                    </Col>
                </Row>
                <Row>
                    {
                        data && data?.length>0 &&
                        data?.slice(0, displayedItems)?.map((element,index)=>{
                            return(
                                <Col md={4} key={index}>
                                    <CsrSingle
                                        img={element?.images?.list?.[0]?.full_path}
                                        title={element?.page_data?.title}
                                        short_desc={element?.page_data?.short_desc}
                                        link={`sustainability/${element?.page_data?.slug}`}
                                    />
                                </Col>
                            )
                        })
                    }

                </Row>
                {data && data?.length > displayedItems && (
                    <Row>
                        <Col className={'load-button'}>
                            <Button
                                text={'Load More'}
                                border={'1px solid #000000'}
                                hoverBorderColor={'#8A1313'}
                                color={'#000000'}
                                onClick={handleLoadMoreClick}
                            />
                        </Col>
                    </Row>
                )}
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    
  .load-button{
    .dc-btn{
      margin: auto;
    }
  }
    .text{
        margin-bottom: 40px;
    }
`;

export default MyComponent;
