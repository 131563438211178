import React, {useEffect} from 'react';
import styled from "styled-components";
import {Img} from "./Img";
import {Container} from "react-bootstrap";
import Title from "./Title";
import reactHtmlParser from "react-html-parser";
import VisibilitySensor from "react-visibility-sensor";
import {title} from "../styles/globalStyleVars";
import {ImageParallax} from "./ImageParallax";
import {ImgParallax} from "./ImgParallax";
import {gsap} from 'gsap';



const InnerBanner = ({img, text, title}) => {

    return (
        <StyledInnerBanner className='InnerBanner'>
            {
                img &&
                <Img banner={true} src={img}/>
            }
            <Container>
                {
                    title &&
                    <h1 className={''}>{reactHtmlParser(title)}</h1>
                }

            </Container>
        </StyledInnerBanner>
    );
};

const StyledInnerBanner = styled.section`
  padding-top: calc(500 / 1366 * 100%);
  position: relative;
  //background-color: #000;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background-color: rgba(0, 0, 0, 0.5);
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
  }

  .container {
    position: absolute;
    //height: 100%;
    //top: 0;
    margin: auto;
    left: 0;
    right: 0;
    bottom: 94px;
    z-index: 2;
  }

  h1 {
    //position: absolute;
    left: 15px;
    //bottom: 94px;
    color: #ffffff;
    font-size: 56px;
    line-height: 82px;
    text-transform: capitalize;
    z-index: 2;
    font-family: ${title} !important;


    span {
      font-weight: 600;
      color: #ffffff;
    }
  }

  @media (min-width: 767px) {
    .title {
      width: 50%;
    }
  }

  @media (max-width: 767px) {
    //padding-top: 0;
    padding-top: calc(500 / 375 * 100%);
    .container {
      bottom: 69px;
    }

    .title {
      margin-bottom: 40px !important;
    }

    h1 {
      font-size: 40px;
      line-height: 45px;
    }
  }
`;

export default InnerBanner;
