import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {Img} from "../Img";
import NavButton from "../NavButton";
import TitleTwo from "../TitleTwo";
import SingleProduct from "./SingleProduct";

const MyComponent = ({title,data,products}) => {
    return (
        <StyledComponent className={'pt-150'}>
            <Container>
                <Row className={'management-team'}>
                    <Col md={10}>
                        <h3>Related Products</h3>
                    </Col>
                    <Col md={2}>
                        <div className="testimonial__head__navigation">
                            <NavButton prev_id={'gallery-prev'} next_id={'gallery-next'}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            products&& products?.length>0 &&
                        <Swiper
                            slidesPerView={4}
                            spaceBetween={30}
                            speed='500'
                            navigation={{
                                prevEl: '#gallery-prev',
                                nextEl: '#gallery-next',
                            }}
                            initialSlide={1}
                            loop={true}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1024: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1025: {
                                    slidesPerView: 4,
                                    spaceBetween: 30,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}

                            className="mySwiper"
                        >
                            {
                                products && products?.length>0 &&
                                products?.map((element,index)=>{
                                    return(
                            <SwiperSlide key={index}>
                                <SingleProduct
                                    title={element?.product_data?.title}
                                    img={element?.images?.list?.[0]?.full_path}
                                    link={`/product/${element?.product_data?.slug}`}
                                />
                            </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mobile-btn">
                            <NavButton prev_id={'gallery-prev'} next_id={'gallery-next'}/>
                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .management-team{
    padding-bottom: 18px;
    position: relative;
    margin: 0 auto 60px auto;
    &:after{
      content: '';
      position: absolute;
      background: rgba(34,31,31,0.3);
      height: 1px;
      bottom: 0;
      left: 0;
      width: calc(100%);
    }
    .col-md-10, .col-md-2{
      padding: 0;
    }
    @media(max-width: 767px){
      margin-bottom: 40px;
    }
    h3{
      font-weight: 400;
      margin-top: 15px;
    }
  }
  .testimonial__single{
    padding: 54px 41px 46px 49px;
    border: 0.5px solid #CBB1B1;
    &__img{
      position: relative;
      padding-top: calc(110 / 120 * 100%);
      @media(max-width: 767px){
        padding-top: calc(163 / 180 * 100%);
      }
    }
  }

  .testimonial__head__navigation{
    .navigation{
      justify-content: end;
    }
    @media(max-width: 767px){
      display: none;
    }
  }
  .mobile-btn{
    display: none;
    margin-top: 40px;
    @media(max-width: 767px){
      display: block;
    }
  }
`;

export default React.memo(MyComponent);
