import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TitleTwo from "../TitleTwo";
import {Img} from "../Img";
import {ImgParallax} from "../ImgParallax";
import reactHtmlParser from "react-html-parser";
import {Link} from "react-router-dom";

const MyComponent = ({data}) => {

    return (
        <StyledComponent className={'pt-150 pb-150'}>
            {
                data?.images?.list &&
                <ImgParallax src={data?.images?.list?.[0]?.full_path} alt=""/>
            }
            <Container>
                <Row>
                    <Col md={3} className={'ataglance_left'}>
                        {
                            data?.section_data?.subtitle &&
                            <TitleTwo text={data?.section_data?.subtitle} color={'#FFFFFF'} lineColor={'#FFFFFF'}/>
                        }
                        <p className={'split-up'}>{data?.section_data?.short_desc}</p>
                    </Col>
                    <Col md={{span:8,offset:1}}>
                        {
                            data?.posts?.list && data?.posts?.list.length>0 &&
                            <div className="Flex_box ">
                                {
                                    data?.posts?.list && data?.posts?.list.length>0 &&
                                    data?.posts?.list?.map((element,index)=>{
                                        return(
                                            <div className="single_item_container" key={index}>
                                                <img decoding="async"
                                                     src={element?.images?.[0]?.full_path}
                                                     alt=""/>

                                                <p>{reactHtmlParser(element?.data?.title)}</p>
                                                <img className={'link'} src={'/images/dynamic/arrow.svg'} alt=""/>

                                                <a href={''} className="link-btn">
                                                </a>
                                                <div className="hover_content">
                                                    <img decoding="async"
                                                         src={element?.images?.[1]?.full_path}
                                                         alt=""/>
                                                    <div className="hover_content__text">
                                                        <p>{reactHtmlParser(element?.data?.description)}</p>
                                                        <Link to={`/${element?.data?.short_desc}`}>Learn More
                                                            <svg xmlns="http://www.w3.org/2000/svg" width="8.121" height="14.121" viewBox="0 0 8.121 14.121">
                                                                <g id="Group_23869" data-name="Group 23869" transform="translate(787.061 442.061)">
                                                                    <line id="Line_352" data-name="Line 352" x2="6" y2="6" transform="translate(-786 -429) rotate(-90)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1.5"/>
                                                                    <line id="Line_353" data-name="Line 353" x1="6" y2="6" transform="translate(-786 -435) rotate(-90)" fill="none" stroke="#FFFFFF" stroke-linecap="round" stroke-width="1.5"/>
                                                                </g>
                                                            </svg>
                                                        </Link>
                                                    </div>

                                                </div>
                                            </div>
                                        )
                                    })
                                }
                            </div>


                        }

                    </Col>
                </Row>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    position: relative;
  overflow: hidden;
    .back-img{
      position: absolute;
      top:0;
      left:0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      
    }
  .ataglance_left{
    p{
      color: #FFFFFF;
      margin-top: 40px;
    }
  }
    .Flex_box{
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      .single_item_container{
        background: #F3F3F3;
        flex: 0 0 calc(33.33333% - 18px);
        margin-bottom: 25px;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;
        min-height: 300px;
        overflow: hidden;
        cursor: pointer;
        img{
          height: auto;
          width: 60px;
          margin-bottom: 30px;
        }
        p{
          font-size: 24px;
          font-weight: 400;
          line-height: 32px;
          text-align: center;
        }
        .link-btn{
          position: absolute;
          height: 100%;
          width: 100%;
          top: 0;
          left: 0;
          z-index: 5;
          display: none;
          @media(max-width: 767px){
            display: block;
          }
        }
        .link{
          position: absolute;
          bottom: 0;
          right: 0;
          display: none;
          margin-bottom: 0;
          
        }
        .hover_content{
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          padding: 30px;
          opacity: 0;
          transition: all 0.4s ease-in;
          img{
            height: auto;
            width: 50px;
            margin-bottom: 30px;
            opacity: 0;
          }
          &__text{
            opacity: 0;
              display: flex;
              flex-direction: column;
              justify-content: space-between;
            p{
              color: #FFFFFF;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;
              text-align: left;
              transition: all 0.4s ease-in;
              //transform: translateY(20px);
              margin-bottom: 42px;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 4; 
              -webkit-box-orient: vertical;
            }
            a{
              color: #FFFFFF;
              font-size: 14px;
              font-weight: 400;
              line-height: 21px;
              text-align: left;
              transition: all 0.4s ease-in;
                position: absolute;
                bottom: 40px;
              svg{
                width: 10px;
                height: 10px;
                transition: all 0.5s ease;
                margin-left: 10px;
              }
              &:hover{
                  color: #FFFFFF !important;
                svg{
                  transform: translateX(20px);
                }
              }
            }
          }
        }
        &:hover{
          .hover_content{
            opacity: 1;
            img{
              opacity: 1;
            }
            &__text{
              opacity: 1;
              p{
                transform: translateY(0);
              }
            }
            &:before{
              transform: translateY(0);
            }
          }
        }
        @media(max-width: 767px){
          margin-bottom: 20px !important;
          flex: 0 0 calc(50% - 10px) !important;
        }
        &:before{
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background: #8A1313;
          transform: translateY(100%);
          transition: all 0.5s cubic-bezier(0.25, 0.25, 0.50, 1);
        }
        &:hover{
          &:before{
            transform: translateY(0);
          }
        }
      }
    }
  @media(max-width: 767px){
    .col-md-8{
      margin-top: 40px;
    }
  }

`;

export default MyComponent;
