export const ApiParam = {
    type: 'type',
    value: 'value',
    image : 'image',
    post : 'post',
    file : 'file',
    get_section : 'get_section',
    gallery : 'gallery',
    specification : 'specification',
    page_id : 'page_id',
    category: 'category_id',
    job_sub_cat: 'post',
    keyword: 'keyword',
};
