import React, {useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TitleTwo from "../TitleTwo";
import CsrList from "./CsrList";
import CsrSingle from "./CsrSingle";
import Button from "../Button";

const MyComponent = ({data,sectionData,csrID}) => {
    const [displayedItems, setDisplayedItems] = useState(6);

    const handleLoadMoreClick = () => {
        setDisplayedItems((prevDisplayedItems) => prevDisplayedItems + 6);
    };
    return (
        <StyledComponent className={'pt-150 pb-150'} id={csrID}>
            <Container>
                <Row>
                    <Col md={10}>
                        {
                            sectionData?.section_data?.subtitle &&
                            <TitleTwo text={sectionData?.section_data?.subtitle} margin={'0 0 30px 0'}/>
                        }
                        <p className={'text'}>{sectionData?.section_data?.short_desc}</p>
                    </Col>
                </Row>
                <Row>
                    {
                        data?.list && data?.list?.length>0 &&
                        data?.list?.slice(0, displayedItems)?.map((element,index)=>{
                            return(
                    <Col md={4} key={index}>
                        <CsrSingle
                        img={element?.images?.list?.[0]?.full_path}
                        title={element?.page_data?.title}
                        short_desc={element?.page_data?.short_desc}
                        link={`sustainability/${element?.page_data?.slug}`}
                        />
                    </Col>
                            )
                        })
                    }

                </Row>
                {data?.list && data?.list?.length > displayedItems && (
                    <Row>
                        <Col className={'load-button'}>
                            <Button
                                text={'Load More'}
                                border={'1px solid #000000'}
                                hoverBorderColor={'#8A1313'}
                                color={'#000000'}
                                onClick={handleLoadMoreClick}
                            />
                        </Col>
                    </Row>
                )}
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  .load-button{
    .dc-btn{
      margin: auto;
    }
  }
    .text{
        margin-bottom: 40px;
    }
`;

export default MyComponent;
