import React, {useState} from 'react';
import styled from "styled-components";
import {Col, Container, Form, Modal, Row} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";

import TitleTwo from "../TitleTwo";
import {Img} from "../Img";
import {ImgParallax} from "../ImgParallax";
import Button from "../Button";
import ApplyButton from "../ApplyButton";
const AssetService = ({offset,img,icon,services,reverse,title,desc,text,address,phone,mapLink,revealClass,short_desc,bgColor}) => {
    const [show, setShow] = useState(false);
    const [popupTitle, setPopupTitle] = useState('')


    const handleClose = () => setShow(false);
    const handleShow = (e) =>{
        setShow(true);
        setPopupTitle(title)
    }
    console.log(desc.split(' ').length)
    return (
        <StyledService className={`asset-seal pt-150 ${bgColor}`} reverse={reverse}>
            <Container className={` ${reverse ? 'reverse' : ''}`}>
                {
                    reverse==="yes" ?
                        <Row className={'reverse-row'}>
                            <Col sm={{span: 6}} className='asset-seal__text'>
                                {
                                    title &&
                                    <TitleTwo text={title} margin={'0 0 40px 0'}/>
                                }
                                <div className={'description'}>

                                        {
                                            reactHtmlParser(short_desc)
                                        }

                                    {
                                        address &&
                                        <div className="address ">
                                            <h3 className={''}>Address</h3>
                                            <a className={'map-address split-up'}
                                               href={mapLink}>{reactHtmlParser(address)}</a>
                                            <a className={'split-up'} href={`tel:${phone}`}>{phone}</a>
                                        </div>
                                    }

                                </div>

                                {
                                    desc && desc.split(' ').length > 65 ?
                                        <div onClick={() => handleShow(desc)}>
                                            <Button text={'Read More'} color={'#000000'}
                                                    border={'1px solid #000000'} hoverBorderColor={'#8A1313'}
                                                    margin={'60px 0 0 0'}
                                            />
                                        </div> : ''
                                }

                            </Col>
                            <Col sm={{span: 6}} className='asset-seal__img'>
                                {
                                    title &&
                                    <TitleTwo text={title} margin={'0 0 40px 0'}/>
                                }
                                <div className={`asset-seal__img__inner`}>
                                    <Img layout={'responsive'} src={img} alt=""/>
                                </div>
                            </Col>
                        </Row>
                        :
                        <Row>
                            <Col sm={6} className='asset-seal__img'>
                                {
                                    title &&
                                    <TitleTwo text={title} margin={'0 0 40px 0'}/>
                                }
                                <div className={`asset-seal__img__inner`}>
                                    <Img src={img} alt=""/>
                                </div>
                            </Col>

                            <Col sm={{span: 6}} className='asset-seal__text'>
                                {
                                    title &&
                                    <TitleTwo text={title} margin={'0 0 40px 0'}/>
                                }
                                <div className={'description'}>

                                    {
                                        reactHtmlParser(short_desc)
                                    }
                                    {
                                        address &&
                                        <div className="address ">
                                            <h3 className={''}>Address</h3>
                                            <a className={'map-address'}
                                               href={mapLink}>{reactHtmlParser(address)}</a>
                                            <a className={'split-up'} href={`tel:${phone}`}>{phone}</a>
                                        </div>
                                    }
                                </div>
                                {
                                    desc && desc.split(' ').length > 65 ?
                                <div onClick={() => handleShow(desc)}>
                                    <Button text={'Read More'} color={'#000000'}
                                            border={'1px solid #000000'} hoverBorderColor={'#8A1313'}
                                            margin={'60px 0 0 0'}
                                    />
                                </div> : ''
                                }
                            </Col>
                        </Row>
                }

            </Container>
            <Modal show={show} className='business-modal' onHide={handleClose}>
                <Modal.Body>


                    <div className="modal-data">
                        <Container>
                            <Row>
                                <Col md={12}>
                                    <div className="modal-title">
                                        <h2>{popupTitle}</h2>
                                        <div onClick={handleClose} className="modal-close dc-btn">
                                            <svg xmlns="http://www.w3.org/2000/svg" width="32.121" height="32.121" viewBox="0 0 32.121 32.121">
                                                <g id="Group_23302" data-name="Group 23302" transform="translate(-1226.939 -58.939)">
                                                    <line id="Line_3591" data-name="Line 3591" x2="30" y2="30" transform="translate(1228 60)" fill="none" stroke="#6e6e6e" stroke-linecap="round" stroke-width="1.5"/>
                                                    <line id="Line_3592" data-name="Line 3592" y1="30" x2="30" transform="translate(1228 60)" fill="none" stroke="#6e6e6e" stroke-linecap="round" stroke-width="1.5"/>
                                                </g>
                                            </svg>

                                        </div>
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={12} className='modal-data__content d-flex'>
                                    <Col md={7}>
                                        {
                                            reactHtmlParser(desc)
                                        }
                                    </Col>
                                    <Col md={5}>
                                        <img src={img} alt=""/>
                                    </Col>
                                </Col>
                            </Row>
                        </Container>

                    </div>
                </Modal.Body>
            </Modal>
        </StyledService>
    );
};

const StyledService = styled.section`
  background-color: #FFFFFF;
    &.bg-color{
        background-color: rgb(245, 245, 245);
    }
  &:last-child{
    padding-bottom: 150px;
    @media(max-width: 767px){
      padding-bottom: 80px;
    }
  }
  .container-fluid{
    // padding-right: ${props => props.offset ? props.offset : '115'}px;
    &.reverse{
      // padding-left: ${props => props.offset ? props.offset : '90'}px;

      .security-seal__text{
        padding-left: 0;
        padding-right: 70px;
      }
      
      @media(max-width: 992px){
        .reverse-row{
          flex-direction: column-reverse;
        }
      }
    }
  }
  .asset-seal__img {
    padding-right: 35px;
    .title{
      display: none;
    }
    &__inner {
      padding-top: calc(480 / 530 * 100%);
      position: relative;
      overflow: hidden;
      min-height: 100%;
        img{
            object-fit: cover;
        }
    }
    @media(max-width: 767px){
      .title{
        display: block;
      }
    }
  }
  .reverse-row{
    .asset-seal__text{
      padding-right: 35px;
    }
    .asset-seal__img{
      padding-left: 35px;
    }
    @media(max-width: 768px){
      flex-direction: column-reverse;
    }
  }

  .asset-seal__text {
    p {
      color: #3C3C3B;
      :last-child{
        margin-bottom: 0px;
      }
    }
    h3{
      margin-top: 60px;
      margin-bottom: 20px;
      font-weight: 300;
      font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
      color: #2A2627;
    }
    .address{
      a{
        font-size: 16px;
        line-height: 24px;
        display: block;
        width: max-content;
      }
      .map-address{
        margin-bottom: 20px;
        p{
          margin-bottom: 0;
        }
      }
    }
    .title{
      display: block;
    }
    @media(max-width: 767px){
      .title{
        display: none;
      }
    }
  }

  @media(min-width: 768px){
    .asset-seal__text{
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      justify-content: center;
      padding-left: 35px;
    }
  }
  @media (max-width: 991px) {
    .container-fluid{
      padding-right: 15px !important;
      padding-left: 15px !important;
    }
    .asset-seal__img {
      min-width: 100%;
      padding-left: 15px !important;
      padding-right: 15px !important;
        overflow: hidden;
    }   
    .asset-seal__text {
      min-width: 100%;
      margin-top: 60px;
      margin-left: 0;
      padding-left: 15px !important;
      padding-right: 15px !important;
    }
  }

  @media (max-width: 767px) {
    img{
      border-radius: 0px;
    }
    .dc-btn {
      margin-top: 30px;
    }
  }
    
    .description{
        ul{
            margin-top: 40px;
           li{
               position: relative;
               padding-left: 20px;
               &:after{
                   content: '';
                   position: absolute;
                   top: 7px;
                   left: 0;
                   width: 10px;
                   height: 10px;
                   background-color: #8A1313;
                   border-radius: 50%;
               }
           } 
        }
    }


`;
export default React.memo(AssetService);
