import React, {useCallback, useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useHistory, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import HomeAbout from "../../components/home/HomeAbout";
import TimelineV2 from "../../components/about/Timeline-v2";
import Chairman from "../../components/about/Chairman";
import ManagingDirector from "../../components/about/ManagingDirector";
import Zigzag from "../../components/business-units/Zigzag";
import NewsList from "../../components/news/NewsList";
import CareerList from "../../components/career/CareerList";
import JoinTeam from "../../components/career/JoinTeam";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchCareer} from "../../api/redux/career";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {

    const dispatch = useDispatch();
    const location = useLocation();
    const history = useHistory();
    const getPost = useSelector(state => state.career);
    const catList = getPost?.posts?.job_cat;
    const careerList = getPost?.posts?.job_list;

    const [activeCat, setActiveCat] = useState(null);
    const [activeSubCat, setActiveSubCat] = useState(careerList?.[0]?.data?.post);

    const updateSearchParams = useCallback(() => {
        const searchParams = new URLSearchParams(location.search);
        if (activeCat !== null) {
            searchParams.set("category_id", activeCat);
        }
        searchParams.set("post", activeSubCat);
        const newSearch = searchParams.toString();
        history.push({ search: newSearch });
        console.log("Search params updated:", newSearch);
    }, [activeCat, activeSubCat, history, location.search]);

    useEffect(() => {
        updateSearchParams();
    }, [activeCat, activeSubCat, updateSearchParams]);

    useEffect(() => {
        const api_url = apiEndPoints.JOB_FILTER;
        dispatch(fetchCareer([api_url]));
    }, [dispatch]);

    useEffect(() => {
        if (getPost?.posts?.job_list?.length > 0) {
            const initialCategory = getPost.posts.job_list[0]?.data?.category_id;
            if (initialCategory) {
                setActiveCat(initialCategory);
                setActiveSubCat(''); // Clear subcategory when category changes
            }
        }
    }, [getPost]);

    const page_data = getPost?.posts?.data?.page_data;
    const careerBanner = getPost?.posts?.page_data?.sections?.find(f => f?.section_data?.slug === 'career-banner');



    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | Career</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>

            <StyledComponent>

                <InnerBanner img={careerBanner?.images?.list?.[0]?.full_path} title={careerBanner?.section_data?.subtitle}/>
                <CareerList data={careerList}
                            filterList={catList}
                            activeCategory={activeCat} setActiveCategory={setActiveCat}
                            activeSubCat={activeSubCat} setActiveSubCat={setActiveSubCat}
                />
                <JoinTeam/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
