import React, {useEffect, useState} from 'react';
import styled from "styled-components";
import {Link, useHistory} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import NewsList from "../../components/news/NewsList";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchMedia} from "../../api/redux/media";
import {useDispatch, useSelector} from "react-redux";
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {motion} from "framer-motion";

const MyComponent = () => {
    const [activeCategory, setActiveCategory] = useState(0);
    const dispath = useDispatch()
    const history = useHistory();



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.BLOG_LIST
        let param = {
            [ApiParam.category]: activeCategory,
        }
        dispath(fetchMedia([api_url, param]))
        const newUrl = activeCategory === 0 ? '/media-center' : `/media-center?${activeCategory}`;
        history?.push(newUrl);
    }, [dispath])

    const handleCategoryClick = (category) => {
        setActiveCategory(category);

    };


    let getPost = useSelector(state => state.media)
    let page_data = getPost?.posts?.data?.page_data
    const newsBanner = getPost?.posts?.page_data?.sections?.find(f => f?.section_data?.slug === 'media-center-banner');
    const newsList = getPost?.posts?.data;
    const filterList = getPost?.posts?.media_cat;
    const categoriesWithAll = Array.isArray(filterList) ? [{id: 0, title: 'All'}, ...filterList] : [];


    // const newData = newsList?.filter((item) => item?.data?.category_id === activeCategory);
    const newData = activeCategory === 0
        ? newsList?.filter((item) => item?.data?.category_id !== 12)  // If 'All' is selected, show all items
        : newsList?.filter((item) => item?.data?.category_id === activeCategory);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | Media Center</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>

            <StyledComponent>
                <InnerBanner img={newsBanner?.images?.list?.[0]?.full_path} title={newsBanner?.section_data?.subtitle}/>
                <NewsList data={newData}
                          filterList={categoriesWithAll}
                          handleCategoryClick={handleCategoryClick}
                          setActiveCategory={setActiveCategory} activeCategory={activeCategory}/>
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
