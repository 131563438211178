import React from 'react';
import styled from 'styled-components';
import {Col, Container, Row} from "react-bootstrap";
import {Img} from "../Img";
import Button from "../Button";
import {ImgParallax} from "../ImgParallax";


const Cta = ({data}) => {

    return (

        <StyledCTA className="image-wrapper">
            <ImgParallax src={data?.images?.list?.[0]?.full_path}/>
                <Container>
                    <Row>
                        <Col md={5} className={'cta-left'}>
                            <h3 className={'split-up'}>{data?.posts?.list?.[0]?.data?.title}</h3>
                            <p className={'split-up'}>{data?.posts?.list?.[0]?.data?.description}</p>
                            <Button  hoverBorderColor={'#8A1313'} data-lag={0.1}
                                    background={'transparent'} border={'1px solid #FFFFFF'} color={'#FFFFFF'}
                                    src={'/sustainability'}
                                    text={'Learn More'}/>

                        </Col>
                        <Col md={{span:5,offset:2}}>
                            <h3 className={'split-up'}>{data?.posts?.list?.[1]?.data?.title}</h3>
                            <p className={'split-up'}>{data?.posts?.list?.[1]?.data?.description}</p>
                            <Button  hoverBorderColor={'#8A1313'} data-lag={0.1}
                                    background={'transparent'} border={'1px solid #FFFFFF'} color={'#FFFFFF'}
                                    src={'/about#milestone'}
                                    text={'Learn More'}/>
                        </Col>
                    </Row>
                </Container>
        </StyledCTA>

    )
};


const StyledCTA = styled.div`
  overflow: hidden;
    padding-bottom: 150px;
    padding-top: 150px;
    position: relative;
    will-change: transform;
    img{
      position: absolute;
      top:0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    h3 {
      font-size: 40px;
      line-height: 52px;
      padding-bottom: 20px;
      color: #FFFFFF;
      border-bottom: 1px solid rgba(112,112,112,0.6);
      margin-bottom: 40px;
      font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
    }
    p{
      color: #FFFFFF;
      margin-bottom: 40px;
    }
    @media(min-width: 1920px){
      padding-top: 150px;
      padding-bottom: 150px;
    }
  


  @media (max-width: 767px) {
      padding-bottom: 100px;
      padding-top: 100px;
    
    .cta-left{
      margin-bottom: 80px;
    }

  }
`;

export default React.memo(Cta);














