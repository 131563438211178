import React from 'react';
import styled from "styled-components";
import {Accordion} from "react-bootstrap";
import reactHtmlParser from "react-html-parser";
import {BsChevronDown} from "react-icons/bs";
import {Img} from "../Img";
import {hover} from "../../styles/globalStyleVars"
import TitleTwo from "../TitleTwo";

const Accordions = ({data}) => {
    const iframeStyle = {
        width: '100%',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        border: 0,
    };
    return (
        <StyledAddress className='address'>
            <div className={`address__accordion`} sm={12}>
                <Accordion >
                    {
                        data?.posts?.list && data?.posts?.list?.length>0 &&
                        data?.posts?.list?.map((element,index)=>{
                            return(
                    <Accordion.Item key={index} eventKey={`${index}`}>
                                    <Accordion.Header>
                                        <h2>{element?.data?.title}</h2>
                                        <span><BsChevronDown/></span>
                                    </Accordion.Header>
                                    <Accordion.Body>

                                        <div className="contact-numbers ">
                                            <div className={'wrapper-single-item'}>
                                                <h5>{element?.data?.subtitle}</h5>
                                                <p>Address</p>
                                                <a target={'_blank'} href={element?.data?.short_desc}>
                                                    {reactHtmlParser(element?.data?.address)}
                                                </a>
                                            </div>
                                            <div className="wrapper-single-item">
                                                <p>Phone</p>
                                                <ul>
                                                    <li><a href={`tel:${element?.data?.phone}`}>{element?.data?.phone}</a></li>
                                                    {
                                                        element?.data?.phone_two &&
                                                        <li><a
                                                            href={`tel:${element?.data?.phone_two}`}>{element?.data?.phone_two}</a>
                                                        </li>

                                                    }
                                                    {
                                                        element?.data?.phone_three &&
                                                        <li><a
                                                            href={`tel:${element?.data?.phone_three}`}>{element?.data?.phone_three}</a>
                                                        </li>
                                                    }

                                                </ul>
                                            </div>
                                            <div className="wrapper-single-item">
                                                <p>Email</p>
                                                <ul>
                                                    <li><a href={`mailto:${element?.data?.email}`}>{element?.data?.email}</a></li>
                                                </ul>
                                            </div>
                                            <div className="wrapper-single-item">
                                                {
                                                    element?.data?.fax &&
                                                    <>
                                                        <p>Fax</p>
                                                        <ul>
                                                            <li><a
                                                                href={`tel:${element?.data?.fax}`}>{element?.data?.fax}</a>
                                                            </li>
                                                        </ul>
                                                    </>
                                                }

                                            </div>
                                        </div>
                                        <div className="map">
                                            {
                                                element?.data?.description &&
                                                <a target={'_blank'} href={element?.data?.short_desc}>
                                                    <div className="img-wrap">
                                                        <iframe
                                                            style={iframeStyle}
                                                            src={element?.data?.description}
                                                            allowFullScreen
                                                        ></iframe>
                                                    </div>
                                                </a>
                                            }
                                        </div>

                                    </Accordion.Body>
                    </Accordion.Item>
                            )
                        })
                    }

                </Accordion>
            </div>
        </StyledAddress>
    );
};


const StyledAddress = styled.section`

    .accordion-item {
        margin-bottom: 80px;

        &:nth-last-child(1) {
            margin-bottom: 0;
        }
    }

    .accordion-header {
        position: relative;
        margin: 0;
        font-size: unset;
        line-height: unset;

        h2 {
            font-size: 40px;
            line-height: 52px;
            @media (max-width: 767px) {
                font-size: 28px;
                line-height: 36px;
            }
    }
    button {
      border: none;
      background-color: transparent;
      padding-left: 0;
      color: ${hover};
      padding-bottom: 15px;
      border-bottom: 1px solid ${hover};
      width: 100%;
      text-align: left;
      transition: all .4s ease;
      font-size: 24px;
      line-height: 32px;
      font-weight: 500;

      &.collapsed {
        color: #1A1818;
        border-color: #1A1818;

        span {
          background-color: black;

          svg {
            transform: rotate(0deg);
          }

          &:after {
            background-color: ${hover} !important;
          }
        }
      }
    }

    span {
      position: absolute;
      height: 30px;
      width: 30px;
      border-radius: 50%;
      color: #ffffff;
      background-color: ${hover};
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;
      bottom: 16px;

      &:after {
        height: 0;
        width: 0;
        background-color: ${hover};
        border-radius: 50%;
        opacity: 0;
        transition: all .4s ease;
        content: '';
        position: absolute;
      }

      svg {
        font-size: 13px;
        z-index: 2;
        transform: rotate(180deg);
      }

    }

    &:hover {
      span {
        &:after {
          height: 100%;
          width: 100%;
          opacity: 1;
        }
      }

      button {
        color: ${hover};
        border-color: ${hover};
      }
    }
  }

  .accordion-body {
    padding-top: 40px;

    p, a {
      font-size: 16px;
      line-height: 22px;
      font-weight: 500;
    }
    p{
      color: #2A2627;
      margin-bottom: 9px;
      opacity: 0.3;
    }
    a {
      color: #000;
    }

    .contact-numbers {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;

      .wrapper-single-item {
        flex: 0 0 calc(33% - 30px);
        margin-bottom: 40px;
        &:first-child{
          flex: 0 0 calc(100%);
        }
          h5{
              margin-bottom: 20px;
              font-weight: 500;
          }
          @media(max-width: 767px){
              margin-bottom: 30px;
          }
        
      }

      ul {
        li {
          a {
            margin-bottom: 13px;
            display: flex;

            span {
              font-size: 26px;
              font-weight: bold;
              line-height: 32px;
              transition: .3s ease;
            }

            &:hover span {
              color: red;
            }
          }

          &:nth-last-of-type(1) {
            margin-bottom: 0;
          }
        }
      }
        @media(max-width: 767px){
            flex-direction: column;
        }
    }

    .map {

      .img-wrap {
        position: relative;
        overflow: hidden;
        padding-top: calc(280 / 570 * 100%);
          @media(max-width: 767px){
              padding-top: calc(280 / 280 * 100%);
          }
      }
    }
  }


  .address__form {

    .form-control {
      font-size: 16px;
      line-height: 22px;
      border: none;
      background-color: transparent;
      border-bottom: 1px solid black;
      border-radius: 0;
      padding-left: 0;
      padding-bottom: 20px;
      margin-bottom: 40px;

      &::placeholder {
        color: rgba(34, 31, 31, 0.5) !important;
        font-size: 16px;
        line-height: 22px;
        font-weight: 300;
      }
    }

    textarea {
      min-height: 90px;
      max-height: 90px;
      min-width: 100%;
      max-width: 100%;
      margin-bottom: 60px;
    }

    .divider {
      width: 100%;
      display: flex;
      justify-content: space-between;

      .form-control {
        width: calc(50% - 15px);
      }
    }

    &__title {
      margin-bottom: 60px;

      h5 {
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 20px;
        font-weight: 600;
      }

      p {
        font-size: 26px;
        font-weight: bold;
        line-height: 32px;
      }
    }
  }

  @media (max-width: 768px) {
    .address__accordion {
      min-width: 100%;
    }

    .address__form {
      min-width: 100%;
      margin-top: 60px;
    }
  }

  @media (max-width: 767px) {

    .address__row {
      flex-direction: column-reverse;
    }

    .address__form {
      margin-top: 0;
      margin-bottom: 60px;
    }

    .contact-numbers {
      flex-wrap: wrap;

      .col-sm-4 {
        min-width: 50%;

        &:nth-last-child(1) {
          margin-top: 30px;
          padding-left: 0;
        }
      }
    }
  }
  @media(max-width: 767px){
    margin-bottom: 60px;
  }

`;


export default React.memo(Accordions);