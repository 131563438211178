import styled from "styled-components";
import {Col, Container, Row} from "react-bootstrap";
import Title from "../Title";
import Button from "../Button";
import {BsChevronLeft, BsChevronRight} from 'react-icons/bs';
import {useEffect, useRef, useState} from "react";
import VisibilitySensor from "react-visibility-sensor";
import {Swiper, SwiperSlide} from 'swiper/react';
import {Autoplay, Navigation, Pagination} from "swiper";
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/scrollbar';
import {hover} from '../../styles/globalStyleVars'
import {Link, useHistory} from "react-router-dom";
import {Img} from "../Img";
import NavButton from "../NavButton";
import TitleTwo from "../TitleTwo";
import Media from "../Media";

const BlogSliderV1 = ({data,titledata}) => {
    // SwiperCore.use([Autoplay]);
    let leftRef = useRef();
    let rightRef = useRef();
    let mLeftRef = useRef();
    let mRightRef = useRef();
    let containerRef = useRef();
    let sliderRef = useRef();
    const [current, setCurrent] = useState('')
    let [offset, setOffset] = useState(90)
    let [theWidth, SetTheWidth] = useState(0)
    let [activeNumber, setActiveNumber] = useState(1)
    let [totalNumber, setTotalNumber] = useState(5)




    // useEffect(() => {
    //     setOffset(containerRef.current?.offsetLeft)
    //     window.addEventListener('resize', () => {
    //         setOffset(containerRef.current?.offsetLeft)
    //         SetTheWidth(window.innerWidth)
    //     })
    //
    //     // slider number
    //     const getActiveItem = document.querySelector('.swiper-pagination-current')?.innerHTML
    //     const getTotalItem = document.querySelector('.swiper-pagination-total')?.innerHTML
    //     setActiveNumber(getActiveItem)
    //     setTotalNumber(getTotalItem)
    // }, [null])





    // useEffect(() => {
    //     const getTotalItem = 5
    //     setTotalNumber(getTotalItem)
    // }, [data])
    // let sliderNumber = () => {
    //     const getActiveItem = document.querySelector('.swiper-pagination-current').innerHTML
    //     // const getTotalItem = document.querySelector('.swiper-pagination-total').innerHTML
    //     setActiveNumber(getActiveItem)
    //     // setTotalNumber(getTotalItem)
    // }

    return (
        <StyledBlog className='blog-slider pt-150 pb-150'>
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="blog-title">
                            {
                                titledata?.section_data?.subtitle &&
                                <TitleTwo margin={'0 0 50px 0'} text={titledata?.section_data?.subtitle}/>
                            }
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="blog-button">
                            <Button src={'/media-center'} text={'View All'} color={'#000000'}
                                    border={'1px solid #000000'} hoverBorderColor={'#8A1313'}/>
                            <NavButton prev_id={'blog_prev'} next_id={'blog_next'}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className={` fade-up blog-slider__slider-wrap`}>
                            <div className="blog-slider__slider-wrap__inner">
                                {
                                    data && data?.length>0 &&
                                <Swiper loop={true}
                                        spaceBetween={30}
                                        slidesPerView={3}
                                        allowSlideNext={true}
                                        allowSlidePrev={true}
                                        allowTouchMove={true}
                                        autoplay={false}
                                        speed={900}
                                        pagination={{
                                            type: "fraction",
                                        }}
                                        navigation={{
                                            prevEl: '#blog_prev',
                                            nextEl: '#blog_next',
                                        }}
                                        modules={[Autoplay, Pagination, Navigation]}

                                        breakpoints={{
                                            320: {
                                                slidesPerView: 1,
                                                spaceBetween: 30,
                                            },
                                            991: {
                                                slidesPerView: 2,
                                                spaceBetween: 30,
                                            },
                                            1024: {
                                                slidesPerView: 3,
                                                spaceBetween: 30,
                                            },
                                        }}

                                    // navigation={true} modules={[Navigation]}
                                        onSwiper={(swiper) => console.log(swiper)}
                                >

                                    {
                                        data && data?.length>0 &&
                                        data?.map((element,index)=>{
                                            return(

                                        <SwiperSlide key={index}>
                                            <Media
                                                title={element?.data?.title}
                                                img={element?.images?.list[0]?.full_path}
                                                date={element?.data?.date}
                                                link={element?.data?.link ? element?.data?.link : `/media-center/${element?.data?.slug}`}

                                            />
                                        </SwiperSlide>
                                            )
                                        })
                                    }

                                </Swiper>
                                }
                            </div>

                            <div className="slider-nav-mobile">
                                <Button src={'/media-center'} text={'View All'} color={'#000000'}
                                        border={'1px solid #000000'} hoverBorderColor={'#8A1313'}/>
                            </div>
                        </div>

                    </Col>
                </Row>
            </Container>


        </StyledBlog>
    );
};

const StyledBlog = styled.section`
  background-color: #F2F2F2;
  .blog-title {
    position: relative;

    p {
      position: absolute;
      top: 0;
      right: 0;

    }

  }


  .blog-button {
    display: flex;
    justify-content: end;
    gap: 70px;
    margin-top: 30px;
    align-items: center;
    @media(max-width: 767px){
      margin-top: 0;
        justify-content: start;
    }
    
  }

  .swiper-button-next, .swiper-button-prev {
    position: absolute;
    height: 40px;
    width: 40px;
    top: 0;
    bottom: 0;
    margin: auto;
    z-index: 99;
    background-color: red;
  }

  .swiper-button-next, .swiper-button-prev, .swiper-pagination {
    opacity: 0;
    visibility: hidden;
  }

  .swiper-initialized {
    //padding-right: 300px;
    //padding-left: 300px;
      // margin-right: ${props => props.offset + 15}px;
    // margin-left: ${props => props.offset + 15}px;
    // padding-right: ${props => props.offset + 15}px;
  }

  .slider-nav-mobile {
    display: none;
  }

  .blog-slider {
    &__slider-wrap {
      &__inner {
        .blog-single {
          &__inner {
            padding-top: 100%;
          }
        }
      }
    }
  }

  @media (max-width: 767px) {
    .swiper-container {
      //margin-left: 0;
      //padding-right: 0;
    }

    .blog-slider {
      &__slider-wrap {
        //margin-left: 15px;
        //margin-right: 15px;

        .slider-nav-mobile {
          //margin-top: 40px;

          ul {
            display: flex;
          }

          li {
            height: 50px;
            width: 50px;
            background-color: #000000;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            cursor: pointer;

            &:nth-of-type(1) {
              margin-right: 20px;
            }

            svg {
              color: #ffffff;
              z-index: 2;
            }
          }
        }
      }
    }

    .blog-button {
      margin-bottom: 40px;

      .dc-btn {
        display: none;
      }
    }

    .slider-nav-mobile {
      display: block;
    }

  }
    
    .media-single__img{
        padding-top: 100%;
    }

`;
export default BlogSliderV1;