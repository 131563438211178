import React, {useEffect} from 'react';
import styled from "styled-components";
import {Link, useLocation} from "react-router-dom";
import {HelmetProvider, Helmet} from 'react-helmet-async'
import InnerBanner from "../../components/InnerBanner";
import HomeAbout from "../../components/home/HomeAbout";
import TimelineV2 from "../../components/about/Timeline-v2";
import Chairman from "../../components/about/Chairman";
import ManagingDirector from "../../components/about/ManagingDirector";
import BoardMembers from "../../components/about/BoardMembers";
import {useDispatch, useSelector} from "react-redux";
import {apiEndPoints} from "../../api/network/apiEndPoints";
import {ApiParam} from "../../api/network/apiParams";
import {fetchHome} from "../../api/redux/home";
import {fetchAbout} from "../../api/redux/about";
import MIssionVision from "../../components/about/MIssionVision";
import {gsap} from "gsap";
import {ScrollTrigger} from "gsap/ScrollTrigger";
import { ScrollToPlugin } from 'gsap/ScrollToPlugin';
import {PageAnimationParent} from "../../components/PageAnimationParent";
import {PageAnimation} from "../../components/PageAnimation";
import {AnimatePresence, motion} from "framer-motion";

const MyComponent = () => {
    gsap.registerPlugin(ScrollTrigger, ScrollToPlugin); // (or in the top-level file in your React app)
    const dispath = useDispatch()
    const location=useLocation();



    // api call
    useEffect(() => {
        let api_url = apiEndPoints.SECTIONS;
        let api_params = {
            [ApiParam.type]: 'slug',
            [ApiParam.value]: 'about-us',
            [ApiParam.get_section]: 'yes',
            [ApiParam.image]: 'yes',
            [ApiParam.post]: 'yes',
            [ApiParam.file]: 'yes',
            [ApiParam.gallery]: 'no',
        }
        dispath(fetchAbout([api_url , api_params]))

    }, [location?.pathname])

    const getData = useSelector((state) => state.about);

    let page_data = getData?.posts?.data?.page_data;
    const banner = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-banner');
    const overview = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-overview');
    const timeline = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'about-timeline');
    const missionVision = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'mission-vision');
    const chairman = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'chairman-message');
    const director = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'message-from-md');
    const Board = getData?.posts?.data?.sections?.find(f => f?.section_data?.slug === 'board-member');
    console.log(page_data);

    useEffect(() => {
        window.scroll(0, 0)
    }, [])
    useEffect(() => {
        if (location.hash && !getData?.loading) {
            const targetElement = document.querySelector(location.hash);
            const topPx = targetElement.offsetTop;
            console.log(topPx);
            if (targetElement) {
                gsap.to(window, {
                    duration: 0.8,
                    scrollTo: topPx
                });
            }
        }
    }, [location, getData]);

    return (
        <HelmetProvider>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Mitali Group | About</title>
                <meta
                    name="description"
                    content="Mitali Group has been established since 2000 and has rapidly become a major supplier of ready made garments"
                />
            </Helmet>


            <StyledComponent>

                <InnerBanner img={banner?.images?.list?.[0]?.full_path} title={banner?.section_data?.subtitle}/>
                <HomeAbout title={overview?.section_data?.subtitle}
                           img={overview?.images?.list?.[0]?.full_path}
                           desc={overview?.section_data?.description}
                           leftText={overview?.section_data?.short_desc}
                           revealClass={'reveal-new'}
                           button
                           companyDoc={overview?.files?.list?.[0]?.full_path}
                />
                <TimelineV2 data={timeline}/>
                <MIssionVision data={missionVision}/>
                <Chairman data={chairman}/>
                <ManagingDirector data={director}/>
                <BoardMembers data={Board} />
            </StyledComponent>
        </HelmetProvider>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
