import React, {useEffect, useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import Title from "../Title";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import 'swiper/css/scrollbar';
import SwiperCore, {Autoplay, Navigation, Pagination} from "swiper";
import {Swiper, SwiperSlide} from "swiper/react"
import {Img} from "../Img";
import NavButton from "../NavButton";
import TitleTwo from "../TitleTwo";
import {hover} from "../../styles/globalStyleVars";

const MyComponent = ({title,data}) => {
    const containerRef = useRef(null);
    const [swiperInstance, setSwiperInstance] = useState(null);

    useEffect(() => {
        const observer = new IntersectionObserver(
            ([entry]) => {
                if (entry.isIntersecting) {
                    swiperInstance?.autoplay?.start();
                } else {
                    swiperInstance?.autoplay?.stop();
                }
            },
            { threshold: 0.1 } // Adjust this threshold as needed
        );

        if (containerRef.current) {
            observer.observe(containerRef.current);
        }

        return () => {
            if (containerRef.current) {
                observer.unobserve(containerRef.current);
            }
        };
    }, [swiperInstance]);
    return (
        <StyledComponent className={'pt-150 pb-150'} ref={containerRef}>
            <Container>
                <Row className={'management-team'}>
                    <Col md={10}>
                        {
                            data?.section_data?.subtitle &&
                            <TitleTwo text={data?.section_data?.subtitle} margin={'0 0 60px 0'}/>
                        }
                    </Col>
                    <Col md={2}>
                        <div className="testimonial__head__navigation">
                            <NavButton prev_id={'gallery-prev'} next_id={'gallery-next'}/>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        {
                            data?.images?.list && data?.images?.list?.length>0 &&
                        <Swiper
                            onSwiper={setSwiperInstance}
                            slidesPerView={5}
                            spaceBetween={30}
                            autoplay={{
                                delay: 500,
                                disableOnInteraction: false,
                            }}
                            speed='500'
                            navigation={{
                                prevEl: '#gallery-prev',
                                nextEl: '#gallery-next',
                            }}
                            initialSlide={0}
                            loop={true}
                            breakpoints={{
                                320: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1024: {
                                    slidesPerView: 1,
                                    spaceBetween: 30,

                                },
                                1025: {
                                    slidesPerView: 5,
                                    spaceBetween: 30,
                                },
                            }}
                            modules={[Autoplay, Pagination, Navigation]}

                            className="mySwiper"
                        >
                            {
                                data?.images?.list && data?.images?.list?.length>0 &&
                                data?.images?.list?.map((element,index)=>{
                                    return(
                            <SwiperSlide key={index}>
                                <div className="testimonial-wrapper">
                                    <div className="testimonial__single">
                                        <div className="testimonial__single__img">
                                            <Img src={element?.full_path}/>
                                        </div>
                                    </div>
                                </div>
                            </SwiperSlide>
                                    )
                                })
                            }
                        </Swiper>
                        }
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <div className="mobile-btn">
                            <NavButton prev_id={'gallery-prev'} next_id={'gallery-next'}/>
                        </div>
                    </Col>
                </Row>
            </Container>

        </StyledComponent>
    );
};

const StyledComponent = styled.section`
    background-color: #F2F2F2;
    .testimonial-wrapper{
      border: 0.5px solid #CBB1B1;
      position: relative;
      // &:before{
      //     content: "";
      //     position: absolute;
      //     height: 1px;
      //     top: 0px;
      //     width: 0%;
      //     left: 0px;
      //     background-color: ${hover};
      //     transition: all 150ms ease 0s;
      //     z-index: 2;
      // }
      // &:after{
      //     content: "";
      //     position: absolute;
      //     height: 0%;
      //     top: 0px;
      //     width: 1px;
      //     right: 0px;
      //     background-color: ${hover};
      //     transition: all 150ms linear 150ms;
      //     z-index: 2;
      // }
      &:hover{
          &:before{
              width: 100%;
          }
          &:after{
              height: 100%;
          }
      }
  }
  .testimonial__single{
    padding: 54px 41px 46px 49px;
    cursor: pointer;
      position: relative;
    &__img{
      position: relative;
      padding-top: calc(160 / 160 * 100%);
      @media(max-width: 767px){
        padding-top: calc(163 / 180 * 100%);
      }
      img{
        transition: 0.3s ease-in-out;
      }
    }
      // &:before{
      //     content: "";
      //     position: absolute;
      //     height: 1px;
      //     bottom: 0px;
      //     width: 0%;
      //     right: 0px;
      //     background-color: ${hover};
      //     transition: all 150ms ease 300ms;
      //     z-index: 2;
      // }
      // &:after{
      //     content: "";
      //     position: absolute;
      //     height: 0%;
      //     bottom: 0px;
      //     width: 1px;
      //     left: 0px;
      //     background-color: ${hover};
      //     transition: all 150ms ease 450ms;
      //     z-index: 2;
      // }
      
    
    &:hover{
        &:before{
            width: 100%;
        }
        &:after{
            height: 100%;
        }
        
      }
    }

  .testimonial__head__navigation{
    .navigation{
      justify-content: end;
      margin-top: 30px;
      @media(max-width: 767px){
        margin-top: 0;
      }
    }
    @media(max-width: 767px){
    display: none;
  }
  }
  .mobile-btn{
    display: none;
    margin-top: 40px;
    @media(max-width: 767px){
      display: block;
    }
  }
`;

export default React.memo(MyComponent);
