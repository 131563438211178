import React, {useRef, useState} from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";
import TitleTwo from "../TitleTwo";
import {hover} from "../../styles/globalStyleVars";
import SingleProduct from "./SingleProduct";
import {Link, useLocation} from "react-router-dom";
import reactHtmlParser from "react-html-parser";

const MyComponent = ({data,catList,activeCategory,setActiveCategory, setActiveSearch,activeSearch,isLoading}) => {
    const location = useLocation();
    const [activeCat, setActiveCat] = useState('byCategory');
    const categoryIdFromUrl = Number(new URLSearchParams(location.search).get('category_id')); // Convert to number
    const [activeFilter, setActiveFilter] = useState(categoryIdFromUrl || catList?.[0]?.id);
    const searchInputRef = useRef(null);

    const handleCategoryClick = (element) => {
        setActiveFilter(element?.id);
        setActiveCategory(element?.id);
        setActiveSearch('');
        if (searchInputRef.current) {
            searchInputRef.current.value = '';
        }

    };
    const handleCatClick = (category) => {
        setActiveCat(category);
        setActiveCategory('');
        setActiveSearch('');
        if (searchInputRef.current) {
            searchInputRef.current.value = '';
        }

    };
    const handleCatClickTwo = (category) => {
        setActiveCat(category);
        setActiveCategory(13);
        setActiveSearch('');
        if (searchInputRef.current) {
            searchInputRef.current.value = '';
        }

    };
    // const handleSearchChange = (value) => {
    //     setActiveSearch(value);
    // };
    const handleSearchSubmit = (e) => {
        e.preventDefault();
        const inputValue = e.target.elements.searchInput.value;
        setActiveSearch(inputValue);
    };
    const newData = data?.filter((item) => item?.product_data?.category_id === activeCategory);
    return (
        <StyledComponent className={'pb-150'}>
            <Container>
                <Row className={'category-list'}>
                    <Col md={8}>
                        <ul>
                            <li className={activeCat === 'byCategory' ? 'active' : ''}
                                onClick={() => handleCatClickTwo('byCategory')}><span>By Category</span></li>

                            <li className={activeCat === 'all' ? 'active' : ''} onClick={() => handleCatClick('all')}>
                                <span>All</span></li>
                        </ul>
                    </Col>
                    <Col md={4}>
                        {/*<div className="product-search">*/}
                        {/*    <input placeholder={'Search'} type="text"/>*/}
                        {/*    <button type={'submit'}>*/}
                        {/*        <svg xmlns="http://www.w3.org/2000/svg" width="17.811" height="17.811" viewBox="0 0 17.811 17.811">*/}
                        {/*            <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3.75 -3.75)">*/}
                        {/*                <path id="Path_14617" data-name="Path 14617" d="M18.722,11.611A7.111,7.111,0,1,1,11.611,4.5,7.111,7.111,0,0,1,18.722,11.611Z" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>*/}
                        {/*                <path id="Path_14618" data-name="Path 14618" d="M28.842,28.842l-3.867-3.867" transform="translate(-8.342 -8.342)" fill="none" stroke="#000" stroke-linecap="round" stroke-linejoin="round" stroke-width="1.5"/>*/}
                        {/*            </g>*/}
                        {/*        </svg>*/}
                        {/*    </button>*/}
                        {/*</div>*/}
                        <div className="product-search">
                            <form onSubmit={handleSearchSubmit}>
                                <input
                                    id="searchInput"
                                    name="searchInput"
                                    placeholder={'Search'}
                                    type="text"
                                    ref={searchInputRef}
                                />
                            <button type="submit">
                                <svg xmlns="http://www.w3.org/2000/svg" width="17.811" height="17.811" viewBox="0 0 17.811 17.811">
                                    <g id="Icon_feather-search" data-name="Icon feather-search" transform="translate(-3.75 -3.75)">
                                        <path
                                            id="Path_14617"
                                            data-name="Path 14617"
                                            d="M18.722,11.611A7.111,7.111,0,1,1,11.611,4.5,7.111,7.111,0,0,1,18.722,11.611Z"
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                        />
                                        <path
                                            id="Path_14618"
                                            data-name="Path 14618"
                                            d="M28.842,28.842l-3.867-3.867"
                                            transform="translate(-8.342 -8.342)"
                                            fill="none"
                                            stroke="#000"
                                            stroke-linecap="round"
                                            stroke-linejoin="round"
                                            stroke-width="1.5"
                                        />
                                    </g>
                                </svg>
                            </button>
                            </form>
                        </div>

                    </Col>
                </Row>
                {activeCat === 'all' && (
                <Row className={'all-products'}>
                    {
                        data && data?.length>0 &&
                        data?.map((element,index)=>{
                            return(
                    <Col md={3} key={index}>
                        <SingleProduct
                        title={element?.product_data?.title}
                        img={element?.images?.list?.[0]?.full_path}
                        link={`/product/${element?.product_data?.slug}`}
                        />
                    </Col>
                    )
                    })
                    }
                </Row>
                )}
                {activeCat === 'byCategory' && (
                <Row className={'category-product'}>
                    <Col md={3} className={'product-category-list'}>
                        <h3>All Categories</h3>
                        <ul className='desktop-lists'>
                            {
                                catList && catList?.length >0 &&
                                catList?.map((element,index)=>{
                                    return(
                                        <li className={element?.id === activeCategory ? 'active' : ''} key={index} onClick={() => handleCategoryClick(element)}>
                                            <a >{reactHtmlParser(element?.title)}</a>
                                        </li>
                                    )
                                })
                            }
                        </ul>
                    </Col>
                    <Col md={9}>
                        <Row>
                            {
                                data && data?.length>0 &&
                                data?.map((element,index)=>{
                                    return(
                                        <Col md={4} key={index}>
                                            <SingleProduct
                                                title={element?.product_data?.title}
                                                img={element?.images?.list?.[0]?.full_path}
                                                link={`/product/${element?.product_data?.slug}`}
                                            />
                                        </Col>
                                    )
                                })
                            }
                        </Row>
                    </Col>
                </Row>
                )}
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  min-height: 100vh;
  padding-top: 80px;
  position: relative;
  
  .category-list{
    padding-bottom: 15px;
    position: relative;
    margin: 0 auto 80px auto;
    //&:after{
    //  content: '';
    //  position: absolute;
    //  background: rgba(34,31,31,0.3);
    //  height: 1px;
    //  bottom: 0;
    //  left: 0;
    //  width: calc(100%);
    //}
    .col-md-4{
      padding: 0;
    }
    .col-md-8{
      padding: 0;
    }
    ul{
      display: flex;
      flex-wrap: wrap;
      li{
        font-size: 16px;
        line-height: 24px;
        cursor: pointer;
        border: 1px solid #000000;
        padding: 12px 32px;
        border-radius: 29px;
        margin-right: 20px;
        transition: all .3s ease;
        width: max-content;
        position: relative;
        overflow: hidden;
        &:last-child{
          margin-right: 0;
        }
        span {
          transition: color .3s ease;
          position: relative;
          z-index: 2;
          width: max-content;
          color: #000000;
        }
        &:before{
          top: 100%;
          content: "";
          display: block;
          position: absolute;
          right: 0;
          left: 0;
          background-color: ${p => p.hoverBackground || hover};
          height: 100%;
          width: 100%;
          margin: auto;
          transition: all .5s ease;
          border-radius: 24px;
        }
        &:hover{
          span{
            color: #FFFFFF;
          }
          border: 1px solid #8A1313;
          &:before{
            top:0;
          }
        }
        &.active{
          background-color: ${hover};
          border: 1px solid ${hover};
          span{
            color: #FFFFFF;
          }
        }
        @media(max-width: 767px){
          margin-bottom: 20px;
          
        }
      }
    }
    @media(max-width: 767px){
      margin-bottom: 60px;
      display: flex;
      flex-direction: column-reverse;
      .product-search{
        margin-bottom: 40px;
      }
    }
  }
  
  .product-search{
    position: relative;
    input {
      width: 100%;
      border: none;
      border-bottom: 1px solid #2A2627;
      padding: 12px 12px 12px 30px;
      font-size: 16px; /* You can adjust the font size as needed */
      transition: border-color 0.3s ease;
    }
    input::placeholder {
      position: absolute;
      left: 30px;
      top: 12px;
      color: rgba(42,38,39,0.3);
    }
    input:focus {
      border-color: ${hover}; /* Change this to your desired focus color */
      outline: none;
    }

    button {
      position: absolute;
      left: 0;
      top: 0;
      height: 100%;
      border: none;
      background: none;
      cursor: pointer;
    }
  }

  h3{
    font-weight: 300;
    font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
    margin-bottom: 20px;
    color: ${hover};
  }

  .desktop-lists {
    li {
      margin-bottom: 10px;

      a {
        font-size: 16px;
        font-weight: 400;
        line-height: 16px;
        position: relative;
          cursor: pointer;
          transition: 0.3s ease-in-out;
        // &:after {
        //   content: '';
        //   position: absolute;
        //   border-radius: 50%;
        //   top: 7px;
        //   bottom: 0;
        //   right: -15px;
        //   margin: auto;
        //   height: 10px;
        //   width: 10px;
        //   background-color: ${hover};
        //   display: none;
        // }
      }

      &.active {
        a {
          color: ${hover};
            font-weight: 600px;

          &:after {
            display: block;
          }
        }
      }
    }
  }
  @media(max-width: 767px){
    .product-category-list{
      margin-bottom: 60px;
    }
  }
`;

export default MyComponent;
