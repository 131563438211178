import React, {useEffect} from 'react';
import styled from "styled-components";
import {Container, Row, Col, Modal} from "react-bootstrap";
import Title from "../Title";
import {Img} from "../Img";
import TitleTwo from "../TitleTwo";
import ModalVideo from "react-modal-video";
import SimpleBar from "simplebar-react";
import {useRef, useState} from "react";
import reactHtmlParser from "react-html-parser";
import Button from "../Button";
import {ImgParallax} from "../ImgParallax";
import { gsap } from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { DrawSVGPlugin } from 'gsap/DrawSVGPlugin';


const MyComponent = ({desc,title,img,url,revealClass,leftText,rightText,button,companyDoc}) => {
    gsap.registerPlugin(ScrollTrigger, DrawSVGPlugin);

    let [open, setOpen] = useState(false);
    let [videoId, setVideo] = useState('');

    let handelOpen = (open, Id) => {
        setOpen(open);
        setVideo(Id);
        setShow(true);
        console.log(videoId);
    };
    const modalRef = useRef(null);



    const [show, setShow] = useState(false);
    const [popupId, setPopupId] = useState()
    const [showVideo, setShowVideo] = useState(false)

    const handleClose = () => {
        setShow(false);
    }
    // const handleShow = (e) => {
    //     setShow(true)
    //     setPopupId(e)
    //     setVideo(true)
    // }
    const handleCloseModal = () => {
        setOpen(false);
        setVideo('');
        handleClose();
    };



    return (
        <StyledComponent className={'pt-150 pb-150 home-about'}>
            <Container>
                <Row className={'home-about-wrapper'}>
                    <Col md={6}>
                        <Row>
                            <Col md={12}>
                                {
                                    title &&
                                    <TitleTwo text={title} color={'#8A1313'} margin={'0px 0px 40px 0px'}/>
                                }

                            </Col>
                        </Row>
                        <Row className={''}>
                            <Col md={12} className={'home-about__text '}>
                                {
                                    reactHtmlParser(desc)
                                }
                                {/*<p className="split-text split-up">*/}
                                {/*    {reactHtmlParser(desc)}*/}
                                {/*</p>*/}
                                {/*<div className="left-text">*/}
                                {/*    {*/}
                                {/*        reactHtmlParser(leftText)*/}
                                {/*    }*/}
                                {/*</div>*/}
                                {/*<div className="right-text">*/}
                                {/*    {*/}
                                {/*        reactHtmlParser(rightText)*/}
                                {/*    }*/}
                                {/*</div>*/}
                            </Col>
                        </Row>
                        {
                            button &&
                            <Row>
                                <Col>
                                    <Button src={companyDoc} text={'Download Profile'} color={'#000000'}
                                            border={'1px solid #000000'} hoverBorderColor={'#8A1313'}
                                            margin={'60px 0 0 0'}
                                    />
                                </Col>
                            </Row>
                        }

                    </Col>
                    <Col md={{span:5,offset:1}}>

                        <div className={`home-about__img ${revealClass}`}>

                            <ImgParallax src={img}/>

                            {
                                url &&
                                <div className="play-btn" onClick={() => handelOpen(true, url )}>
                                    <svg id="Component_214_1" data-name="Component 214 – 1" xmlns="http://www.w3.org/2000/svg" width="96" height="96" viewBox="0 0 96 96">
                                        <g id="Ellipse_623" data-name="Ellipse 623" transform="translate(41 41)" fill="none" stroke="#fff" stroke-width="2">
                                            <circle cx="7" cy="7" r="7" stroke="none"/>
                                            <circle cx="7" cy="7" r="6" fill="none"/>
                                        </g>
                                        <g id="Ellipse_624" data-name="Ellipse 624" transform="translate(3 3)" fill="none" stroke="#fff" stroke-width="2">
                                            <circle cx="45" cy="45" r="45" stroke="none"/>
                                            <circle cx="45" cy="45" r="44" fill="none"/>
                                        </g>
                                        <circle id="Ellipse_622" data-name="Ellipse 622" cx="48" cy="48" r="48" fill="#fff"/>
                                        <path id="Polygon_2" data-name="Polygon 2" d="M11.638,1.469a1,1,0,0,1,1.725,0L24.116,19.79a1,1,0,0,1-.862,1.506H1.746A1,1,0,0,1,.884,19.79Z" transform="translate(62 35.5) rotate(90)" fill="#8a1313"/>
                                    </svg>

                                </div>

                            }

                        </div>
                    </Col>
                </Row>

            </Container>



            <Modal
                show={show}
                // item={item}
                onHide={handleCloseModal}
                backdrop="static"
                keyboard={false}
                className="gph_modal modal_video_popup popup-version-one"
                dialogClassName="custom-modal-dialog"
            >
                <SimpleBar className="main_scroll " style={{height: '100vh'}}>
                    <Modal.Body>
                        <Container>
                            <Row className={'for-close'}>
                                <div onClick={handleCloseModal} className="modal-close ">

                                    <svg id="Button_-_Close" data-name="Button - Close" xmlns="http://www.w3.org/2000/svg" width="44" height="44" viewBox="0 0 44 44">
                                        <g id="Ellipse_18" data-name="Ellipse 18" fill="none" stroke="#3c3c3b" stroke-width="1" opacity="0.3">
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Ellipse_19" data-name="Ellipse 19" fill="none" stroke="#3c3c3b" stroke-width="1" stroke-dasharray="0 142" opacity={'0'}>
                                            <circle cx="22" cy="22" r="22" stroke="none"/>
                                            <circle cx="22" cy="22" r="21.5" fill="none"/>
                                        </g>
                                        <g id="Group_18979" data-name="Group 18979" transform="translate(-3149 -104.5)">
                                            <line id="Line_4" data-name="Line 4" x2="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                            <line id="Line_3877" data-name="Line 3877" x1="8" y2="8" transform="translate(3167 122.5)" fill="none" stroke="#3c3c3b" strokeLinecap="round" stroke-width="1"/>
                                        </g>
                                    </svg>

                                </div>

                            </Row>
                        </Container>
                        <Container>
                            <Row>


                                <div className="modal-data d-flex">
                                    <ModalVideo channel='youtube' isOpen={open}
                                                videoId={videoId}
                                                onClose={handleCloseModal}/>

                                </div>
                            </Row>
                        </Container>
                    </Modal.Body>
                </SimpleBar>
            </Modal>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`
  position: relative;
  overflow: hidden;
  .back-svg{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .home-about{
    
    &__text{
        p{
            &:not(:last-child){
                margin-bottom: 24px;
            }
        }
      .left-text, .right-text{
        flex: 0 0 calc(50% - 15px);
          
          p{
              &:not(:last-child){
                  margin-bottom: 24px;
              }
          }
      }
      .left-text{
        margin-right: 30px;
      }
      @media(max-width: 767px){
        flex-direction: column;
        .left-text{
          margin-right: 0;
        }
      }
      
    }
    
    &__img{
      position: relative;
      padding-top: calc(600 / 470 * 100%);
        
        @media(min-width: 1920px){
            padding-top: calc(600 / 600 * 100%);
        }
        @media(max-width: 1500px){
            padding-top: calc(700 / 470 * 100%);
        }
      //height: 100%;
      overflow: hidden;
      @media(max-width: 767px){
        margin-top: 40px;
      }
      .play-btn{
        position: absolute;
        top: 50%;
        left: 50%;
        width: 96px;
        height: 96px;
        transform: translate(-50%, -50%);
        cursor: pointer;
        &:hover{
          &:after{
            width: 250px;
            height: 250px;
            opacity: 0;
          }
          &:before{
            width: 300px;
            height: 300px;
            opacity: 0;
          }
        }
        
      }
      .play-btn:after{
        content: '';
        position: absolute;
        width: 96px;
        height: 96px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: all 0.6s ease-in, opacity 0.6s ease-in;
      }
      .play-btn:before{
        content: '';
        position: absolute;
        width: 96px;
        height: 96px;
        border: 1px solid #FFFFFF;
        border-radius: 50%;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        opacity: 1;
        transition: all 0.4s ease-in, opacity 0.4s ease-in;
      }
    }
  }
  .split-text {
    -webkit-column-count: 2; /* Number of columns */
    -moz-column-count: 2;
    column-count: 2;
    column-gap: 30px;
    @media(max-width: 767px){
      column-count: 1;
    }
  }
    @media(max-width: 1200px){
        .home-about-wrapper{
            display: flex;
            flex-direction: column;
            .col-md-6, .col-md-5{
                flex: 0 0 100%;
                max-width: 100%;
            }
            .home-about__img{
                margin-top: 60px;
                padding-top: calc(450 / 345 * 100%);
            }
            .offset-md-1{
                margin-left: 0;
            }
        }
    }
`;

export default MyComponent;
