import React from 'react';
import styled from "styled-components";
import {hover} from "../styles/globalStyleVars";

const NextBtn = ({next_id, prev_id, color,prevSlide,nextSlide}) => {
    return (
        <StyledComponent>
            <ul className="navigation">
                <li className={"prev_swipper hover"} id={prev_id ? prev_id : 'service-prev'} onClick={prevSlide}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8.121" height="14.121" viewBox="0 0 8.121 14.121">
                        <g id="Group_23869" data-name="Group 23869" transform="translate(869.061 442.061)">
                            <line id="Line_352" data-name="Line 352" x2="6" y2="6" transform="translate(-862 -441) rotate(90)" fill="none" stroke={`${color ? color : '#000000'}`} stroke-linecap="round" stroke-width="1.5"/>
                            <line id="Line_353" data-name="Line 353" x1="6" y2="6" transform="translate(-862 -435) rotate(90)" fill="none" stroke={`${color ? color : '#000000'}`} stroke-linecap="round" stroke-width="1.5"/>
                        </g>
                    </svg>


                </li>
                <li className={"next_swipper hover"} id={next_id ? next_id : 'service-next'} onClick={nextSlide}>
                    <svg xmlns="http://www.w3.org/2000/svg" width="8.121" height="14.121" viewBox="0 0 8.121 14.121">
                        <g id="Group_23869" data-name="Group 23869" transform="translate(787.061 442.061)">
                            <line id="Line_352" data-name="Line 352" x2="6" y2="6" transform="translate(-786 -429) rotate(-90)" fill="none" stroke={`${color ? color : '#000000'}`} stroke-linecap="round" stroke-width="1.5"/>
                            <line id="Line_353" data-name="Line 353" x1="6" y2="6" transform="translate(-786 -435) rotate(-90)" fill="none" stroke={`${color ? color : '#000000'}`} stroke-linecap="round" stroke-width="1.5"/>
                        </g>
                    </svg>

                </li>
            </ul>
        </StyledComponent>
    );
};

const StyledComponent = styled.div`
  .navigation {
    display: flex;
    align-items: center;

    li {
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid #000000;
      transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);

      svg {
        line {
          transition: all 0.7s cubic-bezier(0.4, 0, 0, 1);
        }

      }

      &.hover:after {
        background-color: ${hover};
        border: 1px solid ${hover};
      }

      &:first-child {
        margin-right: 20px;
      }

      position: relative;
      border-radius: 50%;
      height: 50px;
      width: 50px;
      
      &:hover{
        border: 1px solid ${hover};
        line{
          stroke: #FFFFFF;
        }
      }
    }
  }
  

  
`;

export default NextBtn;
