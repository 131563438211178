import {configureStore} from "@reduxjs/toolkit";
import postReducer from '../redux/post'
import globalReducer from '../redux/global'
import homeReducer from '../redux/home'
import aboutReducer from '../redux/about'
import businessReducer from '../redux/businessunit'
import csrReducer from '../redux/sustainability'
import csrDetailReducer from '../redux/sustainability'
import contactReducer from '../redux/contact'
import careerReducer from '../redux/career'
import mediaReducer from '../redux/media'
import mediaDetailReducer from '../redux/media'
import productReducer from '../redux/product'
import productDetailReducer from '../redux/product'
import whatWeDoReducer from '../redux/whatwedo'
import globalapiReducer from '../redux/globalapi'


const store = configureStore({
    reducer: {
        postReducer,
        globalReducer,
        home : homeReducer,
        about : aboutReducer,
        businessunit : businessReducer,
        sustainability : csrReducer,
        contact: contactReducer,
        career:careerReducer,
        media: mediaReducer,
        mediaDetails: mediaDetailReducer,
        products: productReducer,
        productDetails: productDetailReducer,
        whatWeDo: whatWeDoReducer,
        csr: csrReducer,
        csrDetails: csrDetailReducer,
        globalapi: globalapiReducer,

    },
    // middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(logger)
})

export default store

