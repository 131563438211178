import { gsap } from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { SplitText } from "gsap/SplitText";
import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";

export const SplitUpNew = () => {
    const location = useLocation();
    gsap.registerPlugin(SplitText);
    gsap.registerPlugin(ScrollTrigger);
    let getPost = useSelector((state) => state);


    useEffect(() => {
        document.fonts.ready.then(() => {

            const quotes = document.querySelectorAll(".split");

            quotes.forEach(quote => {
                // Reset if needed
                if(quote.anim) {
                    quote.anim.progress(1).kill();
                    quote.split.revert();
                }

                quote.split = new SplitText(quote, {
                    type: "lines,words,chars",
                    linesClass: "split-line"
                });

                // Set up the anim
                quote.anim = gsap.from(quote.split.chars, {
                    scrollTrigger: {
                        trigger: quote,
                        toggleActions: "restart pause resume reverse",
                    },
                    duration: 0.6,
                    ease: "circ.out",
                    y: 130,
                    stagger: 0.02,
                });
            });
        });
    }, [location?.pathname, getPost]);
};
