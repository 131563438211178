import React from 'react';
import styled from "styled-components";
import {Container, Row, Col} from "react-bootstrap";

const MyComponent = () => {
    return (
        <StyledComponent>
            <Container>
                <h1>H1</h1>
                <h2>H2</h2>
                <h3><span>H3</span> H3 </h3>
                <h4>H4</h4>
                <h5>H5</h5>
                <h6>H6</h6>
                <p>Paragraph 1</p>
                <p className={'light-para'}>Paragraph 2</p>
                <p className={'banner-menu'}>Banner Menu</p>
                <p className={'footer-menu'}>Footer Menu</p>
                <p className={'copyright'}>Copyright</p>
            </Container>
        </StyledComponent>
    );
};

const StyledComponent = styled.section`

`;

export default MyComponent;
