import {createGlobalStyle, css} from 'styled-components';
import {hover, text, title, titletwo} from './globalStyleVars';

function createCSS() {
    let styles = '';

    for (let i = 2; i < 20; i += 1) {
        styles += `
        .anim-active.fade-up:nth-child(${i}) {
          transition-delay: ${i * .12}s;
        }
     `
    }

    for (let a = 2; a < 100; a += 1) {
        styles += `
        .anim-active.fade-right span:nth-child(${a}) {
          transition-delay: ${a * .03}s;
        }
     `
    }

    return css`${styles}`;
}

export default createGlobalStyle`

  ${createCSS()}
  #root {
    min-height: 100vh;
    overflow-x: hidden;
  }


  @font-face {
    font-family: 'pp-fragment-serif-2';
    src: url('/fonts/pp-fragment-serif-2.woff2') format('woff2'),
    url('/fonts/pp-fragment-serif-2.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }
  
  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Regular.woff2') format('woff2'),
    url('/fonts/Poppins-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Light.woff2') format('woff2'),
    url('/fonts/Poppins-Light.woff') format('woff');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Medium.woff2') format('woff2'),
    url('/fonts/Poppins-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
  }
  

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-ExtraBold.woff2') format('woff2'),
    url('/fonts/Poppins-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Thin.woff2') format('woff2'),
    url('/fonts/Poppins-Thin.woff') format('woff');
    font-weight: 100;
    font-style: normal;
    font-display: swap;
  }

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-SemiBold.woff2') format('woff2'),
    url('/fonts/Poppins-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
  }
  

  @font-face {
    font-family: 'Poppins';
    src: url('/fonts/Poppins-Bold.woff2') format('woff2'),
    url('/fonts/Poppins-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
  }
  html {
      /* For Firefox */
      scrollbar-color: ${hover} #F8F8F9;
      scrollbar-width: thin;
  }


  body {
    font-family: 'Poppins','pp-fragment-serif-2', Arial, Helvetica, freesans, sans-serif !important;
    font-style: normal;
    margin: 0;
    color: ${text};
    padding: 0;
    overflow-x: hidden;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    min-height: 100vh;
    font-size: 16px;
    line-height: 24px;
      scrollbar-width: thin;
  }
  

  a {
    transition: color .3s ease;
    text-decoration: none;

    &:hover {
      color: ${hover} !important;
      text-decoration: none;
      outline: none;
      box-shadow: none;
    }

    &:focus {
      text-decoration: none;
      outline: none;
      box-shadow: none;
      color: ${text};
    }
  }

  ::selection {
    background: ${hover};
    color: #FFF;
  }

  p, a, h4, h3, h5, h6 {
    color: ${text};
    margin: 0;
  }

  h1{
    font-size: 64px;
    font-weight: 400;
    line-height: 82px;
    font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;

    @media(max-width: 767px){
      font-size: 48px;
      line-height: 60px;
    }
  }

  h2{
    font-size: 40px;
    font-weight: 300;
    line-height: 52px;
    font-family: ${title};
      font-kerning: none;
    @media(max-width: 767px){
      font-size: 32px;
      line-height: 36px;
    }
  }
  h3{
    font-size: 24px;
    font-weight: 500;
    line-height: 32px;
      font-kerning: none;
    span{
      font-size: 24px;
      font-weight: 600;
      line-height: 32px;
    }
  }
  h4{
    font-size: 22px;
    font-weight: 500;
    line-height: 30px;
      font-kerning: none;
  }
  h5{
    font-size: 20px;
    font-weight: 400;
    line-height: 26px;
  }
  h6{
    font-size: 18px;
    font-weight: 500;
    line-height: 26px;
  }
  .light-para{
    font-size: 16px;
    font-weight: 300;
    line-height: 28px;
  }
  p{
    font-size: 16px;
    font-weight: 400;
    line-height: 24px;
      font-kerning: none;
  }
  .banner-menu{
    font-size: 15px;
    font-weight: 400;
    line-height: 22px;
  }
  .footer-menu{
    font-size: 14px;
    font-weight: 400;
    line-height: 24px;
  }
  .copyright{
    font-size: 12px;
    font-weight: 400;
    line-height: 20px;
  }
  h1, h2 {
    margin: 0;
  }

  ul {
    margin: 0;
    padding: 0
  }

  li {
    list-style: none;
  }

  img {
    max-width: 100%;
    object-fit: contain;
  }


  .btn:focus, button:focus, button:active:focus, .btn.active.focus, .btn.active:focus, .btn.focus, .btn:active.focus, .btn:active:focus, .btn:focus {
    outline: none;
    box-shadow: none;
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  textarea:-webkit-autofill,
  textarea:-webkit-autofill:hover,
  textarea:-webkit-autofill:focus,
  select:-webkit-autofill,
  select:-webkit-autofill:hover,
  select:-webkit-autofill:focus {
    border: 1px solid rgba(0, 0, 0, 0);
    -webkit-text-fill-color: #000;
    -webkit-box-shadow: 0 0 0px 1000px rgba(0, 0, 0, 0) inset;
    transition: background-color 5000s ease-in-out 0s;
  }


  table {
    width: 100%;
  }

  form div {
    position: relative;
  }

  .form-control {
    box-shadow: none;
    outline: 0;
    border-radius: 0;

    &:focus {
      box-shadow: none;
    }
  }

  .p-0 {
    padding: 0 !important;
  }

  .pl-0 {
    padding-left: 0;
  }

  .pr-0 {
    padding-right: 0;
  }

  .pt-200 {
    padding-top: 200px;
    @media (max-width: 767px) {
      padding-top: 100px;
    }
  }

  .pb-200 {
    padding-bottom: 200px;
    @media (max-width: 767px) {
      padding-bottom: 100px;
    }
  }

  .pt-150 {
    padding-top: 150px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }

  .pb-150 {
    padding-bottom: 150px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }

  .pb-120 {
    padding-bottom: 120px;
    @media (max-width: 767px) {
      padding-bottom: 80px;
    }
  }
  .pt-120 {
    padding-top: 120px;
    @media (max-width: 767px) {
      padding-top: 80px;
    }
  }


  .pt-100 {
    padding-top: 100px;
    @media (max-width: 767px) {
      padding-top: 60px;
    }
  }

  .pb-100 {
    padding-bottom: 100px;
    @media (max-width: 767px) {
      padding-bottom: 60px;
    }
  }

  .pt-80 {
    padding-top: 80px;
    @media (max-width: 767px) {
      padding-top: 40px;
    }
  }

  .pb-80 {
    padding-bottom: 80px;
    @media (max-width: 767px) {
      padding-bottom: 40px;
    }
  }


  .MuiDrawer-paper {
    width: 500px !important;
    padding: 20px;
    @media (max-width: 767px) {
      width: 100% !important;
    }
  }


  @media (min-width: 1500px) {
    .container {
      //min-width: 1366px;
      min-width: 85%;
      margin: auto;
    }
  }

  @media (max-width: 1199px) and (min-width: 768px) {
    .container, .container-lg, .container-md, .container-sm {
      max-width: 90%;
      margin: auto;
    }
  }


  @media (max-width: 767px) {
    .container, .container-sm {
      max-width: 100%;
    }
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }


  //react select
  .css-yk16xz-control, .css-1pahdxg-control {
    height: 50px;
    border-radius: 0 !important;
    padding-left: 5px;
    font-size: 16px;
    outline: none !important;
    border-color: #D9D9D9 !important;
    box-shadow: none !important;

    .css-1wa3eu0-placeholder {
      font-weight: 300;
      line-height: 21px;
      color: rgba(0, 0, 0, 0.5);
      outline: none;
    }

    .css-1okebmr-indicatorSeparator {
      display: none;
    }

    .css-tlfecz-indicatorContainer, .css-1gtu0rj-indicatorContainer {
      margin-right: 10px;
    }
  }

  .css-2613qy-menu {
    border-radius: 0 !important;
    margin-top: 0 !important;
  }

  //animation class


  .info-window {
    max-width: 200px;
  }

  .gm-style-iw {
    border-radius: 0 !important;
  }

  .swiper-pagination-bullet {
    outline: none;
  }

  .css-nmuc1a-menu {
    z-index: 5 !important;
  }


  .map-info__img {
    img {
      height: 100px;
      margin-bottom: 12px;
      object-fit: cover;
    }
  }

  .map-info__content {
    h4 {
      font-size: 20px;
    }

    p {
      margin-bottom: 5px;
    }
  }

  .overlay {
    position: fixed;
    height: 100vh;
    width: 100%;
    //background-color: rgba(0,0,0,0.5);
    top: 0;
    bottom: 0;
    left: 0;
    z-index: 9;
    display: none;

    &.show {
      display: block;
    }
  }

  .form-control.has-error {
    border-color: #dc004e !important;
  }

  .has-error .find-retainer-filter__control {
    border-color: #dc004e !important;
  }

  //preloader
  .content-loader {
    position: absolute;
    height: 70%;
    width: 70%;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    justify-content: center;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    margin: auto;
  }

  .loading-before-submit {
    position: fixed;
    height: 100vh;
    width: 100%;
    bottom: 0;
    top: 0;
    left: 0;
    background: rgba(0, 0, 0, 0.65);
    z-index: 9;
    display: flex;
    align-items: center;
    justify-content: center;

    img {
      height: 40px;
    }
  }


  .swiper-slide {
    height: auto;
  }

  .slick-slide {
    div {
      outline: none !important
    }
  }

  button, button:active, button:focus, button:focus-visible {
    outline: none !important;
    box-shadow: none !important;
  }


  .hover {
    position: relative;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;

    span {
      z-index: 2;
    }

    svg {
      position: relative;
      z-index: 2;
    }

    &:after {
      content: '';
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      right: 0;
      top: 100%;
      margin: auto;
      background-color: ${hover};
      transition: all .5s ease;
      border-radius: 50%;
    }

    &:hover {
      &:after {
        top: 0;
      }
    }
  }


  .modal-backdrop {
    background-color: rgb(34 31 31 / 90%);
    min-width: 100%;
    //z-index: 9999;

    &.show {
      opacity: 1;
    }
  }


  .valid {
    color: ${hover};
    position: absolute;
    font-size: 12px;
    top: 44px;
  }

  input[type="number"]::-webkit-outer-spin-button,
  input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
  }


  //menu fixed
  .scroll-down .menu-desktop {
    transform: translate3d(0, -100%, 0);
  }

  .scroll-up .menu-desktop {
    background-color: #fff;

    .menu-desktop__hamburger__lines {
      &__inner .line {
        background-color: #191818;
      }

      p {
        color: #191818;
      }
    }

    .menu-desktop__hamburger .controller svg {
      path {
        fill: #191818;
      }

      line {
        stroke: #191818;
      }
    }

    .dc-btn a {
      color: #191818;

      &:hover {
        color: #191818 !important;
      }

      &:after, &:before {
        box-shadow: 0 0 0 1px #191818;
      }
    }
  }

  .form-control:disabled {
    background-color: transparent;
  }

  @media (max-width: 600px) {
    .prevent-overflow {
      overflow: hidden;
      height: 100vh;
    }
  }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  .mobile-menu {
    #fb-root, .fb_reset {
      display: none !important;
      opacity: 0 !important;
      visibility: hidden !important;
    }
  }

  .slick-slide {
    -webkit-transform: translate3d(0, 0, 0);
  }

  .reveal, .revealFast {
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;

    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }

  //calender
  ._3efP_GeH5kyBAzqnLzL._kN_bCa3VNYpqFLH311L {
    border-radius: 0 !important;
  }

  //video modal 
  .modal-video-close-btn:before, .modal-video-close-btn:after {
    background-color: ${hover};
  }

  .page-loader {
    position: fixed;
    background-color: rgb(36, 24, 67);
    width: 100vw;
    z-index: 999999999;
    overflow: hidden;
    //opacity: 0;
    //height: 100vh;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    //left: 0; //width: 0;
    //transition: all 2.6s ease;
    //.anim-logo {
    //  width: 130px;
    //  overflow: hidden;
    //  height: fit-content;
    //  position: absolute;
    //  left: 0;
    //  right: 0;
    //  top: 0;
    //  bottom: 0;
    //  opacity: 0;
    //  margin: auto;
    //  z-index: 2;
    //
    //  img {
    //    height: 55px;
    //  }
    //}

    //.hide-logo {
    //  background-color: #010A1A;
    //  width: 50%;
    //  height: 100vh;
    //  top: 0;
    //  left: 0;
    //  right: 0;
    //  margin: auto;
    //  position: absolute;
    //  z-index: 999;
    //}

    .pre-loader__img {
      //position: fixed;
      height: 100px;
      width: 100px;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      background-color: rgb(36, 24, 67);
      z-index: 99999999;

      //flex: 1;
      margin: auto;
      opacity: 0;

      img {
        display: block;
      }

      .top {
        height: 8px;
      }

      .bottom {
        height: 30px;
        margin-top: 5px;
        animation: flip 1s linear infinite;
      }

      @keyframes flip {
        0% {
          transform: rotateY(0deg)
        }
        100% {
          transform: rotateY(180deg)
        }
      }
    }

  }

  //------------------------animation
  .split-parent {
    overflow: hidden;
  }

  .split-child {
    overflow: hidden;
  }
  .split{
      overflow: hidden;
  }
  .split-line {
      overflow: hidden;
  }

  


  //page transition
  .page-transition {
    position: fixed;
    height: 100%;
    width: 100%;
    top: 0;
    left: 0;
    background-color: #000000;
    z-index: 999999999;
    display: none;
    opacity: 0;
    //display: flex;
    align-items: center;
    justify-content: center;
    .logo {
      height: 80px;
      opacity: 0;
    }
  }

  .modal_video_popup {
    .modal-content, .modal-video, .modal-dialog {
      background: transparent !important;
      border: none !important;
      box-shadow: none !important;
      outline: none !important;

    }
    .modal-dialog{
      border: 0 !important;
      margin: 0 !important;
      max-width: unset !important;
      width: 100% !important;height: 100% !important;
    }
    .modal-body{
      border: none !important;
      box-shadow: none !important;
    }

    .modal-close {
      opacity: 0;
    }
  }
  
  //Business Unit Modal
  
  .business-modal{
      padding-left: 0 !important;
      padding-right: 0 !important;
      z-index: 9999999;

      @media (min-width: 320px) {
          .modal-dialog {
              max-width: unset;
              margin: 0;
              height: 100vh;
          }
      }
      .modal-body{
          padding-right: 0;
          padding-left: 0;
      }
      .modal-content{
          background: #FFFFFF;
          color: #2A2627;
          padding-bottom: 200px;
          min-height: 100vh;
          
      }
      .modal-title {
          display: flex;
          justify-content: space-between;
          //align-items: center;
          margin-top: 50px;
          @media(max-width: 767px){
              flex-direction: column-reverse;
              gap: 10px;
          }

          h2{
              margin-bottom: 60px;
          }
          h5 {
              margin-bottom: 60px;
              font-size: 24px;
              line-height: 32px;
              font-weight: 400;
              opacity: 0.8;
              font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
              //margin-bottom: 60px;
              //padding-bottom: 10px;
              @media (max-width: 767px) {
                  font-size: 24px;
                  //margin-bottom: 50px;
              }
          }
      }
      h3{
          margin-bottom: 60px;
      }
      .modal-data__content{
          position: relative;
          color: #1b1e21;
          @media(max-width: 767px){
              flex-direction: column-reverse;
              gap: 40px;
          }

          ul{
              margin-top: 40px;
              li{
                  position: relative;
                  padding-left: 20px;
                  &:after{
                      content: '';
                      position: absolute;
                      top: 7px;
                      left: 0;
                      width: 10px;
                      height: 10px;
                      background-color: #8A1313;
                      border-radius: 50%;
                  }
              }
          }

      }

      .modal-close {
          display: flex;
          justify-content: flex-end;
          //margin-bottom: 50px;
          //margin-top: 30px;
          svg {
              height: 40px;
              width: 40px;
              position: relative;
              cursor: pointer;
              transition: 0.7s all ease;
              @media (max-width: 767px) {
                  height: 30px;
                  width: 30px;
              }
              line {
                  transition: 0.7s all ease;
              }
              &:hover {
                  line {
                      stroke: ${hover};
                  }
              }
          }
      }
  }
  
  //Job Apply Modal

  .job-modal{
    padding-left: 0 !important;
    padding-right: 0 !important;
    z-index: 9999999;

    @media (min-width: 320px) {
      .modal-dialog {
        max-width: unset;
        margin: 0;
        height: 100vh;
      }
    }
    .modal-body{
      padding-right: 0;
      padding-left: 0;
    }
    .modal-content{
      background: #FFFFFF;
      color: #2A2627;
      padding-bottom: 200px;
        min-height: 100vh;
    }
    .modal-title {
      display: flex;
      justify-content: space-between;
      //align-items: center;
      margin-top: 50px;
      @media(max-width: 767px){
        flex-direction: column-reverse;
        gap: 10px;
      }

      h5 {
        margin-bottom: 60px;
        font-size: 24px;
        line-height: 32px;
        font-weight: 400;
        opacity: 0.8;
        font-family: 'pp-fragment-serif-2',Poppins,Arial, Helvetica, freesans, sans-serif;
        //margin-bottom: 60px;
        //padding-bottom: 10px;
        @media (max-width: 767px) {
          font-size: 24px;
          //margin-bottom: 50px;
        }
      }
    }
    h3{
      margin-bottom: 60px;
    }
    .modal-data__content{
      position: relative;

    }
    .form-control{
      background-color: transparent;
      border:none;
      border-bottom: 1px solid rgba(34,31,31,0.3);
      margin-bottom: 40px;
      color: #221F1F;
      padding-left: 0;
      padding-bottom: 20px;
      color: rgba(42,38,39,0.5);
      font-size: 12px;
      line-height: 20px;
      font-weight: 400;
      &::placeholder{
        color: rgba(42,38,39,0.5);
        font-size: 12px;
        line-height: 20px;
        font-weight: 400;
      }
    }
    .form__phoneEmail{
      display: flex;
      gap: 20px;
      @media(max-width: 767px){
        flex-direction: column;
        input{
          width: 100% !important;
        }
      }
      input{
        //width: calc(33.33% - 20px);
      }
    }
    input[type="textarea"]{
      padding-bottom: 80px;
    }

    .attachCvName{
      display: flex;
      gap: 40px;
      margin-bottom: 60px;

      .attach-cv{
        width: 222px;
        cursor: pointer;
        border: 1px solid #fff;
        border-radius: 28px;
        height: 48px;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 10px;
        background: ${hover};
        label{
          margin-bottom: 0;
          cursor: pointer;
          color: #FFFFFF;
        }
        @media(max-width: 767px){
          //width: 100%;
        }
      }
      @media(max-width: 767px){
        flex-direction: column;
      }
    }
    .dc-btn{
      @media(max-width: 767px){
        display: flex;
        //justify-content: center;
      }
    }
    .file-name{
      font-size: 14px;
      opacity: 0.8;
      color: #FFFDFB;
      padding-left: 10px;
    }

    p{
      color: #FFFDFB;
      margin-bottom: 60px;
      @media(max-width: 767px){
        margin-bottom: 40px;
      }
    }

    .modal-close {
      display: flex;
      justify-content: flex-end;
      //margin-bottom: 50px;
      //margin-top: 30px;
      svg {
        height: 20px;
        width: 20px;
        position: relative;
        cursor: pointer;
        transition: 0.7s all ease;
        @media (max-width: 767px) {
          height: 30px;
          width: 30px;
        }
        line {
          transition: 0.7s all ease;
        }
        &:hover {
            line {
              stroke: ${hover};
            }
        }
      }
    }
    .modal-data{
      justify-content: unset;
    }
  }
.bg-color{
  background-color: #F5F5F5;
}


  .reveal{
    overflow: hidden;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    bottom: 0;
    display: block;
    img {
      transform-origin: left;
    }

    .global-image {
      background: transparent;
    }
  }
  
   ::-webkit-scrollbar {
     width: 0px;
   }

  // ::-webkit-scrollbar-track {
  //   box-shadow: inset 0 0 5px white;
  //   //border-radius: 10px;
  // }
  //
  // ::-webkit-scrollbar-thumb {
  //   background: ${hover};
  //   //border-radius: 10px;
  // }
  //
  // ::-webkit-scrollbar-thumb:hover {
  //   background: ${hover};
  // }

  .Toastify__toast-container {
    z-index: 99999999;
  }

  //toastify
  .toastify {
    position: fixed;
    z-index: 999999999;
  }

  .toastify {
    z-index: 999999999;
  }


  .Toastify__toast-container {
    z-index: 99999999 !important;
  }
  .form-control {
    
    &:focus {
      border-bottom: 1px solid #221F1F;
    }
  }
  input:-webkit-autofill, input:-webkit-autofill:hover, input:-webkit-autofill:focus{
    border-bottom: 1px solid #221F1F !important;
  }

  .page-loader {
      position: fixed;
      background-color: #000000;
      width: 100vw;
      z-index: 9999999999;
      overflow: hidden;
      height: 0;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      display: flex;
      align-items: center;
      justify-content: center;

      //transition: height 1.2s ease;
    //background-color: red;
    //height: 100%;

    //&.show {
    //  .vert-loader-bar {
    //    height: 100%;
    //  }
    //}
    //
    //&.active {
    //  height: 100%;
    //}

    .vert-loader-bar {
      background-color: #000000;
      width: -webkit-calc(100% / 6);
      width: calc(100% / 6);
      z-index: 999999;
      position: relative;
      //height: 100%;
      //-webkit-transition: height 0.3s;
      //transition: height 1.2s ease;

      //&.bar-0 {
      //  -webkit-transition-delay: 0s;
      //  transition-delay: 0s;
      //}
      //
      //&.bar-1 {
      //  //-webkit-transition-delay: 30ms;
      //  transition-delay: 05ms;
      //}
      //
      //&.bar-2 {
      //  //-webkit-transition-delay: 60ms;
      //  transition-delay: 07ms;
      //}
      //
      //&.bar-3 {
      //  //-webkit-transition-delay: 90ms;
      //  transition-delay: 09ms;
      //}
      //
      //&.bar-4 {
      //  //-webkit-transition-delay: 120ms;
      //  transition-delay: 11ms;
      //}
      //
      //&.bar-5 {
      //  //-webkit-transition-delay: 150ms;
      //  transition-delay: 13ms;
      //}
    }


  }

  .stop-scroll{
      overflow: hidden;
  }
  
  .menu-bg-color{
      background: rgba(0,0,0,0.5) !important;
  }

  .text-justify{
      text-align: justify;
  }

  .form-error {
      position: absolute;
      font-size: 10px;
      color: #EC1C23 !important;
  }

  .single-input{
      flex: 0 0 33%;
      position: relative;
      input{
          width: 100%;
      }
      .form-error{
          top: 50%;
      }
  }

  .button-group{
      position: relative;
      .form-error{
          top: 100%;
          left: 0;
      }
  }

  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus{
      border: none ! Important;
      border-bottom: 1px solid rgb(34, 31, 31) !important;
      /* Some more desired behaviors */
  }


`;



